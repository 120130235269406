<style scoped>

.home_view {
  //display: flex; /* Используем flexbox */
  //flex-direction: column; /* Выстраиваем дочерние элементы в столбец */
  //justify-content: stretch; /* Растягиваем дочерние элементы по вертикали */
}

</style>

<template>
  <div class="home_view">
    <keep-alive>
      <search-component></search-component>
    </keep-alive>
  </div>
</template>

<script>

import SearchComponent from "@/components/Search.vue";
export default {
  name: "HomeView",
  components: {SearchComponent}
}
</script>