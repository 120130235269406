<template>
  <div class="content-main pb-4 pt-4">
    <div class="container-m">
      <b-row>
        <b-col class="rbd" cols="12">
          <p><strong>Consult for the custom service in the Live Support See Bottom Right</strong></p>
          <h4>Services we provide:</h4>
          <ul class="gray_arrow" style="list-style: none">
            <li class="gr_text">
              Custom Web Scraping
            </li>
            <li class="gr_text">
               Crawling and Search
            </li>
            <li class="gr_text">
               Custom Data Mining
            </li>
            <li class="gr_text">
                Lead Generation
            </li>
            <li class="gr_text">
              OCR (Optical Character Recognition)
            </li>
          </ul>
          <p>Our team of experienced data engineers, web developers and lead generation experts specializes in web data mining, scraping, large-scale web crawling, indexing, data search, OCR (images/PDFs to Excel/Word/Txt conversion) and lead generation. </p>
          <p>We have developed a pipeline of sophisticated tools for large-scale web data extraction, processing and indexing with numerous filters to extract specific information from millions of websites. The pipeline has been tested for many use cases and being in use for over 10 years. It has been continuously polished and improved. </p>
          <p>We provide custom web crawling/scraping and data extraction, lead generation and list building, indexing and search services to clients of all sizes. We work with individuals, start-ups, academics, corporates, and non-profits.</p>
          <p>We also provide custom solutions on Optical Character Recognition, transforming images and PDFs into Excel, Word, or Txt files. Our technology is capable of processing millions of documents daily.</p>
          <p>Among other services, we provide scraping of Instagram, Twitter, Facebook, LinkedIn, TikTok, Google and other social media and search platforms for followers, likes/shares, emails, etc.</p>
          <p>You can request a scan of one specific website (it can be a big website such as Amazon, Ebay, Airbnb, Yelp, Craigslist, YellowPages or a smaller niche website); or request a bulk scan for large scale scanning of many websites (up to millions); or a general request defined by your business needs.</p>
          <h4>We also provide price monitoring of:</h4>
          <ul class="gray_arrow" style="list-style: none">
            <li class="gr_text">
              Large marketplaces such as Amazon, Walmart, Ebay, big brands as well as small niche brands and local stores.
            </li>
            <li class="gr_text">
              Homes, apartments, cars, etc; commodities like gold, stocks etc.; gas station prices in any region (mostly US, Canada, Europe).
            </li>
            <li class="gr_text">
              General inquiries defined by your business needs, such as "surfing boards prices in San Diego", "bike prices in New York", "fashion brands prices in Orange county", etc.
            </li>
          </ul>
          <p>We also provide geolocation data and business listing services of large and small brands, stores and businesses.</p>
          <p>We can consult you on various lead generation and web data extraction, indexing and search strategies and techniques.</p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServicesView',
  components: {}
}
</script>

<style scoped>
h1 {
  font-size: 28px;
}
ul {
  list-style: none;
}
</style>
