<script>
import '../fixtures/records';
import {connectLKWebSocket, getCsrfToken} from "@/utils/utils";

export default {
  name: 'last-keywords',
  data() {
    return {
      showDiv: false,
      connection: null,
      compname: this.$options.name,
      fakeArticles: [
        { keyword: 'Fake Keyword 1', timestamp: '2024-02-28T12:34:56.789Z', view: 'View 1' },
        { keyword: 'Test Keyword 2', timestamp: '2024-02-27T10:45:23.456Z', view: 'View 2' },
        { keyword: 'Test Keyword 1', timestamp: '2024-02-28T12:34:56.789Z', view: 'View 1' },
        { keyword: 'Test Keyword 2', timestamp: '2024-02-27T10:45:23.456Z', view: 'View 2' },
        { keyword: 'Test Keyword 1', timestamp: '2024-02-28T12:34:56.789Z', view: 'View 1' },
        { keyword: 'Test Keyword 2', timestamp: '2024-02-27T10:45:23.456Z', view: 'View 2' },
        { keyword: 'Test Keyword 1', timestamp: '2024-02-28T12:34:56.789Z', view: 'View 1' },
        { keyword: 'Test Keyword 2', timestamp: '2024-02-27T10:45:23.456Z', view: 'View 2' },
        { keyword: 'Test Keyword 1', timestamp: '2024-02-28T12:34:56.789Z', view: 'View 1' },
        { keyword: 'Test Keyword 2', timestamp: '2024-02-27T10:45:23.456Z', view: 'View 2' },
      ],
      articles: [],
      fields: [
        { key: "Searched", tdClass: ["td-text"], thStyle: { width: "50%" }, label: "Just searched" },
        { key: "timestamp", tdClass: ["td-text"], thStyle: { width: "30%" }, label: "" },
      ],
    }
  },
  computed: {

    canShow() {
      this.$bus.$emit('show-lastKeyWords');
      return this.articles.length > 0;
    },

    articlesFormatted() {
      if (this.canShow) {
        return this.chooseArticles(this.articles)
      } else {
        return this.chooseArticles(this.fakeArticles)
      }
    },
  },
  methods: {
    chooseArticles(data) {
      return data.map(item => {
        const dt = new Date(item.timestamp);
        const tdelta = new Date() - dt;
        const time_since_request = this.getTimeRepresentation(tdelta);
        return {
          Searched: item.keyword,
          timestamp: time_since_request,
          request: item.view
        };
      });
    },
    getTimeRepresentation(milliseconds) {
      const seconds = Math.floor(milliseconds / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const weeks = Math.floor(days / 7);
      const months = Math.floor(days / 30);
      const years = Math.floor(days / 365);

      if (years >= 1) {
        return `${years} year${years !== 1 ? "s" : ""}`;
      } else if (months >= 1) {
        return `${months} month${months !== 1 ? "s" : ""}`;
      } else if (weeks >= 1) {
        return `${weeks} week${weeks !== 1 ? "s" : ""}`;
      } else if (days >= 1) {
        return `${days} day${days !== 1 ? "s" : ""}`;
      } else if (hours >= 1) {
        return `${hours} hour${hours !== 1 ? "s" : ""}`;
      } else if (minutes >= 1) {
        return `${minutes} min`;
      } else {
        return `${seconds} sec`;
      }
    },

    viewClick: function (data) {
      this.$store.commit('SET_keywordLKI', data);
      this.$emit('on-lki', 'LastKeywordItems');
    },

     websocket_connect: async function () {
      const self = this;
      self.connection = connectLKWebSocket();
      self.connection.onmessage = function (event) {
        const data = JSON.parse(event.data);
        self.articles = data.records
      }
      self.connection.onerror = function (event) {
      }
    },

  },
  mounted: function () {
    this.websocket_connect();
    // // ============Для Теста===========
    // // this.articles = [
    // //   { keyword: 'Test Keyword 1 Test Keyword 1', timestamp: '2024-02-28T12:34:56.789Z', view: 'View 1' },
    // //   { keyword: 'Test Keyword 2', timestamp: '2024-02-27T10:45:23.456Z', view: 'View 2' },
    // //   { keyword: 'Test Keyword 1', timestamp: '2024-02-28T12:34:56.789Z', view: 'View 1' },
    // //   { keyword: 'Test Keyword 2', timestamp: '2024-02-27T10:45:23.456Z', view: 'View 2' },
    // //   { keyword: 'Test Keyword 1', timestamp: '2024-02-28T12:34:56.789Z', view: 'View 1' },
    // //   { keyword: 'Test Keyword 2', timestamp: '2024-02-27T10:45:23.456Z', view: 'View 2' },
    // //   { keyword: 'Test Keyword 1', timestamp: '2024-02-28T12:34:56.789Z', view: 'View 1' },
    // //   { keyword: 'Test Keyword 2', timestamp: '2024-02-27T10:45:23.456Z', view: 'View 2' },
    // //   { keyword: 'Test Keyword 1', timestamp: '2024-02-28T12:34:56.789Z', view: 'View 1' },
    // //   { keyword: 'Test Keyword 2', timestamp: '2024-02-27T10:45:23.456Z', view: 'View 2' },
    // // ];
    // // ==================================
    //  this.connection = connectLKWebSocket()
    // .then(data => {
    //   this.articles = data.records;
    // })
    // .catch(error => {
    //   console.error('WebSocket Error:', error);
    // });
    // Include CSRF token in WebSocket headers
    this.connection.addEventListener('open', (event) => {
      this.connection.send(JSON.stringify({
        'type': 'websocket.handshake',
        'headers': {
          'X-CSRFToken': getCsrfToken()
        }
      }));
    });
  },
  beforeDestroy() {
    if (this.connection !== null) {
      this.connection.close();
      this.connection = null;
    }
  },
};
</script>

<template>

  <div :class="{ 'show_on_table': !canShow }">
    <b-table id="tbl-keywords" :fields="fields" :items="articlesFormatted" borderless small>
      <template #head(Searched)="data">
        <div class="d-flex justify-content-start align-items-center" style="height: 30px;">
          <div class="head-logo">
            <svg width="16" height="16">
              <path fill="currentColor"
                d="M2.5 7c0-2.481 2.019-4.5 4.5-4.5s4.5 2.019 4.5 4.5-2.019 4.5-4.5 4.5A4.505 4.505 0 012.5 7m12.146 6.584l-2.91-2.91A5.98 5.98 0 0012.9 5.898c-.453-2.524-2.564-4.522-5.108-4.847a6.004 6.004 0 00-6.739 6.76c.333 2.541 2.334 4.644 4.857 5.092a5.98 5.98 0 004.766-1.168l2.909 2.911a.502.502 0 00.708 0l.353-.354a.5.5 0 000-.708">
              </path>
            </svg>
          </div>
          <div class="head-text">
            {{ data.label }}
          </div>
        </div>
      </template>

      <template #cell(Searched)="data">
        <div class="wrapp-keywords">
          <span class="keyword-cell d-inline-block text_truncated"
              v-on:click='viewClick({ "value": data.item.request, "idx": 1 })'
              style="max-width: 200px;"
        >
          {{ data.value }}
        </span>
        </div>
      </template>

      <template #cell(timestamp)="data">
        <div class="text-center">
          {{ data.value }}
        </div>
      </template>
    </b-table>
  </div>
</template>

<style scoped>
table {
  border: 1px solid #dee2e6;
}

/deep/ .table>tbody>tr>td {
  padding: 5px 5px 5px 20px;
  height: 30px;
}

/deep/ .table>tbody>tr:first-child>td {
  padding-top: 10px;
}

/deep/ .table>tbody>tr:last-child>td {
  padding-bottom: 10px;
}

/deep/ .table>thead>tr>th {
  padding-left: 20px;
  border-bottom: 2px solid #dee2e6;
}

.keyword-cell {
  cursor: pointer;
}

.keyword-cell:hover {
  text-decoration: underline;
  color: #0056b3;
}

.wrapp-keywords {
  position: relative;
}

.text_truncated {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text_hover {
  position: absolute;
  white-space: normal;
  background-color: #fff;
  border: 1px solid #dee2e6;
  font-size: 110%;
  padding: 5px;
  max-width: 200px;
  visibility: hidden;
  top: 0;
  left: 0;
}

.wrapp-keywords:hover .text_hover {
  visibility: visible !important;
}

.head-text {
  color: rgb(51, 51, 51);
  font-weight: 600;
  padding-top: 1px;
}

.head-logo svg {
  margin-right: 10px;
  color: #968cff;
}

.show_on_table {
  visibility: hidden;
}
</style>
