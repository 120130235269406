<template>
  <div>
    <b-container fluid="sm">
      <b-navbar type="primary" variant="white">
        <b-navbar-brand>
          <router-link class="navbar-brand" to="/">
            <img src="/static/images/logo.png" alt="" />
          </router-link>
        </b-navbar-brand>
        <b-navbar-nav class="flex-row">
          <b-nav-item href="#" @click="onTabClick('web')" :class="[activeTab === 'web' ? 'active' : '']">Web
          </b-nav-item>
          <b-nav-item href="#" @click="onTabClick('business')"
            :class="[activeTab === 'business' ? 'active' : '']">Business
          </b-nav-item>
          <!--         Для основного сайт-->
          <!--          <b-nav-item href="#" disabled id="tooltip-target-1">People</b-nav-item>-->
          <!--          <b-tooltip target="tooltip-target-1" triggers="hover" placement="bottomright"-->
          <!--                     delay="{ show: 100, hide: 100 }" variant="secondary">-->
          <!--            Coming soon-->
          <!--          </b-tooltip>-->
          <!--         Для тестового сайта-->
          <b-nav-item href="#" @click="onTabClick('people')" :class="[activeTab === 'people' ? 'active' : '']">People
          </b-nav-item>
        </b-navbar-nav>
      </b-navbar>
    </b-container>
  </div>


</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      navBarTitle: "IZSearch",
      navBarItems: ["Web", "Business", "People"],
      showTooltipOne: false,
      showTooltipSecond: false,
    };
  },

  computed: {
    activeTab: {
      get() {
        return this.$store.getters.getActiveTab;
      },
      set(value) {
        this.$store.dispatch("actSetTab", value);
      },
    },
  },

  methods: {
    // setCsrf(value){
    //   let sfrm = JSON.parse(JSON.stringify(this.$store.getters.sfrm))
    //   if (value.toLowerCase() === 'web'){
    //     sfrm['limit'] = 1000
    //   }else{
    //     sfrm['limit'] = 60
    //   }
    //   this.$store.dispatch("actSfrm", sfrm);
    // },
    onTabClick(value) {
      // this.setCsrf(value)
      this.$store.dispatch("actSetTab", value);
      this.$bus.$emit('changeNavBarSearchTab', `${this.$options.filters.capitalize(value)} search`);
    },
  },

  filters: {
    capitalize: function (value) {
      return `${value[0].toUpperCase()}${value.substring(1)}`
    },
  },

  created() {
    this.$store.dispatch('actGetTab');
  },

  mounted() {
    // this.setCsrf(this.$store.getters.getActiveTab)
    this.$bus.$emit('changeNavBarSearchTab', `${this.$options.filters.capitalize(this.$store.getters.getActiveTab)} search`);
  }
};
</script>

<style scoped>
.navbar {
  border-bottom: 0;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.2rem 0;
}

.navbar .navbar-nav {
  padding-bottom: 0px;
  margin-top: 10px;
}

.navbar .navbar-brand {
  margin-bottom: 10px;
  margin-right: 0;
  align-self: center;
}

.navbar .navbar-brand a {
  margin: 0;
}

.navbar .navbar-brand img {
  margin: 0;
}

.container-sm {
  max-width: 661px;
}

.navbar-nav .nav-item {
  color: var(--primary);
  border-radius: 0.25rem;
  font-size: 14px;
}

.navbar-nav .nav-item:first-child {
  margin: 0 1rem;
}

.navbar-nav .nav-item.active {
  background-color: var(--primary);
}

.navbar-nav .nav-item.active a {
  color: #fff;
}

.nav-link {
  padding: 4px;
}

@media(max-width: 540px) {
  .navbar .navbar-nav .nav-item:first-child {
    margin-left: 0;
  }
}
</style>
