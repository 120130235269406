<template>
<div>
  <b-container fluid="sm">
    <b-navbar type="primary" variant="white">
      <b-navbar-brand>
            <router-link class="navbar-brand" to="/">
              <img src="/static/images/logo.png" alt=""/>
            </router-link>
      </b-navbar-brand>
    </b-navbar>
  </b-container>
</div>


</template>

<script>
export default {
  name: "NavBarAboutPages",
};
</script>

<style scoped>

.navbar {
  border-bottom: 0;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.2rem 0;
}

.navbar .navbar-nav {
  padding-bottom: 0px;
  margin-top: 10px;
}

.navbar .navbar-brand {
  margin-bottom: 10px;
  margin-right: 0;
  align-self: center;
}

.navbar .navbar-brand a {
  margin: 0;
}

.navbar .navbar-brand img {
  margin: 0;
}

.container-sm {
  max-width: 661px;
}

</style>
