<template>
    <div class="custom-select">
          <ul class="custom-select_options">
            <li
              v-for="(option, index) in arrayOptions"
              :key="index"
              class="custom-select_options_item"
              @click="toggleOption(option, $event)"
              :class="{ active: isSelected(option) }"
            >
              {{ option }}
            </li>
          </ul>
    </div>

</template>

<script>
export default {
  props: {
    optionsData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedOptions: [],
    };
  },
  methods: {

    clearSelectedOptions() {
      this.selectedOptions = [];
       this.$emit('options-selected', this.selectedOptions);
    },

    isSelected(option) {
      return this.selectedOptions.includes(option);
    },

    toggleOption(option, event) {

      if (event.ctrlKey) {
        if (this.isSelected(option)) {
          this.selectedOptions = this.selectedOptions.filter(item => item !== option);
        } else {
          this.selectedOptions.push(option);
        }
      } else if (event.shiftKey) {
        if (this.selectedOptions.length > 0) {
          const lastSelectedIndex = this.arrayOptions.indexOf(this.selectedOptions[this.selectedOptions.length - 1]);
          const currentIndex = this.arrayOptions.indexOf(option);

          const start = Math.min(lastSelectedIndex, currentIndex);
          const end = Math.max(lastSelectedIndex, currentIndex);

          this.selectedOptions = this.arrayOptions.slice(start, end + 1);
        } else {
          this.selectedOptions.push(option);
        }
      } else {
        this.clearSelectedOptions();
        this.selectedOptions.push(option);
      }
      this.$emit('options-selected', this.selectedOptions);
      this.$bus.$emit('update-textarea');
    },
  },
  computed: {
    arrayOptions() {
      return this.optionsData;
    },

  },

  mounted() {
    this.$bus.$on('clear-search', this.clearSelectedOptions);
  }
};
</script>

<style scoped>
.custom-select {
  user-select: none;
  border: 1px solid #000;
  width: 200px;
  height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  min-height: 12rem;
  max-height: 16rem;
  padding-left: 1rem;
  background: none;
}

.custom-select_options {
  list-style: none;
  padding: 8px;
}

.custom-select_options_item {
  cursor: default;
  font-size: 1rem;
  line-height: 1.2em;
  color: black;
  white-space: nowrap;
}

.custom-select_options_item.active {
  color: white;
  background-color: rgb(53, 68, 252);
}
</style>
