<template><span></span></template>
<script>
    import Vue from "vue";
    import cookies from "vue-cookies";
    import {connectFSQWebsocket, getCsrfToken} from "@/utils/utils";

    export default {
        name: 'FreeSearchQuote',
        data(){
          return {}
        },
        methods: {
          websocket_connect: function(){
            const self = this;
            self.connection = connectFSQWebsocket();
            self.connection.onmessage = function (event) {
              const parse = JSON.parse(event.data);
              self.$store.dispatch("actFqc", parse['search_quote'] || 0);
            }
            this.connection.onerror = function (event) {
              console.error("WebSocket error:", event);
            }
          },

          showFreeQuoteCount: function(){
            const self = this;
            const cfp = cookies.get("__fp");
            const fp = self.$store.getters.fp
            if(!fp && cfp){
              self.$store.dispatch("actFp", cfp);
            }
            let options = JSON.stringify({"fp": fp});
            if (self.connection.readyState === WebSocket.OPEN) {
              self.connection.send(options);
            }
          }
        },
        mounted: function () {
          let self = this;
          self.websocket_connect();
          // Include CSRF token in WebSocket headers
          self.connection.addEventListener('open', (event) => {
              self.connection.send(JSON.stringify({
                  'type': 'websocket.handshake',
                  'headers': {
                      'X-CSRFToken': getCsrfToken()
                  }
              }));
          });
          self.$bus.$on("on_search", self.showFreeQuoteCount);
        },

        beforeDestroy() {
          let self = this;
          if (self.connection !== null) {
            self.connection.close();
            self.connection = null;
          }
          Vue.prototype.$bus.$off("fsq_retrieve");
        },
    };
</script>
