import fixture from 'can-fixture';

fixture({

        "POST /api/sm_test/{fingerprint}/": () => {
            return {id: 1, email: '', fingerprint: 'nmlhVjafcfcOW0k5', search_counter: 10, timestamp: '2023-12-25'}
        },
        "POST /api/sf_test/": () => {
            return {
                "status": 200, "message": "", "variant": "success", "data": null,
                "orderlink": "Your query was submitted. Payment: <b>OK</b>, Amount: <b>$0</b>, Quantity: <b>60</b> records<br> Use this link to find your final results in case your session expires: <a href='http://localhost:8000/api/gms/fs_e96b2d0a29ad4ea583e3b8ef/' target='_blank'>http://localhost:8000/api/gms/fs_e96b2d0a29ad4ea583e3b8ef/</a>",
                "oid": "fs_e96b2d0a29ad4ea583e3b8ef",
                "dv": 0
            }
        },
        "POST /api/sf_error_test/": () => {
            return {
                "status": 5001,
                "message": "Your free search daily limit has been reached",
                "variant": "warning",
                "data": null
            }
        },
        "POST /api/test_tok/": () => {
            return {
                "status": 200,
                "message": "",
                "variant": "success",
                "data": {
                    "fingerprint": "nmlhVjafcfcOW0k5"
                }
            }
        },
        "GET /api/ws/": () => {
            return {
                "yp": {
                    "status": 2
                },
                "bbb": {
                    "status": 2
                },
                "gloc": {
                    "status": 2
                },
                "gmap": {
                    "status": 2
                },
                "yelp": {
                    "status": 2
                },
                "custom": {
                    "status": 2
                }
            }
        },
        "GET /api/lk/": () => {
            return {
                "status": 500,
                "message": "Network Error",
                "dv": 1,
                "size": 60,
                "records": [{
                    "search_type": "business",
                    "title": "Carnitas' Snack Shack - Embarcadero",
                    "address": "1004 N Harbor Dr, San Diego, CA 92101, USA",
                    "phone": "(619) 696-7675",
                    "email": "embarcadero@carnitassnackshack.com",
                    "web": "http://carnitassnackshack.com/",
                    "social": "https://facebook.com/carnitassnacks | https://instagram.com/carnitassnacks | https://twitter.com/carnitassnacks",
                    "likes": "17K /17K",
                    "service": "$/Delivery · Contactless delivery · Takeout…",
                    "logo": "https://scontent.fmnl25-1.fna.fbcdn.net/v/t39.30808-1/215219675_4255948497793488_3651684339586390564_n.jpg?stp=dst-jpg_p200x200&_nc_cat=108&ccb=1-7&_nc_sid=5f2048&_nc_ohc=5bE-HR5e3joAX94Wzao&_nc_ht=scontent.fmnl25-1.fna&oh=00_AfBMbn6bvqsMDwFuJmqcg4nGjzehxBWOutSvaZqUBMn6GA&oe=652E5D3B",
                    "descr": "Carnitas’ Snack Shack is San Diego’s original pork house offering a wide variety of indulgent food, l",
                    "category": "Comfort Food Restaurant",
                    "builtwith": "Facebook Pixel; Google Analytics; Woocommerce; Wordpress",
                    "keyword": "San Diego restaurants",
                    "weight": 9,
                    "rel": 1.0,
                    "ratings": "4.4;5.0"
                },

                    {
                        "search_type": "business",
                        "title": "Fogo de Chão Brazilian Steakhouse",
                        "address": "668 Sixth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 338-0500",
                        "email": "info@fogodechao.com",
                        "web": "https://fogodechao.com/location/san-diego/",
                        "social": "https://facebook.com/fogo | https://facebook.com/fogorestaurants | https://instagram.com/fogo | https://tiktok.com/@fogodechao | https://twitter.com/fogorestaurants",
                        "likes": "515K /522K",
                        "service": "$$$",
                        "logo": "https://scontent-mia3-2.xx.fbcdn.net/v/t39.30808-1/241662257_4085718231555198_1222945952331458793_n.png?stp=dst-png_p200x200&_nc_cat=1&ccb=1-7&_nc_sid=5f2048&_nc_ohc=24OaX5nU2gIAX_TX3ux&_nc_ht=scontent-mia3-2.xx&oh=00_AfCtmvuEV_UmbwbcIs8t690yJmb-JBnvUFJemniiHCycaA&oe=652EC91B",
                        "descr": "Every bite is a new discovery.",
                        "category": "Brazilian Restaurant",
                        "builtwith": "Google Analytics; Google Tag; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.3;"
                    },
                    {
                        "search_type": "business",
                        "title": "The Melting Pot",
                        "address": "901 Fifth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 234-5554",
                        "email": "info@gaslampfondue.com",
                        "web": "https://www.meltingpot.com/san-diego-gaslamp-ca/",
                        "social": "https://facebook.com/meltingpotsandiegogaslamp | https://tiktok.com/@themeltingpotrestaurants | https://twitter.com/meltingpotgslmp | https://yelp.com/biz/the-melting-pot-san-diego-2",
                        "likes": "11K /11K",
                        "service": "$$",
                        "logo": "https://scontent-atl3-2.xx.fbcdn.net/v/t39.30808-1/348229150_244001978281163_3786753955532269897_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=6mgMpcA7C4EAX_iUHf7&_nc_ht=scontent-atl3-2.xx&oh=00_AfCzszfHxFKaGLH_X65SdxAVUpSp83NlzblHgEmoNXyczA&oe=652EF414",
                        "descr": "The San Diego/Gaslamp Melting Pot is a fondue lovers paradise where patrons can \"dip into something d",
                        "category": "Fondue Restaurant",
                        "builtwith": "Google Analytics",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "business",
                        "title": "The Grant Grill",
                        "address": "326 Broadway, San Diego, CA 92101, USA",
                        "phone": "(619) 744-2077",
                        "email": "pablo.leonardo@luxurycollection.com\ntheusgrant@theusgranthotel.com",
                        "web": "https://www.grantgrill.com/",
                        "social": "https://facebook.com/grantgrill | https://instagram.com/grantgrill | https://twitter.com/usgranthotel | https://yelp.com/biz/grant-grill-san-diego",
                        "likes": "1K /1K",
                        "service": "$$$/Outdoor seating",
                        "logo": "https://scontent-bos5-1.xx.fbcdn.net/v/t39.30808-1/348438188_781966413652005_4113643020024806385_n.jpg?stp=dst-jpg_p200x200&_nc_cat=106&ccb=1-7&_nc_sid=5f2048&_nc_ohc=Lt0Q1mmXSsYAX-5MNd0&_nc_ht=scontent-bos5-1.xx&oh=00_AfCPQkhVvo4afHsVfrv5UnzL4c_IWs7aZeGOKDOgkFSCKw&oe=652DDECA",
                        "descr": "California Cuisine. Craft Cocktails. Fine Wines. Iconic Setting.",
                        "category": "Local business",
                        "builtwith": "Google Analytics",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "business",
                        "title": "Dobson's Bar & Restaurant",
                        "address": "956 Broadway Cir, San Diego, CA 92101, USA",
                        "phone": "(619) 231-6771",
                        "email": "marcos@dobsonsrestaurant.com",
                        "web": "http://dobsonsrestaurant.com/",
                        "social": "https://facebook.com/401712866596529 | https://instagram.com/dobsonssandiego | https://twitter.com/dobsonssandiego",
                        "likes": "4.7K /4.7K",
                        "service": "/Dine in · Outdoor seating · In-store collection",
                        "logo": "https://scontent-cpt1-1.xx.fbcdn.net/v/t39.30808-1/346053402_201967359309673_8129260492166892803_n.jpg?stp=cp6_dst-jpg_p200x200&_nc_cat=110&ccb=1-7&_nc_sid=5f2048&_nc_ohc=cv9CXb43zoMAX-kfUT0&_nc_ht=scontent-cpt1-1.xx&oh=00_AfAbwwgMI1fuOqnMbJk5nly9rfK0TnfbU0LPJV0zLlSe7A&oe=652E3DEA",
                        "descr": "Dobson's Bar and Restaurant is a hidden treasure in the heart of Downtown San Diego/Gaslamp Quarter.",
                        "category": "American Restaurant",
                        "builtwith": "Google Analytics; Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "business",
                        "title": "World Famous",
                        "address": "711 Pacific Beach Dr, San Diego, CA 92109, USA",
                        "phone": "(858) 272-3100",
                        "email": "manager@worldfamouspb.com",
                        "web": "http://worldfamouspb.com/",
                        "social": "https://facebook.com/154979564574517 | https://instagram.com/worldfamouspb | https://twitter.com/worldfamouspb | https://yelp.com/biz/world-famous-san-diego",
                        "likes": "8.6K /8.5K",
                        "service": "$$/Dine-in · Outdoor seating · Curbside pickup",
                        "logo": "https://scontent-hou1-1.xx.fbcdn.net/v/t39.30808-1/291331876_405772691575808_3339006906707915263_n.jpg?stp=c12.0.200.200a_dst-jpg_p200x200&_nc_cat=101&ccb=1-7&_nc_sid=5f2048&_nc_ohc=8pj9U7UWAn4AX_SwCP7&_nc_ht=scontent-hou1-1.xx&oh=00_AfCkcCATcRieUH-XFUfqbTYGor4F7mOlO_Uuicvlq239Og&oe=652EA2BB",
                        "descr": "California Coastal Cuisine",
                        "category": "Seafood Restaurant",
                        "builtwith": "Google Analytics; Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.3;"
                    },
                    {
                        "search_type": "business",
                        "title": "Parma Cucina Italiana",
                        "address": "3850 Fifth Ave, San Diego, CA 92103, USA",
                        "phone": "(619) 543-0049",
                        "email": "info@parmaitaliankitchen.com",
                        "web": "https://www.parmaitaliankitchen.com/",
                        "social": "https://facebook.com/314812141967389 | https://yelp.com/biz/parma-cucina-italiana-san-diego",
                        "likes": "2.7K /2.7K",
                        "service": "$$/Outdoor seating · Curbside pickup",
                        "logo": "https://scontent.fdcf1-1.fna.fbcdn.net/v/t39.30808-1/279296157_405205308277819_1543719269828462233_n.jpg?stp=c47.0.200.200a_dst-jpg_p200x200&_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_ohc=fAOBgM_UMDAAX_HOlxg&_nc_ht=scontent.fdcf1-1.fna&oh=00_AfDY1H-d_BuOvWk5XQSy5AiBnhoqp4SLqPg8ijYHTzqftw&oe=652EAB06",
                        "descr": "Located in the heart of Hillcrest in San Diego CA, Parma offers authentic Italian food, a great vari",
                        "category": "Italian Restaurant",
                        "builtwith": "Google Analytics; Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.7;"
                    },
                    {
                        "search_type": "business",
                        "title": "Vessel Restaurant",
                        "address": "1551 Shelter Island Dr, San Diego, CA 92106, USA",
                        "phone": "(619) 819-8195",
                        "email": "aveves@konakaimarina.com\njhornick@sdkonakai.com\nkonakai@20twostudio.com\nreservations@sdkonakai.com\nsales@sdkonakai.com\nweddings@sdkonakai.com",
                        "web": "https://www.resortkonakai.com/shelter-island-restaurant/?utm_source=gmb-restaurant&utm_medium=organic&utm_campaign=gmb",
                        "social": "https://facebook.com/resortkonakai | https://facebook.com/vesselrestaurant | https://instagram.com/konakairesortsd | https://twitter.com/konakaisd",
                        "likes": "7.1K /7.4K",
                        "service": "$$$/Outdoor seating",
                        "logo": "https://scontent-atl3-1.xx.fbcdn.net/v/t39.30808-1/340471042_565780218696070_1757121604426728949_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_ohc=T2eaLoDu3hwAX9twvuS&_nc_ht=scontent-atl3-1.xx&oh=00_AfDbp-vDQ_wbC79QzWafJH8qGHlT4O1asLgkB4GYUwIT8g&oe=652E8B26",
                        "descr": "An idyllic island-style San Diego resort with astounding bay and marina views.",
                        "category": "Hotel resort",
                        "builtwith": "Stripe; Woocommerce",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.2;"
                    },
                    {
                        "search_type": "business",
                        "title": "STK Steakhouse",
                        "address": "600 F St, San Diego, CA 92101, USA",
                        "phone": "(619) 354-5988",
                        "email": "marketing@togrp.com",
                        "web": "https://stksteakhouse.com/venues/san-diego/",
                        "social": "https://facebook.com/eatstk | https://facebook.com/stkhouse | https://instagram.com/eatstk | https://tiktok.com/@eatstk | https://twitter.com/eatstk",
                        "likes": "121K /123K",
                        "service": "",
                        "logo": "https://scontent.fman2-2.fna.fbcdn.net/v/t39.30808-1/369214987_699280635567098_6878000983017625695_n.jpg?stp=dst-jpg_p200x200&_nc_cat=110&ccb=1-7&_nc_sid=5f2048&_nc_ohc=ubKu3rgMEeEAX8-wHQV&_nc_ht=scontent.fman2-2.fna&oh=00_AfAh194l92sqKYxfLkMr-LLPtqFq5YYtgw4aodPWwJkcqw&oe=652E3071",
                        "descr": "STK Steakhouse combines the modern steakhouse and chic lounge into one VIBE DINING experience.",
                        "category": "Steakhouse",
                        "builtwith": "Google Tag; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.3;"
                    },
                    {
                        "search_type": "business",
                        "title": "Prado",
                        "address": "1549 El Prado, San Diego, CA 92101, USA",
                        "phone": "(619) 557-9441",
                        "email": "social@dinecrg.com",
                        "web": "https://www.pradobalboa.com/",
                        "social": "https://facebook.com/pradobalboapark",
                        "likes": "9.9K /10K",
                        "service": "$$$/Outdoor seating",
                        "logo": "https://scontent-hou1-1.xx.fbcdn.net/v/t39.30808-1/308012631_541442107984182_8656254196429818391_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=5f2048&_nc_ohc=VwIRqeCtW_UAX_EVmlf&_nc_ht=scontent-hou1-1.xx&oh=00_AfCHjU42cOHegPbFUlUilkGQRuukIOU_5V7LlseVWxsjXQ&oe=652F1C5A",
                        "descr": "The Prado offers California cuisine with Latin flair located in the heart of San Diego's Balboa Park",
                        "category": "Restaurant",
                        "builtwith": "Google Tag; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.4;"
                    },
                    {
                        "search_type": "business",
                        "title": "Juniper & Ivy",
                        "address": "2228 Kettner Blvd, San Diego, CA 92101, USA",
                        "phone": "(619) 269-9036",
                        "email": "peyton@baybirdinc.com",
                        "web": "http://juniperandivy.com/",
                        "social": "https://facebook.com/pages/juniper-ivy-restaurant/543482805704452 | https://instagram.com/juniperandivy",
                        "likes": "11K /11K",
                        "service": "/Dine-in · Outdoor seating · In-store pickup",
                        "logo": "https://scontent-hou1-1.xx.fbcdn.net/v/t39.30808-1/359758062_284074247623867_5668692159333947679_n.png?stp=dst-png_p200x200&_nc_cat=101&ccb=1-7&_nc_sid=5f2048&_nc_ohc=Na37KiVlTH0AX9w7fZh&_nc_ht=scontent-hou1-1.xx&oh=00_AfD636Qw9mKO3ZJCPPosxX1G47S1BSvAY_QHwtegjsJCog&oe=652E51FC",
                        "descr": "From the entirely-locally-sourced and inventive menu, to the unique ambiance, and the impeccable ser",
                        "category": "American Restaurant",
                        "builtwith": "Facebook Pixel; Google Analytics; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "business",
                        "title": "Kettner Exchange",
                        "address": "2001 Kettner Blvd, San Diego, CA 92101, USA",
                        "phone": "(619) 255-2001",
                        "email": "info@kettnerexchange.com",
                        "web": "http://www.kettnerexchange.com/",
                        "social": "https://facebook.com/kettnerexchange | https://instagram.com/kettnerexchange",
                        "likes": "11K /11K",
                        "service": "/Dine in · Outdoor seating · Roadside collection…",
                        "logo": "https://scontent-cpt1-1.xx.fbcdn.net/v/t39.30808-1/301810278_454173656741128_5851578499807169833_n.jpg?stp=dst-jpg_p200x200&_nc_cat=101&ccb=1-7&_nc_sid=5f2048&_nc_ohc=UD3iPn1EFZ0AX-FjPL9&_nc_ht=scontent-cpt1-1.xx&oh=00_AfDpM6_jo3HdeY1MIdd5b5HhWfFWj_6-ZHRKNstxl5B0nw&oe=652EB7A7",
                        "descr": "",
                        "category": "Bar",
                        "builtwith": "Facebook Pixel; Google Tag; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "business",
                        "title": "Water Grill San Diego",
                        "address": "615 J St, San Diego, CA 92101, USA",
                        "phone": "(619) 717-6992",
                        "email": "ajames@kingsseafood.com",
                        "web": "https://www.watergrill.com/sandiego",
                        "social": "https://facebook.com/watergrillsandiego | https://instagram.com/watergrill | https://linkedin.com/company/kings-seafood-company",
                        "likes": "2.7K /2.8K",
                        "service": "$$$/Outdoor seating",
                        "logo": "https://scontent-dfw5-1.xx.fbcdn.net/v/t39.30808-1/348303036_940756237161483_2336536779679372324_n.jpg?stp=dst-jpg_p200x200&_nc_cat=105&ccb=1-7&_nc_sid=5f2048&_nc_ohc=gUVFG9lR7WoAX-81OmI&_nc_ht=scontent-dfw5-1.xx&oh=00_AfAQ0A4ZT7bWplCZvb9MkJHWHhJWtnbckh-zFFOx9U9SVg&oe=652DE45C",
                        "descr": "San Diego's seafood destination",
                        "category": "Seafood Restaurant",
                        "builtwith": "Google Tag; Shopify",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.4;"
                    },
                    {
                        "search_type": "business",
                        "title": "Mister A's",
                        "address": "2550 Fifth Ave 12th floor, San Diego, CA 92103, USA",
                        "phone": "(619) 239-1377",
                        "email": "info@asrestaurant.com",
                        "web": "https://www.asrestaurant.com/",
                        "social": "https://facebook.com/bertrandatmisteras | https://instagram.com/misterasrestaurant",
                        "likes": "10K /10K",
                        "service": "$$$$/Outdoor seating",
                        "logo": "https://scontent.forf1-4.fna.fbcdn.net/v/t39.30808-1/292076724_450564703741473_1201421822187855334_n.jpg?stp=dst-jpg_p200x200&_nc_cat=104&ccb=1-7&_nc_sid=5f2048&_nc_ohc=0bziImlHtqcAX9wrE8M&_nc_ht=scontent.forf1-4.fna&oh=00_AfAdOh9wpZWVRB_LlnwsuwyZC7bddHJYPtqvIagmROLM1A&oe=652DA6F5",
                        "descr": "Bertrand at Mister A's",
                        "category": "American Restaurant",
                        "builtwith": "Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "business",
                        "title": "Lionfish Modern Coastal Cuisine – San Diego",
                        "address": "435 Fifth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 738-7200",
                        "email": "hello@lionfishsd.com",
                        "web": "https://lionfishsd.com/",
                        "social": "https://facebook.com/lionfishsd | https://instagram.com/lionfishsd | https://twitter.com/lionfishsd",
                        "likes": "6.3K /6.5K",
                        "service": "/Outdoor seating · In-store collection",
                        "logo": "https://scontent.flhe7-2.fna.fbcdn.net/v/t39.30808-1/291692629_718802456066791_6656024244312436463_n.png?stp=dst-png_p200x200&_nc_cat=109&ccb=1-7&_nc_sid=5f2048&_nc_ohc=GOk5_tvMGagAX89pJEZ&_nc_ht=scontent.flhe7-2.fna&oh=00_AfCTQ0hRERDArURQQVRTRIELsB7uBU9pewgkkzZ-Up37og&oe=652EB641",
                        "descr": "The taste of fresh sustainable seafood has come to the Gaslamp Quarter.",
                        "category": "Seafood Restaurant",
                        "builtwith": "Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.4;"
                    },
                    {
                        "search_type": "business",
                        "title": "Herb & Wood",
                        "address": "2210 Kettner Blvd, San Diego, CA 92101, USA",
                        "phone": "(619) 955-8495",
                        "email": "events@herbandwood.com\ninfo@herbandwood.com",
                        "web": "https://www.herbandwood.com/",
                        "social": "https://facebook.com/herbandwood | https://facebook.com/squarespace | https://instagram.com/herbandwood | https://instagram.com/squarespace | https://twitter.com/squarespace | https://yelp.com/biz/herb-and-wood-san-diego",
                        "likes": "5.5K /5.9K",
                        "service": "$$$/Dine-in · Outdoor seating",
                        "logo": "https://scontent-dfw5-1.xx.fbcdn.net/v/t39.30808-1/274706830_4625366830924714_2782819584614438236_n.png?_nc_cat=101&ccb=1-7&_nc_sid=5f2048&_nc_ohc=kmTORKG74RMAX9TCDgM&_nc_ht=scontent-dfw5-1.xx&oh=00_AfCMN8QqxWPKx2oJ-BDiitIn-rGctIvEcn8xbpt5-iA4wg&oe=652EC02E",
                        "descr": "Herb & Wood is a fresh and exciting new concept from Malarkey and Puffer. Capturing flavors that rel",
                        "category": "Local business",
                        "builtwith": "Facebook Pixel; Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "business",
                        "title": "Greystone Prime Steakhouse & Seafood",
                        "address": "658 Fifth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 232-0225",
                        "email": "events@sandiegodininggroup.com",
                        "web": "http://www.greystonesteakhouse.com/",
                        "social": "https://facebook.com/greystonesteakhouse | https://instagram.com/greystonesteak | https://twitter.com/greystone_steak | https://yelp.com/biz/greystone-the-steakhouse-san-diego | https://youtube.com/channel/uc-cu83gr88axnpbipv3vmrq",
                        "likes": "26K /26K",
                        "service": "$$$/Dine-in · Outdoor seating · Curbside pickup",
                        "logo": "https://scontent-atl3-1.xx.fbcdn.net/v/t39.30808-1/326344366_2280480072119418_2137512960424903417_n.jpg?stp=dst-jpg_p200x200&_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_ohc=7okRVsnuyxEAX_XfaU_&_nc_ht=scontent-atl3-1.xx&oh=00_AfAxtRWx-hy4K_l4uBXeRuNhgdxKfmpp6RTRK1EBcdgHSw&oe=652EA9D5",
                        "descr": "Voted one of the Top 5 Steakhouses in California and San Diego. Home to the $100 Steak. Indulge in ou",
                        "category": "Steakhouse",
                        "builtwith": "Facebook Pixel; Google Analytics; Google Tag; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.3;"
                    },
                    {
                        "search_type": "business",
                        "title": "Starlite",
                        "address": "3175 India St, San Diego, CA 92103, USA",
                        "phone": "(619) 618-2830",
                        "email": "info@ch-projects.com\nmanager@starlitesandiego.com\nmanger@starlitesandiego.com\nstarlite@ch-projects.com",
                        "web": "http://www.starlitesandiego.com/",
                        "social": "https://facebook.com/starlitesandiego | https://instagram.com/starlitesd",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Facebook Pixel",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "business",
                        "title": "Farmers Table Little Italy",
                        "address": "550 W Date St Ste A, San Diego, CA 92101, USA",
                        "phone": "(619) 255-0958",
                        "email": "acfarmerstable@gmail.com\nalbertovfarmerscv@gmail.com\neventsfarmerstable@gmail.com\ntacfarmerstable@gmail.com\ntalbertovfarmerscv@gmail.com\nteventsfarmerstable@gmail.com",
                        "web": "https://myfarmerstable.com/",
                        "social": "https://facebook.com/farmerstablesd | https://instagram.com/farmerstablesd | https://twitter.com/farmerstablesd",
                        "likes": "9.6K /10K",
                        "service": "$$",
                        "logo": "https://scontent-iad3-1.xx.fbcdn.net/v/t39.30808-1/348271414_769063418030803_3779015333091998180_n.jpg?stp=dst-jpg_p200x200&_nc_cat=108&ccb=1-7&_nc_sid=5f2048&_nc_ohc=OSkcyBYaznsAX_MEodu&_nc_ht=scontent-iad3-1.xx&oh=00_AfBRGbZWX1G9jLHaEucs8dWAGjFeYH_pJOraIpMHHLOcvQ&oe=652F0B49",
                        "descr": "Fresh farm-to-table fare in a rustic setting. Dine at any of our four Locations in San Diego: La Mes",
                        "category": "American Restaurant",
                        "builtwith": "Google Tag; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "3.7;"
                    },
                    {
                        "search_type": "business",
                        "title": "Monello",
                        "address": "750 W Fir St, San Diego, CA 92101, USA",
                        "phone": "(619) 501-0030",
                        "email": "events@lovebencotto.com\ninfo@lovemonello.com",
                        "web": "http://www.lovemonello.com/",
                        "social": "https://facebook.com/lovemonello | https://instagram.com/lovemonello | https://twitter.com/lovemonello",
                        "likes": "6.7K /6.8K",
                        "service": "$$/Dine-in · Outdoor seating · Curbside pickup",
                        "logo": "https://scontent-lax3-2.xx.fbcdn.net/v/t39.30808-1/305325392_489446853191366_5862255355339907378_n.jpg?stp=dst-jpg_p200x200&_nc_cat=111&ccb=1-7&_nc_sid=5f2048&_nc_ohc=6BoRCqktfdwAX9jLCTg&_nc_ht=scontent-lax3-2.xx&oh=00_AfCvRF8YB-40jWTx9MzEKGPQcXfPfizE0wchH8mnfieIWQ&oe=652E9EBE",
                        "descr": "Luigi Mineo from Sicily: “Now I have Monello, I do not miss Italy that much.\" Serving: Lunch Ape",
                        "category": "Italian Restaurant",
                        "builtwith": "Google Analytics",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.4;"
                    },
                    {
                        "search_type": "business",
                        "title": "The Butcher's Cut",
                        "address": "644 Fifth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 235-8144",
                        "email": "events@sandiegodininggroup.com\ntevents@sandiegodininggroup.com",
                        "web": "http://www.butcherscutsteakhouse.com/",
                        "social": "https://facebook.com/the-butchers-cut-347734962517878 | https://instagram.com/butcherscutsd | https://twitter.com/butcherscutsd | https://youtube.com/channel/uckxlzzuvcgougitzsujo9eg",
                        "likes": "954/983",
                        "service": "/Dine in · Outdoor seating · In-store collection",
                        "logo": "https://scontent-jnb1-1.xx.fbcdn.net/v/t39.30808-1/294932063_566648378328124_8581294383317499225_n.jpg?stp=dst-jpg_p200x200&_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_ohc=hRCFLfj_ZdkAX8L2Sns&_nc_ht=scontent-jnb1-1.xx&oh=00_AfAG0PbN3tUQ427G0CC1usDvQBbetxgNCDJkfMb1eADwhQ&oe=652ECA42",
                        "descr": "A contemporary grill and premiere steakhouse that offers an unmatched selection of beef, fish, and p",
                        "category": "Steakhouse",
                        "builtwith": "Google Tag; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4;"
                    },
                    {
                        "search_type": "business",
                        "title": "Nobu San Diego",
                        "address": "207 Fifth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 814-4124",
                        "email": "globalpress@noburestaurants.com\nsandiego@noburestaurants.com",
                        "web": "https://www.noburestaurants.com/sandiego/home/?utm_source=google&utm_medium=Yext",
                        "social": "https://facebook.com/noburestaurants | https://instagram.com/noburestaurants | https://twitter.com/noburestaurants",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Google Analytics; Google Tag; Stripe",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.2;"
                    },
                    {
                        "search_type": "business",
                        "title": "Saltwater Seafood & Steak",
                        "address": "565 Fifth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 525-9990",
                        "email": "events@saltwatersandiego.com\nevents@sandiegodininggroup.com\ntevents@sandiegodininggroup.com",
                        "web": "https://saltwatersandiego.com/",
                        "social": "https://facebook.com/swfinedining | https://instagram.com/saltwater_sd | https://twitter.com/saltwater_sd",
                        "likes": "1.2K /1.2K",
                        "service": "$$$/Dine-in · Outdoor seating · In-store pickup",
                        "logo": "https://scontent.fric1-1.fna.fbcdn.net/v/t39.30808-1/391680070_739930108131772_1757481544891976858_n.jpg?stp=c9.9.182.182a_dst-jpg_p200x200&_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_ohc=qzyH_r00MuoAX-lP-sw&_nc_ht=scontent.fric1-1.fna&oh=00_AfD31WsJ2FYD_UlGES0bQLxL78uhoHIs_6riICMWPuLKBg&oe=652D8C21",
                        "descr": "Saltwater was established on the foundation to deliver a one-of-a-kind fine dining experience to the",
                        "category": "Seafood restaurant",
                        "builtwith": "Google Tag; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.2;"
                    },
                    {
                        "search_type": "web",
                        "title": "Gaslamp Fish House",
                        "address": "411 Broadway, San Diego, CA 92101, USA",
                        "phone": "(619) 795-3800",
                        "email": "gaslampfishhouse@gmail.com",
                        "web": "http://gaslampfishhouse.com/",
                        "social": "https://facebook.com/gaslampfishhouse | https://instagram.com/gaslampfishhouse | https://twitter.com/gaslampfish",
                        "likes": "2K /2K",
                        "service": "$$$/Dine-in · Outdoor seating · Curbside pickup",
                        "logo": "https://scontent-lga3-2.xx.fbcdn.net/v/t39.30808-1/360169098_736595435141729_4464065776573626765_n.jpg?stp=dst-jpg_p200x200&_nc_cat=101&ccb=1-7&_nc_sid=5f2048&_nc_ohc=ku6niuAPg24AX_6OECT&_nc_ht=scontent-lga3-2.xx&oh=00_AfAMcuaSWSUdj_lyB1wYB77F7c6PchwwFPfrb-u4laVI2g&oe=652D44F1",
                        "descr": "Gaslamp Fish House has been serving the best fish, seafood & steaks in Gaslamp district. Craft cockta",
                        "category": "Seafood Restaurant",
                        "builtwith": "Google Tag; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.2;"
                    },
                    {
                        "search_type": "web",
                        "title": "Petrini's San Diego",
                        "address": "610 W Ash St #100, San Diego, CA 92101, USA",
                        "phone": "(619) 595-0322",
                        "email": "info@petrinis-sandiego.com",
                        "web": "https://petrinis-sandiego.com/",
                        "social": "https://facebook.com/petrinissandiego | https://yelp.com/biz/petrinis-san-diego-2",
                        "likes": "750/764",
                        "service": "/Dine in · Outdoor seating · Roadside collection",
                        "logo": "https://scontent-jnb1-1.xx.fbcdn.net/v/t39.30808-1/294978077_413072700844229_4563656004683046910_n.jpg?stp=dst-jpg_p200x200&_nc_cat=111&ccb=1-7&_nc_sid=5f2048&_nc_ohc=hEkxIeE4WM0AX-5XDEC&_nc_ht=scontent-jnb1-1.xx&oh=00_AfB5hSNC75Y2pwzfl4qfaRMwgIvRx8u93AurVATHwi4wow&oe=652D9C09",
                        "descr": "Family owned and operated, Petrini’s Restaurant serves Italian peasant cuisine and “Pizza to Die",
                        "category": "Italian Restaurant",
                        "builtwith": "Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.2;"
                    },
                    {
                        "search_type": "web",
                        "title": "Asti Ristorante",
                        "address": "728 Fifth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 232-8844",
                        "email": "info@astisandiego.com",
                        "web": "http://astisandiego.com/",
                        "social": "https://facebook.com/182044791809098 | https://instagram.com/astisandiego | https://twitter.com/astisandiego | https://yelp.com/biz/asti-ristorante-san-diego",
                        "likes": "4.4K /4.3K",
                        "service": "$$/Dine-in · Outdoor seating · In-store pickup",
                        "logo": "https://scontent-lga3-1.xx.fbcdn.net/v/t39.30808-1/302114074_440074454841823_4863640227283962023_n.png?stp=dst-png_p200x200&_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=iJLbfNNErycAX_u7Z7L&_nc_ht=scontent-lga3-1.xx&oh=00_AfDkFWe46M5G8RVogFvb4XjvPl4iXS_d8GJVkwxpw_pa7g&oe=652F0FB7",
                        "descr": "Over 20 years, Asti has been serving San Diego exquisite Italian, seafood and steaks. Located on 5th",
                        "category": "Italian Restaurant",
                        "builtwith": "Facebook Pixel; Google Analytics; Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.3;"
                    },
                    {
                        "search_type": "web",
                        "title": "Top of the Market - San Diego",
                        "address": "750 N Harbor Dr, San Diego, CA 92101, USA",
                        "phone": "(619) 234-4867",
                        "email": "",
                        "web": "http://www.topofthemarketsd.com/",
                        "social": "https://facebook.com/thefishmarkets | https://instagram.com/thefishmarkets | https://twitter.com/thefishmarkets",
                        "likes": "4.5K /4.6K",
                        "service": "",
                        "logo": "https://scontent.fmnl4-3.fna.fbcdn.net/v/t39.30808-1/348267369_2883877505081209_4304403015172097968_n.png?stp=dst-png_p200x200&_nc_cat=110&ccb=1-7&_nc_sid=5f2048&_nc_ohc=CJt5RvX4IMcAX8d4Eoj&_nc_ht=scontent.fmnl4-3.fna&oh=00_AfBbjv9SOjudXAdayQpmYURgD_vc-y0046huNpZOvtiDgA&oe=652D77A5",
                        "descr": "Welcome to the official Facebook page for The Fish Market Restaurants. We're glad you're here and inv",
                        "category": "Seafood Restaurant",
                        "builtwith": "Facebook Pixel; Google Analytics; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "web",
                        "title": "CUCINA urbana",
                        "address": "505 Laurel St, San Diego, CA 92101, USA",
                        "phone": "(619) 239-2222",
                        "email": "contact@urbankitchengroup.com",
                        "web": "http://www.cucinaurbana.com/",
                        "social": "https://facebook.com/cucina | https://instagram.com/cucinarestaurants",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Google Tag; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "web",
                        "title": "Baci Restaurant",
                        "address": "1955 Morena Blvd, San Diego, CA 92110, USA",
                        "phone": "(619) 275-2094",
                        "email": "info@sandiegobaci.com",
                        "web": "http://sandiegobaci.com/",
                        "social": "https://facebook.com/sandiegobaci | https://yelp.com/biz/baci-restaurant-san-diego",
                        "likes": "765/759",
                        "service": "$$$/Dine-in · Outdoor seating",
                        "logo": "https://scontent-iad3-2.xx.fbcdn.net/v/t39.30808-1/301971438_404073618531227_5950795770997176499_n.png?stp=dst-png_p200x200&_nc_cat=111&ccb=1-7&_nc_sid=5f2048&_nc_ohc=aovOuZnD1o8AX8h_kX9&_nc_ht=scontent-iad3-2.xx&oh=00_AfCZvEz5QiUi5lAVsQm9dKQlyAEQ2v5RLDrdzMpR2BjzdA&oe=652D87E2",
                        "descr": "Baci Ristorante is a family-owned, fine dining, Italian restaurant.",
                        "category": "Italian Restaurant",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.8;"
                    },
                    {
                        "search_type": "web",
                        "title": "Animae",
                        "address": "969 Pacific Hwy, San Diego, CA 92101, USA",
                        "phone": "(619) 432-1225",
                        "email": "animae@puffermalarkeycollective.com",
                        "web": "https://www.animaesd.com/",
                        "social": "https://facebook.com/animaesd | https://instagram.com/animae.sandiego",
                        "likes": "2.9K /3.1K",
                        "service": "/Dine in",
                        "logo": "https://scontent-jnb1-1.xx.fbcdn.net/v/t39.30808-1/358681572_665591722277705_5477927646676492791_n.png?stp=dst-png_p200x200&_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=zDrTxuWElSYAX-ousjO&_nc_ht=scontent-jnb1-1.xx&oh=00_AfBzdwqPECDw69zAuVqYWTPJ0ENFmA_mAOdexFM4hh0zSQ&oe=652EA091",
                        "descr": "ANIMAE’s eclectic West Coast cuisine, layered with Asian influences, comes to life under Executive Ch",
                        "category": "Asian fusion restaurant",
                        "builtwith": "Facebook Pixel; Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "web",
                        "title": "The Westgate Room Restaurant",
                        "address": "1055 2nd Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 238-1818",
                        "email": "grandamerica@wearebreadandbutter.com\ninfo@westgatehotel.com\nsales@westgatehotel.com",
                        "web": "https://www.westgatehotel.com/dining/the-westgate-room/",
                        "social": "https://facebook.com/westgatehotel | https://instagram.com/westgatehotel | https://linkedin.com/company/the-westgate-hotel",
                        "likes": "10K /10K",
                        "service": "$$$",
                        "logo": "https://scontent-lga3-1.xx.fbcdn.net/v/t39.30808-1/278336098_10160078477988210_895815551878769555_n.jpg?stp=dst-jpg_p200x200&_nc_cat=110&ccb=1-7&_nc_sid=5f2048&_nc_ohc=vERnbdXER_UAX8M0FMM&_nc_oc=AQk0GSDN1myzDBZbtjp0ZWuOGo2qhhiDEeKU8_xNcE9YYnaX1VXZcbP0iYlWTptriEY&_nc_ht=scontent-lga3-1.xx&oh=00_AfCptK6v0dvF5klXPz8XkAkTNDth2H0zrWC8iaOkStZJcg&oe=652E4CF3",
                        "descr": "The Westgate, San Diego’s premiere hotel continues to deliver on its original vision of classic luxur",
                        "category": "Hotel",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "web",
                        "title": "The Crack Shack - Little Italy",
                        "address": "2266 Kettner Blvd, San Diego, CA 92101, USA",
                        "phone": "(619) 795-3299",
                        "email": "",
                        "web": "https://locations.crackshack.com/ca/san-diego/san-diego/",
                        "social": "https://facebook.com/crackshacklittleitaly | https://facebook.com/thecrackshackrestaurant | https://instagram.com/getcrackshacked | https://twitter.com/getcrackshacked | https://yelp.com/biz/the-crack-shack-san-diego-2",
                        "likes": "4.5K /4.7K",
                        "service": "",
                        "logo": "https://scontent.fsan1-2.fna.fbcdn.net/v/t39.30808-1/307099903_3161644137467964_2350992954822118364_n.jpg?stp=dst-jpg_p200x200&_nc_cat=101&ccb=1-7&_nc_sid=5f2048&_nc_ohc=893J2q6aUYAAX9li97C&_nc_ht=scontent.fsan1-2.fna&oh=00_AfAiCJYNe-i2TD3wE6wXZ-5cmf_nD3Xl7EyVuwrc2xhu5g&oe=652DA2D3",
                        "descr": "",
                        "category": "",
                        "builtwith": "Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "web",
                        "title": "The Fish Market - San Diego",
                        "address": "750 N Harbor Dr, San Diego, CA 92101, USA",
                        "phone": "(619) 232-3474",
                        "email": "",
                        "web": "http://www.thefishmarket.com/",
                        "social": "https://facebook.com/thefishmarkets | https://instagram.com/thefishmarkets | https://twitter.com/thefishmarkets",
                        "likes": "4.5K /4.6K",
                        "service": "",
                        "logo": "https://scontent-mia3-2.xx.fbcdn.net/v/t39.30808-1/348267369_2883877505081209_4304403015172097968_n.png?stp=dst-png_p200x200&_nc_cat=110&ccb=1-7&_nc_sid=5f2048&_nc_ohc=CJt5RvX4IMcAX-MgljU&_nc_ht=scontent-mia3-2.xx&oh=00_AfBPaFZjffiLLFmDQMExW4Y_xI5ebFVUT3zap0y3A7_BnQ&oe=652D77A5",
                        "descr": "Welcome to the official Facebook page for The Fish Market Restaurants. We're glad you're here and inv",
                        "category": "Seafood Restaurant",
                        "builtwith": "Facebook Pixel; Google Analytics; Google Tag; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.4;"
                    },
                    {
                        "search_type": "web",
                        "title": "Bandar Restaurant",
                        "address": "845 Fourth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 238-0101",
                        "email": "info@bandarrestaurant.com",
                        "web": "http://www.bandarrestaurant.com/",
                        "social": "https://facebook.com/pages/bandar-restaurant-persian-steakhouse/117524838267123 | https://instagram.com/bandarrestaurant | https://twitter.com/bandarsandiego",
                        "likes": "/2K",
                        "service": "$$$/Dine-in · Outdoor seating · In-store pickup",
                        "logo": "https://scontent-ord5-2.xx.fbcdn.net/v/t39.30808-1/240113530_327403308835578_6343069388462066568_n.jpg?stp=dst-jpg_p200x200&_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_ohc=W_TJXO-Lg6IAX9vEIQX&_nc_ht=scontent-ord5-2.xx&oh=00_AfB2xeaodn5v4du4OfANWgv__5AI1JgBUN9Cdr4h8d_saA&oe=652D93CE",
                        "descr": "Our Award Wining Menu Features: Fresh Prime Steaks, World Famous Chicken, Seafood and Classic Persia",
                        "category": "Persian/Iranian Restaurant",
                        "builtwith": "Google Analytics; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "3.9;"
                    },
                    {
                        "search_type": "web",
                        "title": "ADDISON",
                        "address": "5200 Grand Del Mar Way, San Diego, CA 92130, USA",
                        "phone": "(858) 314-2000",
                        "email": "",
                        "web": "http://www.addisondelmar.com/?utm_source=google+Maps&utm_medium=seo+maps&utm_campaign=seo+maps&y_source=1_MTU2Mzc2NTEtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
                        "social": "https://instagram.com/addisonrestaurant",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "web",
                        "title": "The Lion's Share",
                        "address": "629 Kettner Blvd, San Diego, CA 92101, USA",
                        "phone": "(619) 564-6924",
                        "email": "info@lionssharesd.com",
                        "web": "http://www.lionssharesd.com/",
                        "social": "https://instagram.com/thelionssharesd",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.7;"
                    },
                    {
                        "search_type": "web",
                        "title": "Pushkin Hookah Lounge & Bar",
                        "address": "750 Sixth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 496-1908",
                        "email": "pushkinrestaurant@gmail.com",
                        "web": "http://pushkinrestaurantsd.com/",
                        "social": "https://facebook.com/pushkinsandiego",
                        "likes": "4.1K /4.2K",
                        "service": "$$/Dine-in · Outdoor seating · Curbside pickup…",
                        "logo": "https://scontent-hou1-1.xx.fbcdn.net/v/t39.30808-1/348221708_936793240977848_5636437861072572375_n.jpg?stp=dst-jpg_p200x200&_nc_cat=106&ccb=1-7&_nc_sid=5f2048&_nc_ohc=Y6FiGsd3Q9IAX_u1jkE&_nc_ht=scontent-hou1-1.xx&oh=00_AfC87ANXSr5VsRrUy2X0MYmXUYXGRdQVSDkl8ptalZ0QYA&oe=652D4E14",
                        "descr": "At Pushkin you’ll find European cuisine elegantly crafted with modern flavor and best hookah in SD.",
                        "category": "Hookah Lounge",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "web",
                        "title": "Callie",
                        "address": "1195 Island Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 255-9696",
                        "email": "info@calliesd.com",
                        "web": "https://calliesd.com/",
                        "social": "https://facebook.com/callierestaurant | https://instagram.com/callierestaurant | https://instagram.com/folklor.la",
                        "likes": "1.1K /1.3K",
                        "service": "$$$/Dine-in",
                        "logo": "https://scontent-lga3-2.xx.fbcdn.net/v/t39.30808-1/291634428_366815268892464_6433015383704039168_n.jpg?stp=dst-jpg_p200x200&_nc_cat=105&ccb=1-7&_nc_sid=5f2048&_nc_ohc=7CsbGZRiQA4AX9TZBT-&_nc_ht=scontent-lga3-2.xx&oh=00_AfDlh2ivBKVDmB8xGS7aMQzZJgX0Qc8U6IE6CEQSUvsgYw&oe=652E8625",
                        "descr": "Callie is the first solo project by Chef Travis Swikard. Derived from the Greek \"kallos,\" meaning th",
                        "category": "Restaurant",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.7;"
                    },
                    {
                        "search_type": "web",
                        "title": "Civico 1845",
                        "address": "1845 India St, San Diego, CA 92101, USA",
                        "phone": "(619) 431-5990",
                        "email": "info@civico1845.com",
                        "web": "https://www.civico1845.com/?y_source=1_OTIzMTMwNDItNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
                        "social": "https://facebook.com/civico1845 | https://instagram.com/civico1845",
                        "likes": "4.5K /4.5K",
                        "service": "$$/Outdoor seating · In-store pickup",
                        "logo": "https://scontent-atl3-1.xx.fbcdn.net/v/t39.30808-1/305645903_757150465637528_6974819960015011535_n.jpg?stp=dst-jpg_p200x200&_nc_cat=111&ccb=1-7&_nc_sid=5f2048&_nc_ohc=cYfR1rRSp9oAX-PeAja&_nc_ht=scontent-atl3-1.xx&oh=00_AfC0FsKsccQ8gGiIbsvCsiMK0jKuBNzbaFTqeXgHeXGATQ&oe=652E7F73",
                        "descr": "Civico 1845 features a new generation of Italian cuisine - fresh, light, authentic, and vegan. Exper",
                        "category": "Italian Restaurant",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.4;"
                    },
                    {
                        "search_type": "web",
                        "title": "La Puerta",
                        "address": "560 Fourth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 696-3466",
                        "email": "",
                        "web": "https://lapuertasd.com/?utm_source=Business-Listings",
                        "social": "https://facebook.com/lapuertasd | https://instagram.com/lapuertasd | https://twitter.com/lapuertasd",
                        "likes": "13K /13K",
                        "service": "$$/Dine-in · Outdoor seating",
                        "logo": "https://scontent-bos5-1.xx.fbcdn.net/v/t39.30808-1/341096390_536554598642282_6559122814341441084_n.jpg?stp=dst-jpg_p200x200&_nc_cat=106&ccb=1-7&_nc_sid=5f2048&_nc_ohc=F3GaiB59nBoAX99nkuA&_nc_ht=scontent-bos5-1.xx&oh=00_AfDkLRysjFytyvEGUq4k5r3n4CgSRKjcuisFWN2LYIWpnQ&oe=652E4EA8",
                        "descr": "Improving people's lives one tequila shot at a time!",
                        "category": "Mexican Restaurant",
                        "builtwith": "Google Analytics; Google Tag; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "web",
                        "title": "Lou & Mickey's",
                        "address": "224 Fifth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 237-4900",
                        "email": "ajames@kingsseafood.com\ncontactus@louandmickeys.com\nkcarpenter@kingsseafood.com\nmmalicdem@louandmickeys.com",
                        "web": "https://www.louandmickeys.com/",
                        "social": "https://facebook.com/louandmickeys | https://instagram.com/louandmickeys | https://twitter.com/louandmickeys",
                        "likes": "3.5K /3.5K",
                        "service": "$$/Outdoor seating",
                        "logo": "https://scontent.fsac1-1.fna.fbcdn.net/v/t39.30808-1/361374022_763874489079629_4046450032993819922_n.jpg?stp=dst-jpg_p200x200&_nc_cat=106&ccb=1-7&_nc_sid=5f2048&_nc_ohc=HnccU2_vqp4AX_PHNGE&_nc_ht=scontent.fsac1-1.fna&oh=00_AfDhtT9WgFS7NF4LIHsmq-PRes_6BzLtREQryCREr_b27A&oe=652EE2A4",
                        "descr": "Famous Steaks, Choice Seafood & Exotic Drinks",
                        "category": "Steakhouse",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "web",
                        "title": "Born and Raised",
                        "address": "1909 India St, San Diego, CA 92101, USA",
                        "phone": "(619) 202-4577",
                        "email": "info@bornandraisedsteak.com",
                        "web": "http://bornandraisedsteak.com/",
                        "social": "",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "web",
                        "title": "Trust",
                        "address": "3752 Park Blvd, San Diego, CA 92103, USA",
                        "phone": "(619) 795-6901",
                        "email": "",
                        "web": "http://www.trustrestaurantsd.com/",
                        "social": "https://instagram.com/trustrestaurantsd",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "web",
                        "title": "Morning Glory",
                        "address": "550 W Date St Suite #C, San Diego, CA 92101, USA",
                        "phone": "(619) 629-0302",
                        "email": "",
                        "web": "https://morningglorybreakfast.com/",
                        "social": "",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 6,
                        "rel": 1.0,
                        "ratings": "4.3;"
                    },
                    {
                        "search_type": "web",
                        "title": "North Italia",
                        "address": "7055 Friars Rd, San Diego, CA 92108, USA",
                        "phone": "(619) 343-2301",
                        "email": "info@northitalia.com",
                        "web": "https://www.northitalia.com/locations/san-diego-ca-fashion-valley/",
                        "social": "https://facebook.com/northitalia | https://instagram.com/eatnorthitalia",
                        "likes": "71K /72K",
                        "service": "$$",
                        "logo": "https://scontent-lga3-1.xx.fbcdn.net/v/t39.30808-1/339926738_240439661735594_4662538898389715658_n.jpg?stp=dst-jpg_p200x200&_nc_cat=108&ccb=1-7&_nc_sid=5f2048&_nc_ohc=SnxSaf5srf8AX-seihj&_nc_ht=scontent-lga3-1.xx&oh=00_AfBko-thQrnnQgmrOuX33dvLehHfIwOgGUMI9Rz_d70kxw&oe=652E6412",
                        "descr": "Not all carbs are created equal.",
                        "category": "Italian Restaurant",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 6,
                        "rel": 1.0,
                        "ratings": "4.4;"
                    },
                    {
                        "search_type": "web",
                        "title": "Hob Nob Hill",
                        "address": "2271 First Ave., San Diego, CA 92101, USA",
                        "phone": "(619) 239-8176",
                        "email": "hobnobhill1944@gmail.com",
                        "web": "https://hobnobhill.com/?y_source=1_MTQ2MTA4ODEtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
                        "social": "",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 6,
                        "rel": 1.0,
                        "ratings": "4.4;"
                    },
                    {
                        "search_type": "web",
                        "title": "Hodad's Downtown",
                        "address": "945 Broadway, San Diego, CA 92101, USA",
                        "phone": "(619) 234-6323",
                        "email": "",
                        "web": "http://hodadies.com/",
                        "social": "",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Google Analytics; Stripe; Woocommerce",
                        "keyword": "San Diego restaurants",
                        "weight": 6,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "web",
                        "title": "Cannonball",
                        "address": "3105 Ocean Front Walk, San Diego, CA 92109, USA",
                        "phone": "(858) 228-9304",
                        "email": "cannonballsd@belmontpark.com",
                        "web": "http://www.cannonballsd.com/",
                        "social": "https://facebook.com/cannonballsd | https://instagram.com/cannonballsd | https://twitter.com/cannonballsd",
                        "likes": "7.5K /7.5K",
                        "service": "/Outdoor seating",
                        "logo": "https://scontent-cgk1-2.xx.fbcdn.net/v/t39.30808-1/313248816_512409964232618_2537696942828203172_n.jpg?stp=dst-jpg_p200x200&_nc_cat=106&ccb=1-7&_nc_sid=5f2048&_nc_ohc=a2yv1octDloAX9h6ccd&_nc_ht=scontent-cgk1-2.xx&oh=00_AfC5QwqW9JCchPM-jp4EcUkiJBNR1pO2s8ZIfhkZJGTXHQ&oe=652D3A4A",
                        "descr": "Sushi with a splash! Besides the delicious inventive sushi and California cuisine inspired by Pacifi",
                        "category": "Sushi Restaurant",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 6,
                        "rel": 1.0,
                        "ratings": "4.3;"
                    },
                    {
                        "search_type": "web",
                        "title": "Davanti Enoteca",
                        "address": "1655 India St, San Diego, CA 92101, USA",
                        "phone": "(619) 237-9606",
                        "email": "",
                        "web": "http://www.davantienoteca.com/sandiego",
                        "social": "https://facebook.com/davantienoteca | https://facebook.com/davantisandiego | https://instagram.com/davanti_enoteca | https://twitter.com/davanti_enoteca",
                        "likes": "5.4K /5.4K",
                        "service": "$$",
                        "logo": "https://scontent-dfw5-1.xx.fbcdn.net/v/t39.30808-1/294673837_1030915094354034_4429320768649353835_n.png?stp=dst-png_p200x200&_nc_cat=105&ccb=1-7&_nc_sid=5f2048&_nc_ohc=03WxtN8ArAMAX_Nlmx3&_nc_ht=scontent-dfw5-1.xx&oh=00_AfDibqiqryBXGwLUbH0KILI1VRskhH1L3M1KzMhpmgLoQQ&oe=652DC6BA",
                        "descr": "A member of the Francesca's Restaurant Group; Davanti Enoteca is a wine bar that offers shareable pl",
                        "category": "Italian Restaurant",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 6,
                        "rel": 1.0,
                        "ratings": "4.4;"
                    },
                    {
                        "search_type": "web",
                        "title": "Fort Oak",
                        "address": "1011 Fort Stockton Dr, San Diego, CA 92103, USA",
                        "phone": "(619) 722-3398",
                        "email": "",
                        "web": "https://fortoaksd.com/",
                        "social": "https://instagram.com/fortoaksd",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 6,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "web",
                        "title": "Cafe Sevilla of San Diego",
                        "address": "353 Fifth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 233-5979",
                        "email": "",
                        "web": "https://www.cafesevilla.com/",
                        "social": "",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Facebook Pixel; Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 6,
                        "rel": 1.0,
                        "ratings": "4.3;"
                    },
                    {
                        "search_type": "web",
                        "title": "Ironside Fish & Oyster",
                        "address": "1654 India St, San Diego, CA 92101, USA",
                        "phone": "(619) 269-3033",
                        "email": "",
                        "web": "https://ironsidefishandoyster.com/",
                        "social": "",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 6,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "web",
                        "title": "Fleming’s Prime Steakhouse & Wine Bar",
                        "address": "380 K Street, Fourth Ave at, San Diego, CA 92101, USA",
                        "phone": "(619) 237-1155",
                        "email": "",
                        "web": "https://www.flemingssteakhouse.com/Locations/CA/San-Diego?y_source=1_MTU1MDMxNzQtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
                        "social": "",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 5,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "web",
                        "title": "Claim Jumper Steakhouse & Bar - San Diego, CA",
                        "address": "1355 N Harbor Dr, San Diego, CA 92101, USA",
                        "phone": "(619) 531-7994",
                        "email": "",
                        "web": "https://www.claimjumper.com/",
                        "social": "",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 5,
                        "rel": 1.0,
                        "ratings": "4;"
                    },
                    {
                        "search_type": "web",
                        "title": "Buon Appetito Restaurant",
                        "address": "1609 India St, San Diego, CA 92101, USA",
                        "phone": "(619) 238-9880",
                        "email": "",
                        "web": "https://www.buonappetitosandiego.com/",
                        "social": "",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 5,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "business",
                        "title": "Cowboy Star Restaurant and Butcher Shop",
                        "address": "640 Tenth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 450-5880",
                        "email": "",
                        "web": "http://www.thecowboystar.com/",
                        "social": "",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 5,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "business",
                        "title": "Eddie V's Prime Seafood",
                        "address": "789 W Harbor Dr Suite 158, San Diego, CA 92101, USA",
                        "phone": "(619) 615-0281",
                        "email": "",
                        "web": "https://www.eddiev.com/locations/ca/san-diego/san-diego/8514?cmpid=br:ev_ag:ie_ch:dry_ca:EVGMB_sn:gmb_gt:san-diego-ca-8514_pl:locurl_rd:1013",
                        "social": "",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 5,
                        "rel": 1.0,
                        "ratings": "4.7;"
                    },
                    {
                        "search_type": "business",
                        "title": "De Nada Kitchen & Market",
                        "address": "2123 Adams Ave, San Diego, CA 92116, USA",
                        "phone": "(619) 269-6612",
                        "email": "",
                        "web": "",
                        "social": "",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 4,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "business",
                        "title": "Buona Forchetta - South Park",
                        "address": "3001 Beech St, San Diego, CA 92102, USA",
                        "phone": "(619) 381-4844",
                        "email": "",
                        "web": "",
                        "social": "",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 4,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "business",
                        "title": "Roy's Restaurant",
                        "address": "333 W Harbor Dr, San Diego, CA 92101, USA",
                        "phone": "(619) 239-7697",
                        "email": "",
                        "web": "",
                        "social": "",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 4,
                        "rel": 1.0,
                        "ratings": "4.2;"
                    }
                ]
            }
        },
        "GET /api/records/{oid}/": () => {
            return {
                "status": 500,
                "message": "Network Error",
                "dv": 1,
                "size": 60,
                "records": [
                    {
                        "search_type": "people",
                        "title": "Carnitas' Snack Shack - Embarcadero",
                        "address": "1004 N Harbor Dr, San Diego, CA 92101, USA",
                        "phone": "(619) 696-7675",
                        "email": "embarcadero@carnitassnackshack.com",
                        "web": "http://carnitassnackshack.com/",
                        "social": "https://facebook.com/carnitassnacks | https://instagram.com/carnitassnacks | https://twitter.com/carnitassnacks",
                        "likes": "17K /17K",
                        "service": "$/Delivery · Contactless delivery · Takeout…",
                        "logo": "https://scontent.fmnl25-1.fna.fbcdn.net/v/t39.30808-1/215219675_4255948497793488_3651684339586390564_n.jpg?stp=dst-jpg_p200x200&_nc_cat=108&ccb=1-7&_nc_sid=5f2048&_nc_ohc=5bE-HR5e3joAX94Wzao&_nc_ht=scontent.fmnl25-1.fna&oh=00_AfBMbn6bvqsMDwFuJmqcg4nGjzehxBWOutSvaZqUBMn6GA&oe=652E5D3B",
                        "descr": "Carnitas’ Snack Shack is San Diego’s original pork house offering a wide variety of indulgent food, l",
                        "category": "Comfort Food Restaurant",
                        "builtwith": "Facebook Pixel; Google Analytics; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.4;12,000"
                    },
                    {
                        "search_type": "business",
                        "title": "Fogo de Chão Brazilian Steakhouse",
                        "address": "668 Sixth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 338-0500",
                        "email": "info@fogodechao.com",
                        "web": "https://fogodechao.com/location/san-diego/",
                        "social": "https://facebook.com/fogo | https://facebook.com/fogorestaurants | https://instagram.com/fogo | https://tiktok.com/@fogodechao | https://twitter.com/fogorestaurants",
                        "likes": "515K /522K",
                        "service": "$$$",
                        "logo": "https://scontent-mia3-2.xx.fbcdn.net/v/t39.30808-1/241662257_4085718231555198_1222945952331458793_n.png?stp=dst-png_p200x200&_nc_cat=1&ccb=1-7&_nc_sid=5f2048&_nc_ohc=24OaX5nU2gIAX_TX3ux&_nc_ht=scontent-mia3-2.xx&oh=00_AfCtmvuEV_UmbwbcIs8t690yJmb-JBnvUFJemniiHCycaA&oe=652EC91B",
                        "descr": "Every bite is a new discovery.",
                        "category": "Brazilian Restaurant",
                        "builtwith": "Google Analytics; Google Tag; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.3;1,000"
                    },
                    {
                        "search_type": "business",
                        "title": "The Melting Pot",
                        "address": "901 Fifth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 234-5554",
                        "email": "info@gaslampfondue.com",
                        "web": "https://www.meltingpot.com/san-diego-gaslamp-ca/",
                        "social": "https://facebook.com/meltingpotsandiegogaslamp | https://tiktok.com/@themeltingpotrestaurants | https://twitter.com/meltingpotgslmp | https://yelp.com/biz/the-melting-pot-san-diego-2",
                        "likes": "11K /11K",
                        "service": "$$",
                        "logo": "https://scontent-atl3-2.xx.fbcdn.net/v/t39.30808-1/348229150_244001978281163_3786753955532269897_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=6mgMpcA7C4EAX_iUHf7&_nc_ht=scontent-atl3-2.xx&oh=00_AfCzszfHxFKaGLH_X65SdxAVUpSp83NlzblHgEmoNXyczA&oe=652EF414",
                        "descr": "The San Diego/Gaslamp Melting Pot is a fondue lovers paradise where patrons can \"dip into something d",
                        "category": "Fondue Restaurant",
                        "builtwith": "Google Analytics",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.5;100"
                    },
                    {
                        "search_type": "business",
                        "title": "The Grant Grill",
                        "address": "326 Broadway, San Diego, CA 92101, USA",
                        "phone": "(619) 744-2077",
                        "email": "pablo.leonardo@luxurycollection.com\ntheusgrant@theusgranthotel.com",
                        "web": "https://www.grantgrill.com/",
                        "social": "https://facebook.com/grantgrill | https://instagram.com/grantgrill | https://twitter.com/usgranthotel | https://yelp.com/biz/grant-grill-san-diego",
                        "likes": "1K /1K",
                        "service": "$$$/Outdoor seating",
                        "logo": "https://scontent-bos5-1.xx.fbcdn.net/v/t39.30808-1/348438188_781966413652005_4113643020024806385_n.jpg?stp=dst-jpg_p200x200&_nc_cat=106&ccb=1-7&_nc_sid=5f2048&_nc_ohc=Lt0Q1mmXSsYAX-5MNd0&_nc_ht=scontent-bos5-1.xx&oh=00_AfCPQkhVvo4afHsVfrv5UnzL4c_IWs7aZeGOKDOgkFSCKw&oe=652DDECA",
                        "descr": "California Cuisine. Craft Cocktails. Fine Wines. Iconic Setting.",
                        "category": "Local business",
                        "builtwith": "Google Analytics",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "business",
                        "title": "Dobson's Bar & Restaurant",
                        "address": "956 Broadway Cir, San Diego, CA 92101, USA",
                        "phone": "(619) 231-6771",
                        "email": "marcos@dobsonsrestaurant.com",
                        "web": "http://dobsonsrestaurant.com/",
                        "social": "https://facebook.com/401712866596529 | https://instagram.com/dobsonssandiego | https://twitter.com/dobsonssandiego",
                        "likes": "4.7K /4.7K",
                        "service": "/Dine in · Outdoor seating · In-store collection",
                        "logo": "https://scontent-cpt1-1.xx.fbcdn.net/v/t39.30808-1/346053402_201967359309673_8129260492166892803_n.jpg?stp=cp6_dst-jpg_p200x200&_nc_cat=110&ccb=1-7&_nc_sid=5f2048&_nc_ohc=cv9CXb43zoMAX-kfUT0&_nc_ht=scontent-cpt1-1.xx&oh=00_AfAbwwgMI1fuOqnMbJk5nly9rfK0TnfbU0LPJV0zLlSe7A&oe=652E3DEA",
                        "descr": "Dobson's Bar and Restaurant is a hidden treasure in the heart of Downtown San Diego/Gaslamp Quarter.",
                        "category": "American Restaurant",
                        "builtwith": "Google Analytics; Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "business",
                        "title": "World Famous",
                        "address": "711 Pacific Beach Dr, San Diego, CA 92109, USA",
                        "phone": "(858) 272-3100",
                        "email": "manager@worldfamouspb.com",
                        "web": "http://worldfamouspb.com/",
                        "social": "https://facebook.com/154979564574517 | https://instagram.com/worldfamouspb | https://twitter.com/worldfamouspb | https://yelp.com/biz/world-famous-san-diego",
                        "likes": "8.6K /8.5K",
                        "service": "$$/Dine-in · Outdoor seating · Curbside pickup",
                        "logo": "https://scontent-hou1-1.xx.fbcdn.net/v/t39.30808-1/291331876_405772691575808_3339006906707915263_n.jpg?stp=c12.0.200.200a_dst-jpg_p200x200&_nc_cat=101&ccb=1-7&_nc_sid=5f2048&_nc_ohc=8pj9U7UWAn4AX_SwCP7&_nc_ht=scontent-hou1-1.xx&oh=00_AfCkcCATcRieUH-XFUfqbTYGor4F7mOlO_Uuicvlq239Og&oe=652EA2BB",
                        "descr": "California Coastal Cuisine",
                        "category": "Seafood Restaurant",
                        "builtwith": "Google Analytics; Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.3;"
                    },
                    {
                        "search_type": "business",
                        "title": "Parma Cucina Italiana",
                        "address": "3850 Fifth Ave, San Diego, CA 92103, USA",
                        "phone": "(619) 543-0049",
                        "email": "info@parmaitaliankitchen.com",
                        "web": "https://www.parmaitaliankitchen.com/",
                        "social": "https://facebook.com/314812141967389 | https://yelp.com/biz/parma-cucina-italiana-san-diego",
                        "likes": "2.7K /2.7K",
                        "service": "$$/Outdoor seating · Curbside pickup",
                        "logo": "https://scontent.fdcf1-1.fna.fbcdn.net/v/t39.30808-1/279296157_405205308277819_1543719269828462233_n.jpg?stp=c47.0.200.200a_dst-jpg_p200x200&_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_ohc=fAOBgM_UMDAAX_HOlxg&_nc_ht=scontent.fdcf1-1.fna&oh=00_AfDY1H-d_BuOvWk5XQSy5AiBnhoqp4SLqPg8ijYHTzqftw&oe=652EAB06",
                        "descr": "Located in the heart of Hillcrest in San Diego CA, Parma offers authentic Italian food, a great vari",
                        "category": "Italian Restaurant",
                        "builtwith": "Google Analytics; Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.7;"
                    },
                    {
                        "search_type": "web",
                        "title": "Vessel Restaurant",
                        "address": "1551 Shelter Island Dr, San Diego, CA 92106, USA",
                        "phone": "(619) 819-8195",
                        "email": "aveves@konakaimarina.com\njhornick@sdkonakai.com\nkonakai@20twostudio.com\nreservations@sdkonakai.com\nsales@sdkonakai.com\nweddings@sdkonakai.com",
                        "web": "https://www.resortkonakai.com/shelter-island-restaurant/?utm_source=gmb-restaurant&utm_medium=organic&utm_campaign=gmb",
                        "social": "https://facebook.com/resortkonakai | https://facebook.com/vesselrestaurant | https://instagram.com/konakairesortsd | https://twitter.com/konakaisd",
                        "likes": "7.1K /7.4K",
                        "service": "$$$/Outdoor seating",
                        "logo": "https://scontent-atl3-1.xx.fbcdn.net/v/t39.30808-1/340471042_565780218696070_1757121604426728949_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_ohc=T2eaLoDu3hwAX9twvuS&_nc_ht=scontent-atl3-1.xx&oh=00_AfDbp-vDQ_wbC79QzWafJH8qGHlT4O1asLgkB4GYUwIT8g&oe=652E8B26",
                        "descr": "An idyllic island-style San Diego resort with astounding bay and marina views.",
                        "category": "Hotel resort",
                        "builtwith": "Stripe; Woocommerce",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.2;"
                    },
                    {
                        "search_type": "web",
                        "title": "STK Steakhouse",
                        "address": "600 F St, San Diego, CA 92101, USA",
                        "phone": "(619) 354-5988",
                        "email": "marketing@togrp.com",
                        "web": "https://stksteakhouse.com/venues/san-diego/",
                        "social": "https://facebook.com/eatstk | https://facebook.com/stkhouse | https://instagram.com/eatstk | https://tiktok.com/@eatstk | https://twitter.com/eatstk",
                        "likes": "121K /123K",
                        "service": "",
                        "logo": "https://scontent.fman2-2.fna.fbcdn.net/v/t39.30808-1/369214987_699280635567098_6878000983017625695_n.jpg?stp=dst-jpg_p200x200&_nc_cat=110&ccb=1-7&_nc_sid=5f2048&_nc_ohc=ubKu3rgMEeEAX8-wHQV&_nc_ht=scontent.fman2-2.fna&oh=00_AfAh194l92sqKYxfLkMr-LLPtqFq5YYtgw4aodPWwJkcqw&oe=652E3071",
                        "descr": "STK Steakhouse combines the modern steakhouse and chic lounge into one VIBE DINING experience.",
                        "category": "Steakhouse",
                        "builtwith": "Google Tag; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.3;"
                    },
                    {
                        "search_type": "web",
                        "title": "Prado",
                        "address": "1549 El Prado, San Diego, CA 92101, USA",
                        "phone": "(619) 557-9441",
                        "email": "social@dinecrg.com",
                        "web": "https://www.pradobalboa.com/",
                        "social": "https://facebook.com/pradobalboapark",
                        "likes": "9.9K /10K",
                        "service": "$$$/Outdoor seating",
                        "logo": "https://scontent-hou1-1.xx.fbcdn.net/v/t39.30808-1/308012631_541442107984182_8656254196429818391_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=5f2048&_nc_ohc=VwIRqeCtW_UAX_EVmlf&_nc_ht=scontent-hou1-1.xx&oh=00_AfCHjU42cOHegPbFUlUilkGQRuukIOU_5V7LlseVWxsjXQ&oe=652F1C5A",
                        "descr": "The Prado offers California cuisine with Latin flair located in the heart of San Diego's Balboa Park",
                        "category": "Restaurant",
                        "builtwith": "Google Tag; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.4;"
                    },
                    {
                        "search_type": "web",
                        "title": "Juniper & Ivy",
                        "address": "2228 Kettner Blvd, San Diego, CA 92101, USA",
                        "phone": "(619) 269-9036",
                        "email": "peyton@baybirdinc.com",
                        "web": "http://juniperandivy.com/",
                        "social": "https://facebook.com/pages/juniper-ivy-restaurant/543482805704452 | https://instagram.com/juniperandivy",
                        "likes": "11K /11K",
                        "service": "/Dine-in · Outdoor seating · In-store pickup",
                        "logo": "https://scontent-hou1-1.xx.fbcdn.net/v/t39.30808-1/359758062_284074247623867_5668692159333947679_n.png?stp=dst-png_p200x200&_nc_cat=101&ccb=1-7&_nc_sid=5f2048&_nc_ohc=Na37KiVlTH0AX9w7fZh&_nc_ht=scontent-hou1-1.xx&oh=00_AfD636Qw9mKO3ZJCPPosxX1G47S1BSvAY_QHwtegjsJCog&oe=652E51FC",
                        "descr": "From the entirely-locally-sourced and inventive menu, to the unique ambiance, and the impeccable ser",
                        "category": "American Restaurant",
                        "builtwith": "Facebook Pixel; Google Analytics; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "web",
                        "title": "Kettner Exchange",
                        "address": "2001 Kettner Blvd, San Diego, CA 92101, USA",
                        "phone": "(619) 255-2001",
                        "email": "info@kettnerexchange.com",
                        "web": "http://www.kettnerexchange.com/",
                        "social": "https://facebook.com/kettnerexchange | https://instagram.com/kettnerexchange",
                        "likes": "11K /11K",
                        "service": "/Dine in · Outdoor seating · Roadside collection…",
                        "logo": "https://scontent-cpt1-1.xx.fbcdn.net/v/t39.30808-1/301810278_454173656741128_5851578499807169833_n.jpg?stp=dst-jpg_p200x200&_nc_cat=101&ccb=1-7&_nc_sid=5f2048&_nc_ohc=UD3iPn1EFZ0AX-FjPL9&_nc_ht=scontent-cpt1-1.xx&oh=00_AfDpM6_jo3HdeY1MIdd5b5HhWfFWj_6-ZHRKNstxl5B0nw&oe=652EB7A7",
                        "descr": "",
                        "category": "Bar",
                        "builtwith": "Facebook Pixel; Google Tag; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "web",
                        "title": "Water Grill San Diego",
                        "address": "615 J St, San Diego, CA 92101, USA",
                        "phone": "(619) 717-6992",
                        "email": "ajames@kingsseafood.com",
                        "web": "https://www.watergrill.com/sandiego",
                        "social": "https://facebook.com/watergrillsandiego | https://instagram.com/watergrill | https://linkedin.com/company/kings-seafood-company",
                        "likes": "2.7K /2.8K",
                        "service": "$$$/Outdoor seating",
                        "logo": "https://scontent-dfw5-1.xx.fbcdn.net/v/t39.30808-1/348303036_940756237161483_2336536779679372324_n.jpg?stp=dst-jpg_p200x200&_nc_cat=105&ccb=1-7&_nc_sid=5f2048&_nc_ohc=gUVFG9lR7WoAX-81OmI&_nc_ht=scontent-dfw5-1.xx&oh=00_AfAQ0A4ZT7bWplCZvb9MkJHWHhJWtnbckh-zFFOx9U9SVg&oe=652DE45C",
                        "descr": "San Diego's seafood destination",
                        "category": "Seafood Restaurant",
                        "builtwith": "Google Tag; Shopify",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.4;"
                    },
                    {
                        "search_type": "web",
                        "title": "Mister A's",
                        "address": "2550 Fifth Ave 12th floor, San Diego, CA 92103, USA",
                        "phone": "(619) 239-1377",
                        "email": "info@asrestaurant.com",
                        "web": "https://www.asrestaurant.com/",
                        "social": "https://facebook.com/bertrandatmisteras | https://instagram.com/misterasrestaurant",
                        "likes": "10K /10K",
                        "service": "$$$$/Outdoor seating",
                        "logo": "https://scontent.forf1-4.fna.fbcdn.net/v/t39.30808-1/292076724_450564703741473_1201421822187855334_n.jpg?stp=dst-jpg_p200x200&_nc_cat=104&ccb=1-7&_nc_sid=5f2048&_nc_ohc=0bziImlHtqcAX9wrE8M&_nc_ht=scontent.forf1-4.fna&oh=00_AfAdOh9wpZWVRB_LlnwsuwyZC7bddHJYPtqvIagmROLM1A&oe=652DA6F5",
                        "descr": "Bertrand at Mister A's",
                        "category": "American Restaurant",
                        "builtwith": "Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "web",
                        "title": "Lionfish Modern Coastal Cuisine – San Diego",
                        "address": "435 Fifth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 738-7200",
                        "email": "hello@lionfishsd.com",
                        "web": "https://lionfishsd.com/",
                        "social": "https://facebook.com/lionfishsd | https://instagram.com/lionfishsd | https://twitter.com/lionfishsd",
                        "likes": "6.3K /6.5K",
                        "service": "/Outdoor seating · In-store collection",
                        "logo": "https://scontent.flhe7-2.fna.fbcdn.net/v/t39.30808-1/291692629_718802456066791_6656024244312436463_n.png?stp=dst-png_p200x200&_nc_cat=109&ccb=1-7&_nc_sid=5f2048&_nc_ohc=GOk5_tvMGagAX89pJEZ&_nc_ht=scontent.flhe7-2.fna&oh=00_AfCTQ0hRERDArURQQVRTRIELsB7uBU9pewgkkzZ-Up37og&oe=652EB641",
                        "descr": "The taste of fresh sustainable seafood has come to the Gaslamp Quarter.",
                        "category": "Seafood Restaurant",
                        "builtwith": "Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.4;"
                    },
                    {
                        "search_type": "web",
                        "title": "Herb & Wood",
                        "address": "2210 Kettner Blvd, San Diego, CA 92101, USA",
                        "phone": "(619) 955-8495",
                        "email": "events@herbandwood.com\ninfo@herbandwood.com",
                        "web": "https://www.herbandwood.com/",
                        "social": "https://facebook.com/herbandwood | https://facebook.com/squarespace | https://instagram.com/herbandwood | https://instagram.com/squarespace | https://twitter.com/squarespace | https://yelp.com/biz/herb-and-wood-san-diego",
                        "likes": "5.5K /5.9K",
                        "service": "$$$/Dine-in · Outdoor seating",
                        "logo": "https://scontent-dfw5-1.xx.fbcdn.net/v/t39.30808-1/274706830_4625366830924714_2782819584614438236_n.png?_nc_cat=101&ccb=1-7&_nc_sid=5f2048&_nc_ohc=kmTORKG74RMAX9TCDgM&_nc_ht=scontent-dfw5-1.xx&oh=00_AfCMN8QqxWPKx2oJ-BDiitIn-rGctIvEcn8xbpt5-iA4wg&oe=652EC02E",
                        "descr": "Herb & Wood is a fresh and exciting new concept from Malarkey and Puffer. Capturing flavors that rel",
                        "category": "Local business",
                        "builtwith": "Facebook Pixel; Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "web",
                        "title": "Greystone Prime Steakhouse & Seafood",
                        "address": "658 Fifth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 232-0225",
                        "email": "events@sandiegodininggroup.com",
                        "web": "http://www.greystonesteakhouse.com/",
                        "social": "https://facebook.com/greystonesteakhouse | https://instagram.com/greystonesteak | https://twitter.com/greystone_steak | https://yelp.com/biz/greystone-the-steakhouse-san-diego | https://youtube.com/channel/uc-cu83gr88axnpbipv3vmrq",
                        "likes": "26K /26K",
                        "service": "$$$/Dine-in · Outdoor seating · Curbside pickup",
                        "logo": "https://scontent-atl3-1.xx.fbcdn.net/v/t39.30808-1/326344366_2280480072119418_2137512960424903417_n.jpg?stp=dst-jpg_p200x200&_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_ohc=7okRVsnuyxEAX_XfaU_&_nc_ht=scontent-atl3-1.xx&oh=00_AfAxtRWx-hy4K_l4uBXeRuNhgdxKfmpp6RTRK1EBcdgHSw&oe=652EA9D5",
                        "descr": "Voted one of the Top 5 Steakhouses in California and San Diego. Home to the $100 Steak. Indulge in ou",
                        "category": "Steakhouse",
                        "builtwith": "Facebook Pixel; Google Analytics; Google Tag; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.3;"
                    },
                    {
                        "search_type": "web",
                        "title": "Starlite",
                        "address": "3175 India St, San Diego, CA 92103, USA",
                        "phone": "(619) 618-2830",
                        "email": "info@ch-projects.com\nmanager@starlitesandiego.com\nmanger@starlitesandiego.com\nstarlite@ch-projects.com",
                        "web": "http://www.starlitesandiego.com/",
                        "social": "https://facebook.com/starlitesandiego | https://instagram.com/starlitesd",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Facebook Pixel",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "web",
                        "title": "Farmers Table Little Italy",
                        "address": "550 W Date St Ste A, San Diego, CA 92101, USA",
                        "phone": "(619) 255-0958",
                        "email": "acfarmerstable@gmail.com\nalbertovfarmerscv@gmail.com\neventsfarmerstable@gmail.com\ntacfarmerstable@gmail.com\ntalbertovfarmerscv@gmail.com\nteventsfarmerstable@gmail.com",
                        "web": "https://myfarmerstable.com/",
                        "social": "https://facebook.com/farmerstablesd | https://instagram.com/farmerstablesd | https://twitter.com/farmerstablesd",
                        "likes": "9.6K /10K",
                        "service": "$$",
                        "logo": "https://scontent-iad3-1.xx.fbcdn.net/v/t39.30808-1/348271414_769063418030803_3779015333091998180_n.jpg?stp=dst-jpg_p200x200&_nc_cat=108&ccb=1-7&_nc_sid=5f2048&_nc_ohc=OSkcyBYaznsAX_MEodu&_nc_ht=scontent-iad3-1.xx&oh=00_AfBRGbZWX1G9jLHaEucs8dWAGjFeYH_pJOraIpMHHLOcvQ&oe=652F0B49",
                        "descr": "Fresh farm-to-table fare in a rustic setting. Dine at any of our four Locations in San Diego: La Mes",
                        "category": "American Restaurant",
                        "builtwith": "Google Tag; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "3.7;"
                    },
                    {
                        "search_type": "web",
                        "title": "Monello",
                        "address": "750 W Fir St, San Diego, CA 92101, USA",
                        "phone": "(619) 501-0030",
                        "email": "events@lovebencotto.com\ninfo@lovemonello.com",
                        "web": "http://www.lovemonello.com/",
                        "social": "https://facebook.com/lovemonello | https://instagram.com/lovemonello | https://twitter.com/lovemonello",
                        "likes": "6.7K /6.8K",
                        "service": "$$/Dine-in · Outdoor seating · Curbside pickup",
                        "logo": "https://scontent-lax3-2.xx.fbcdn.net/v/t39.30808-1/305325392_489446853191366_5862255355339907378_n.jpg?stp=dst-jpg_p200x200&_nc_cat=111&ccb=1-7&_nc_sid=5f2048&_nc_ohc=6BoRCqktfdwAX9jLCTg&_nc_ht=scontent-lax3-2.xx&oh=00_AfCvRF8YB-40jWTx9MzEKGPQcXfPfizE0wchH8mnfieIWQ&oe=652E9EBE",
                        "descr": "Luigi Mineo from Sicily: “Now I have Monello, I do not miss Italy that much.\" Serving: Lunch Ape",
                        "category": "Italian Restaurant",
                        "builtwith": "Google Analytics",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.4;"
                    },
                    {
                        "search_type": "web",
                        "title": "The Butcher's Cut",
                        "address": "644 Fifth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 235-8144",
                        "email": "events@sandiegodininggroup.com\ntevents@sandiegodininggroup.com",
                        "web": "http://www.butcherscutsteakhouse.com/",
                        "social": "https://facebook.com/the-butchers-cut-347734962517878 | https://instagram.com/butcherscutsd | https://twitter.com/butcherscutsd | https://youtube.com/channel/uckxlzzuvcgougitzsujo9eg",
                        "likes": "954/983",
                        "service": "/Dine in · Outdoor seating · In-store collection",
                        "logo": "https://scontent-jnb1-1.xx.fbcdn.net/v/t39.30808-1/294932063_566648378328124_8581294383317499225_n.jpg?stp=dst-jpg_p200x200&_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_ohc=hRCFLfj_ZdkAX8L2Sns&_nc_ht=scontent-jnb1-1.xx&oh=00_AfAG0PbN3tUQ427G0CC1usDvQBbetxgNCDJkfMb1eADwhQ&oe=652ECA42",
                        "descr": "A contemporary grill and premiere steakhouse that offers an unmatched selection of beef, fish, and p",
                        "category": "Steakhouse",
                        "builtwith": "Google Tag; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4;"
                    },
                    {
                        "search_type": "web",
                        "title": "Nobu San Diego",
                        "address": "207 Fifth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 814-4124",
                        "email": "globalpress@noburestaurants.com\nsandiego@noburestaurants.com",
                        "web": "https://www.noburestaurants.com/sandiego/home/?utm_source=google&utm_medium=Yext",
                        "social": "https://facebook.com/noburestaurants | https://instagram.com/noburestaurants | https://twitter.com/noburestaurants",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Google Analytics; Google Tag; Stripe",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.2;"
                    },
                    {
                        "search_type": "web",
                        "title": "Saltwater Seafood & Steak",
                        "address": "565 Fifth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 525-9990",
                        "email": "events@saltwatersandiego.com\nevents@sandiegodininggroup.com\ntevents@sandiegodininggroup.com",
                        "web": "https://saltwatersandiego.com/",
                        "social": "https://facebook.com/swfinedining | https://instagram.com/saltwater_sd | https://twitter.com/saltwater_sd",
                        "likes": "1.2K /1.2K",
                        "service": "$$$/Dine-in · Outdoor seating · In-store pickup",
                        "logo": "https://scontent.fric1-1.fna.fbcdn.net/v/t39.30808-1/391680070_739930108131772_1757481544891976858_n.jpg?stp=c9.9.182.182a_dst-jpg_p200x200&_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_ohc=qzyH_r00MuoAX-lP-sw&_nc_ht=scontent.fric1-1.fna&oh=00_AfD31WsJ2FYD_UlGES0bQLxL78uhoHIs_6riICMWPuLKBg&oe=652D8C21",
                        "descr": "Saltwater was established on the foundation to deliver a one-of-a-kind fine dining experience to the",
                        "category": "Seafood restaurant",
                        "builtwith": "Google Tag; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.2;"
                    },
                    {
                        "search_type": "web",
                        "title": "Gaslamp Fish House",
                        "address": "411 Broadway, San Diego, CA 92101, USA",
                        "phone": "(619) 795-3800",
                        "email": "gaslampfishhouse@gmail.com",
                        "web": "http://gaslampfishhouse.com/",
                        "social": "https://facebook.com/gaslampfishhouse | https://instagram.com/gaslampfishhouse | https://twitter.com/gaslampfish",
                        "likes": "2K /2K",
                        "service": "$$$/Dine-in · Outdoor seating · Curbside pickup",
                        "logo": "https://scontent-lga3-2.xx.fbcdn.net/v/t39.30808-1/360169098_736595435141729_4464065776573626765_n.jpg?stp=dst-jpg_p200x200&_nc_cat=101&ccb=1-7&_nc_sid=5f2048&_nc_ohc=ku6niuAPg24AX_6OECT&_nc_ht=scontent-lga3-2.xx&oh=00_AfAMcuaSWSUdj_lyB1wYB77F7c6PchwwFPfrb-u4laVI2g&oe=652D44F1",
                        "descr": "Gaslamp Fish House has been serving the best fish, seafood & steaks in Gaslamp district. Craft cockta",
                        "category": "Seafood Restaurant",
                        "builtwith": "Google Tag; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.2;"
                    },
                    {
                        "search_type": "web",
                        "title": "Petrini's San Diego",
                        "address": "610 W Ash St #100, San Diego, CA 92101, USA",
                        "phone": "(619) 595-0322",
                        "email": "info@petrinis-sandiego.com",
                        "web": "https://petrinis-sandiego.com/",
                        "social": "https://facebook.com/petrinissandiego | https://yelp.com/biz/petrinis-san-diego-2",
                        "likes": "750/764",
                        "service": "/Dine in · Outdoor seating · Roadside collection",
                        "logo": "https://scontent-jnb1-1.xx.fbcdn.net/v/t39.30808-1/294978077_413072700844229_4563656004683046910_n.jpg?stp=dst-jpg_p200x200&_nc_cat=111&ccb=1-7&_nc_sid=5f2048&_nc_ohc=hEkxIeE4WM0AX-5XDEC&_nc_ht=scontent-jnb1-1.xx&oh=00_AfB5hSNC75Y2pwzfl4qfaRMwgIvRx8u93AurVATHwi4wow&oe=652D9C09",
                        "descr": "Family owned and operated, Petrini’s Restaurant serves Italian peasant cuisine and “Pizza to Die",
                        "category": "Italian Restaurant",
                        "builtwith": "Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.2;"
                    },
                    {
                        "search_type": "web",
                        "title": "Asti Ristorante",
                        "address": "728 Fifth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 232-8844",
                        "email": "info@astisandiego.com",
                        "web": "http://astisandiego.com/",
                        "social": "https://facebook.com/182044791809098 | https://instagram.com/astisandiego | https://twitter.com/astisandiego | https://yelp.com/biz/asti-ristorante-san-diego",
                        "likes": "4.4K /4.3K",
                        "service": "$$/Dine-in · Outdoor seating · In-store pickup",
                        "logo": "https://scontent-lga3-1.xx.fbcdn.net/v/t39.30808-1/302114074_440074454841823_4863640227283962023_n.png?stp=dst-png_p200x200&_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=iJLbfNNErycAX_u7Z7L&_nc_ht=scontent-lga3-1.xx&oh=00_AfDkFWe46M5G8RVogFvb4XjvPl4iXS_d8GJVkwxpw_pa7g&oe=652F0FB7",
                        "descr": "Over 20 years, Asti has been serving San Diego exquisite Italian, seafood and steaks. Located on 5th",
                        "category": "Italian Restaurant",
                        "builtwith": "Facebook Pixel; Google Analytics; Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.3;"
                    },
                    {
                        "search_type": "web",
                        "title": "Top of the Market - San Diego",
                        "address": "750 N Harbor Dr, San Diego, CA 92101, USA",
                        "phone": "(619) 234-4867",
                        "email": "",
                        "web": "http://www.topofthemarketsd.com/",
                        "social": "https://facebook.com/thefishmarkets | https://instagram.com/thefishmarkets | https://twitter.com/thefishmarkets",
                        "likes": "4.5K /4.6K",
                        "service": "",
                        "logo": "https://scontent.fmnl4-3.fna.fbcdn.net/v/t39.30808-1/348267369_2883877505081209_4304403015172097968_n.png?stp=dst-png_p200x200&_nc_cat=110&ccb=1-7&_nc_sid=5f2048&_nc_ohc=CJt5RvX4IMcAX8d4Eoj&_nc_ht=scontent.fmnl4-3.fna&oh=00_AfBbjv9SOjudXAdayQpmYURgD_vc-y0046huNpZOvtiDgA&oe=652D77A5",
                        "descr": "Welcome to the official Facebook page for The Fish Market Restaurants. We're glad you're here and inv",
                        "category": "Seafood Restaurant",
                        "builtwith": "Facebook Pixel; Google Analytics; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "web",
                        "title": "CUCINA urbana",
                        "address": "505 Laurel St, San Diego, CA 92101, USA",
                        "phone": "(619) 239-2222",
                        "email": "contact@urbankitchengroup.com",
                        "web": "http://www.cucinaurbana.com/",
                        "social": "https://facebook.com/cucina | https://instagram.com/cucinarestaurants",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Google Tag; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "web",
                        "title": "Baci Restaurant",
                        "address": "1955 Morena Blvd, San Diego, CA 92110, USA",
                        "phone": "(619) 275-2094",
                        "email": "info@sandiegobaci.com",
                        "web": "http://sandiegobaci.com/",
                        "social": "https://facebook.com/sandiegobaci | https://yelp.com/biz/baci-restaurant-san-diego",
                        "likes": "765/759",
                        "service": "$$$/Dine-in · Outdoor seating",
                        "logo": "https://scontent-iad3-2.xx.fbcdn.net/v/t39.30808-1/301971438_404073618531227_5950795770997176499_n.png?stp=dst-png_p200x200&_nc_cat=111&ccb=1-7&_nc_sid=5f2048&_nc_ohc=aovOuZnD1o8AX8h_kX9&_nc_ht=scontent-iad3-2.xx&oh=00_AfCZvEz5QiUi5lAVsQm9dKQlyAEQ2v5RLDrdzMpR2BjzdA&oe=652D87E2",
                        "descr": "Baci Ristorante is a family-owned, fine dining, Italian restaurant.",
                        "category": "Italian Restaurant",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.8;"
                    },
                    {
                        "search_type": "web",
                        "title": "Animae",
                        "address": "969 Pacific Hwy, San Diego, CA 92101, USA",
                        "phone": "(619) 432-1225",
                        "email": "animae@puffermalarkeycollective.com",
                        "web": "https://www.animaesd.com/",
                        "social": "https://facebook.com/animaesd | https://instagram.com/animae.sandiego",
                        "likes": "2.9K /3.1K",
                        "service": "/Dine in",
                        "logo": "https://scontent-jnb1-1.xx.fbcdn.net/v/t39.30808-1/358681572_665591722277705_5477927646676492791_n.png?stp=dst-png_p200x200&_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=zDrTxuWElSYAX-ousjO&_nc_ht=scontent-jnb1-1.xx&oh=00_AfBzdwqPECDw69zAuVqYWTPJ0ENFmA_mAOdexFM4hh0zSQ&oe=652EA091",
                        "descr": "ANIMAE’s eclectic West Coast cuisine, layered with Asian influences, comes to life under Executive Ch",
                        "category": "Asian fusion restaurant",
                        "builtwith": "Facebook Pixel; Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "web",
                        "title": "The Westgate Room Restaurant",
                        "address": "1055 2nd Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 238-1818",
                        "email": "grandamerica@wearebreadandbutter.com\ninfo@westgatehotel.com\nsales@westgatehotel.com",
                        "web": "https://www.westgatehotel.com/dining/the-westgate-room/",
                        "social": "https://facebook.com/westgatehotel | https://instagram.com/westgatehotel | https://linkedin.com/company/the-westgate-hotel",
                        "likes": "10K /10K",
                        "service": "$$$",
                        "logo": "https://scontent-lga3-1.xx.fbcdn.net/v/t39.30808-1/278336098_10160078477988210_895815551878769555_n.jpg?stp=dst-jpg_p200x200&_nc_cat=110&ccb=1-7&_nc_sid=5f2048&_nc_ohc=vERnbdXER_UAX8M0FMM&_nc_oc=AQk0GSDN1myzDBZbtjp0ZWuOGo2qhhiDEeKU8_xNcE9YYnaX1VXZcbP0iYlWTptriEY&_nc_ht=scontent-lga3-1.xx&oh=00_AfCptK6v0dvF5klXPz8XkAkTNDth2H0zrWC8iaOkStZJcg&oe=652E4CF3",
                        "descr": "The Westgate, San Diego’s premiere hotel continues to deliver on its original vision of classic luxur",
                        "category": "Hotel",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "business",
                        "title": "The Crack Shack - Little Italy",
                        "address": "2266 Kettner Blvd, San Diego, CA 92101, USA",
                        "phone": "(619) 795-3299",
                        "email": "",
                        "web": "https://locations.crackshack.com/ca/san-diego/san-diego/",
                        "social": "https://facebook.com/crackshacklittleitaly | https://facebook.com/thecrackshackrestaurant | https://instagram.com/getcrackshacked | https://twitter.com/getcrackshacked | https://yelp.com/biz/the-crack-shack-san-diego-2",
                        "likes": "4.5K /4.7K",
                        "service": "",
                        "logo": "https://scontent.fsan1-2.fna.fbcdn.net/v/t39.30808-1/307099903_3161644137467964_2350992954822118364_n.jpg?stp=dst-jpg_p200x200&_nc_cat=101&ccb=1-7&_nc_sid=5f2048&_nc_ohc=893J2q6aUYAAX9li97C&_nc_ht=scontent.fsan1-2.fna&oh=00_AfAiCJYNe-i2TD3wE6wXZ-5cmf_nD3Xl7EyVuwrc2xhu5g&oe=652DA2D3",
                        "descr": "",
                        "category": "",
                        "builtwith": "Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "business",
                        "title": "The Fish Market - San Diego",
                        "address": "750 N Harbor Dr, San Diego, CA 92101, USA",
                        "phone": "(619) 232-3474",
                        "email": "",
                        "web": "http://www.thefishmarket.com/",
                        "social": "https://facebook.com/thefishmarkets | https://instagram.com/thefishmarkets | https://twitter.com/thefishmarkets",
                        "likes": "4.5K /4.6K",
                        "service": "",
                        "logo": "https://scontent-mia3-2.xx.fbcdn.net/v/t39.30808-1/348267369_2883877505081209_4304403015172097968_n.png?stp=dst-png_p200x200&_nc_cat=110&ccb=1-7&_nc_sid=5f2048&_nc_ohc=CJt5RvX4IMcAX-MgljU&_nc_ht=scontent-mia3-2.xx&oh=00_AfBPaFZjffiLLFmDQMExW4Y_xI5ebFVUT3zap0y3A7_BnQ&oe=652D77A5",
                        "descr": "Welcome to the official Facebook page for The Fish Market Restaurants. We're glad you're here and inv",
                        "category": "Seafood Restaurant",
                        "builtwith": "Facebook Pixel; Google Analytics; Google Tag; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.4;"
                    },
                    {
                        "search_type": "business",
                        "title": "Bandar Restaurant",
                        "address": "845 Fourth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 238-0101",
                        "email": "info@bandarrestaurant.com",
                        "web": "http://www.bandarrestaurant.com/",
                        "social": "https://facebook.com/pages/bandar-restaurant-persian-steakhouse/117524838267123 | https://instagram.com/bandarrestaurant | https://twitter.com/bandarsandiego",
                        "likes": "/2K",
                        "service": "$$$/Dine-in · Outdoor seating · In-store pickup",
                        "logo": "https://scontent-ord5-2.xx.fbcdn.net/v/t39.30808-1/240113530_327403308835578_6343069388462066568_n.jpg?stp=dst-jpg_p200x200&_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_ohc=W_TJXO-Lg6IAX9vEIQX&_nc_ht=scontent-ord5-2.xx&oh=00_AfB2xeaodn5v4du4OfANWgv__5AI1JgBUN9Cdr4h8d_saA&oe=652D93CE",
                        "descr": "Our Award Wining Menu Features: Fresh Prime Steaks, World Famous Chicken, Seafood and Classic Persia",
                        "category": "Persian/Iranian Restaurant",
                        "builtwith": "Google Analytics; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "3.9;"
                    },
                    {
                        "search_type": "business",
                        "title": "ADDISON",
                        "address": "5200 Grand Del Mar Way, San Diego, CA 92130, USA",
                        "phone": "(858) 314-2000",
                        "email": "",
                        "web": "http://www.addisondelmar.com/?utm_source=google+Maps&utm_medium=seo+maps&utm_campaign=seo+maps&y_source=1_MTU2Mzc2NTEtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
                        "social": "https://instagram.com/addisonrestaurant",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "business",
                        "title": "The Lion's Share",
                        "address": "629 Kettner Blvd, San Diego, CA 92101, USA",
                        "phone": "(619) 564-6924",
                        "email": "info@lionssharesd.com",
                        "web": "http://www.lionssharesd.com/",
                        "social": "https://instagram.com/thelionssharesd",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.7;"
                    },
                    {
                        "search_type": "business",
                        "title": "Pushkin Hookah Lounge & Bar",
                        "address": "750 Sixth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 496-1908",
                        "email": "pushkinrestaurant@gmail.com",
                        "web": "http://pushkinrestaurantsd.com/",
                        "social": "https://facebook.com/pushkinsandiego",
                        "likes": "4.1K /4.2K",
                        "service": "$$/Dine-in · Outdoor seating · Curbside pickup…",
                        "logo": "https://scontent-hou1-1.xx.fbcdn.net/v/t39.30808-1/348221708_936793240977848_5636437861072572375_n.jpg?stp=dst-jpg_p200x200&_nc_cat=106&ccb=1-7&_nc_sid=5f2048&_nc_ohc=Y6FiGsd3Q9IAX_u1jkE&_nc_ht=scontent-hou1-1.xx&oh=00_AfC87ANXSr5VsRrUy2X0MYmXUYXGRdQVSDkl8ptalZ0QYA&oe=652D4E14",
                        "descr": "At Pushkin you’ll find European cuisine elegantly crafted with modern flavor and best hookah in SD.",
                        "category": "Hookah Lounge",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "business",
                        "title": "Callie",
                        "address": "1195 Island Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 255-9696",
                        "email": "info@calliesd.com",
                        "web": "https://calliesd.com/",
                        "social": "https://facebook.com/callierestaurant | https://instagram.com/callierestaurant | https://instagram.com/folklor.la",
                        "likes": "1.1K /1.3K",
                        "service": "$$$/Dine-in",
                        "logo": "https://scontent-lga3-2.xx.fbcdn.net/v/t39.30808-1/291634428_366815268892464_6433015383704039168_n.jpg?stp=dst-jpg_p200x200&_nc_cat=105&ccb=1-7&_nc_sid=5f2048&_nc_ohc=7CsbGZRiQA4AX9TZBT-&_nc_ht=scontent-lga3-2.xx&oh=00_AfDlh2ivBKVDmB8xGS7aMQzZJgX0Qc8U6IE6CEQSUvsgYw&oe=652E8625",
                        "descr": "Callie is the first solo project by Chef Travis Swikard. Derived from the Greek \"kallos,\" meaning th",
                        "category": "Restaurant",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.7;"
                    },
                    {
                        "search_type": "business",
                        "title": "Civico 1845",
                        "address": "1845 India St, San Diego, CA 92101, USA",
                        "phone": "(619) 431-5990",
                        "email": "info@civico1845.com",
                        "web": "https://www.civico1845.com/?y_source=1_OTIzMTMwNDItNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
                        "social": "https://facebook.com/civico1845 | https://instagram.com/civico1845",
                        "likes": "4.5K /4.5K",
                        "service": "$$/Outdoor seating · In-store pickup",
                        "logo": "https://scontent-atl3-1.xx.fbcdn.net/v/t39.30808-1/305645903_757150465637528_6974819960015011535_n.jpg?stp=dst-jpg_p200x200&_nc_cat=111&ccb=1-7&_nc_sid=5f2048&_nc_ohc=cYfR1rRSp9oAX-PeAja&_nc_ht=scontent-atl3-1.xx&oh=00_AfC0FsKsccQ8gGiIbsvCsiMK0jKuBNzbaFTqeXgHeXGATQ&oe=652E7F73",
                        "descr": "Civico 1845 features a new generation of Italian cuisine - fresh, light, authentic, and vegan. Exper",
                        "category": "Italian Restaurant",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.4;"
                    },
                    {
                        "search_type": "business",
                        "title": "La Puerta",
                        "address": "560 Fourth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 696-3466",
                        "email": "",
                        "web": "https://lapuertasd.com/?utm_source=Business-Listings",
                        "social": "https://facebook.com/lapuertasd | https://instagram.com/lapuertasd | https://twitter.com/lapuertasd",
                        "likes": "13K /13K",
                        "service": "$$/Dine-in · Outdoor seating",
                        "logo": "https://scontent-bos5-1.xx.fbcdn.net/v/t39.30808-1/341096390_536554598642282_6559122814341441084_n.jpg?stp=dst-jpg_p200x200&_nc_cat=106&ccb=1-7&_nc_sid=5f2048&_nc_ohc=F3GaiB59nBoAX99nkuA&_nc_ht=scontent-bos5-1.xx&oh=00_AfDkLRysjFytyvEGUq4k5r3n4CgSRKjcuisFWN2LYIWpnQ&oe=652E4EA8",
                        "descr": "Improving people's lives one tequila shot at a time!",
                        "category": "Mexican Restaurant",
                        "builtwith": "Google Analytics; Google Tag; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "business",
                        "title": "Lou & Mickey's",
                        "address": "224 Fifth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 237-4900",
                        "email": "ajames@kingsseafood.com\ncontactus@louandmickeys.com\nkcarpenter@kingsseafood.com\nmmalicdem@louandmickeys.com",
                        "web": "https://www.louandmickeys.com/",
                        "social": "https://facebook.com/louandmickeys | https://instagram.com/louandmickeys | https://twitter.com/louandmickeys",
                        "likes": "3.5K /3.5K",
                        "service": "$$/Outdoor seating",
                        "logo": "https://scontent.fsac1-1.fna.fbcdn.net/v/t39.30808-1/361374022_763874489079629_4046450032993819922_n.jpg?stp=dst-jpg_p200x200&_nc_cat=106&ccb=1-7&_nc_sid=5f2048&_nc_ohc=HnccU2_vqp4AX_PHNGE&_nc_ht=scontent.fsac1-1.fna&oh=00_AfDhtT9WgFS7NF4LIHsmq-PRes_6BzLtREQryCREr_b27A&oe=652EE2A4",
                        "descr": "Famous Steaks, Choice Seafood & Exotic Drinks",
                        "category": "Steakhouse",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "business",
                        "title": "Born and Raised",
                        "address": "1909 India St, San Diego, CA 92101, USA",
                        "phone": "(619) 202-4577",
                        "email": "info@bornandraisedsteak.com",
                        "web": "http://bornandraisedsteak.com/",
                        "social": "",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "business",
                        "title": "Trust",
                        "address": "3752 Park Blvd, San Diego, CA 92103, USA",
                        "phone": "(619) 795-6901",
                        "email": "",
                        "web": "http://www.trustrestaurantsd.com/",
                        "social": "https://instagram.com/trustrestaurantsd",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "business",
                        "title": "Morning Glory",
                        "address": "550 W Date St Suite #C, San Diego, CA 92101, USA",
                        "phone": "(619) 629-0302",
                        "email": "",
                        "web": "https://morningglorybreakfast.com/",
                        "social": "",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 6,
                        "rel": 1.0,
                        "ratings": "4.3;"
                    },
                    {
                        "search_type": "business",
                        "title": "North Italia",
                        "address": "7055 Friars Rd, San Diego, CA 92108, USA",
                        "phone": "(619) 343-2301",
                        "email": "info@northitalia.com",
                        "web": "https://www.northitalia.com/locations/san-diego-ca-fashion-valley/",
                        "social": "https://facebook.com/northitalia | https://instagram.com/eatnorthitalia",
                        "likes": "71K /72K",
                        "service": "$$",
                        "logo": "https://scontent-lga3-1.xx.fbcdn.net/v/t39.30808-1/339926738_240439661735594_4662538898389715658_n.jpg?stp=dst-jpg_p200x200&_nc_cat=108&ccb=1-7&_nc_sid=5f2048&_nc_ohc=SnxSaf5srf8AX-seihj&_nc_ht=scontent-lga3-1.xx&oh=00_AfBko-thQrnnQgmrOuX33dvLehHfIwOgGUMI9Rz_d70kxw&oe=652E6412",
                        "descr": "Not all carbs are created equal.",
                        "category": "Italian Restaurant",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 6,
                        "rel": 1.0,
                        "ratings": "4.4;"
                    },
                    {
                        "search_type": "business",
                        "title": "Hob Nob Hill",
                        "address": "2271 First Ave., San Diego, CA 92101, USA",
                        "phone": "(619) 239-8176",
                        "email": "hobnobhill1944@gmail.com",
                        "web": "https://hobnobhill.com/?y_source=1_MTQ2MTA4ODEtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
                        "social": "",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 6,
                        "rel": 1.0,
                        "ratings": "4.4;"
                    },
                    {
                        "search_type": "business",
                        "title": "Hodad's Downtown",
                        "address": "945 Broadway, San Diego, CA 92101, USA",
                        "phone": "(619) 234-6323",
                        "email": "",
                        "web": "http://hodadies.com/",
                        "social": "",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Google Analytics; Stripe; Woocommerce",
                        "keyword": "San Diego restaurants",
                        "weight": 6,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "business",
                        "title": "Cannonball",
                        "address": "3105 Ocean Front Walk, San Diego, CA 92109, USA",
                        "phone": "(858) 228-9304",
                        "email": "cannonballsd@belmontpark.com",
                        "web": "http://www.cannonballsd.com/",
                        "social": "https://facebook.com/cannonballsd | https://instagram.com/cannonballsd | https://twitter.com/cannonballsd",
                        "likes": "7.5K /7.5K",
                        "service": "/Outdoor seating",
                        "logo": "https://scontent-cgk1-2.xx.fbcdn.net/v/t39.30808-1/313248816_512409964232618_2537696942828203172_n.jpg?stp=dst-jpg_p200x200&_nc_cat=106&ccb=1-7&_nc_sid=5f2048&_nc_ohc=a2yv1octDloAX9h6ccd&_nc_ht=scontent-cgk1-2.xx&oh=00_AfC5QwqW9JCchPM-jp4EcUkiJBNR1pO2s8ZIfhkZJGTXHQ&oe=652D3A4A",
                        "descr": "Sushi with a splash! Besides the delicious inventive sushi and California cuisine inspired by Pacifi",
                        "category": "Sushi Restaurant",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 6,
                        "rel": 1.0,
                        "ratings": "4.3;"
                    },
                    {
                        "search_type": "business",
                        "title": "Davanti Enoteca",
                        "address": "1655 India St, San Diego, CA 92101, USA",
                        "phone": "(619) 237-9606",
                        "email": "",
                        "web": "http://www.davantienoteca.com/sandiego",
                        "social": "https://facebook.com/davantienoteca | https://facebook.com/davantisandiego | https://instagram.com/davanti_enoteca | https://twitter.com/davanti_enoteca",
                        "likes": "5.4K /5.4K",
                        "service": "$$",
                        "logo": "https://scontent-dfw5-1.xx.fbcdn.net/v/t39.30808-1/294673837_1030915094354034_4429320768649353835_n.png?stp=dst-png_p200x200&_nc_cat=105&ccb=1-7&_nc_sid=5f2048&_nc_ohc=03WxtN8ArAMAX_Nlmx3&_nc_ht=scontent-dfw5-1.xx&oh=00_AfDibqiqryBXGwLUbH0KILI1VRskhH1L3M1KzMhpmgLoQQ&oe=652DC6BA",
                        "descr": "A member of the Francesca's Restaurant Group; Davanti Enoteca is a wine bar that offers shareable pl",
                        "category": "Italian Restaurant",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 6,
                        "rel": 1.0,
                        "ratings": "4.4;"
                    },
                    {
                        "search_type": "business",
                        "title": "Fort Oak",
                        "address": "1011 Fort Stockton Dr, San Diego, CA 92103, USA",
                        "phone": "(619) 722-3398",
                        "email": "",
                        "web": "https://fortoaksd.com/",
                        "social": "https://instagram.com/fortoaksd",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 6,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "business",
                        "title": "Cafe Sevilla of San Diego",
                        "address": "353 Fifth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 233-5979",
                        "email": "",
                        "web": "https://www.cafesevilla.com/",
                        "social": "",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Facebook Pixel; Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 6,
                        "rel": 1.0,
                        "ratings": "4.3;"
                    },
                    {
                        "search_type": "business",
                        "title": "Ironside Fish & Oyster",
                        "address": "1654 India St, San Diego, CA 92101, USA",
                        "phone": "(619) 269-3033",
                        "email": "",
                        "web": "https://ironsidefishandoyster.com/",
                        "social": "",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 6,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    }
                ]
            }
        },
        "POST /api/records/ch_3OGT9NGefNSPz2RI0N9D3CdG/": () => {
            return {
                "status": 200,
                "message": "",
                "dv": 1,
                "size": 52,
                "records": [
                     {
                         "search_type": "web",
                         "title": "monakoland.com.ua",
                         "descr": "monako restaurant",
                         "address": "",
                         "phone": "+380631086911 | +380980898107 | +380682726929 | +380961872131 | +380665274190 | +380976754215 | +380678044434 | +380322987742 | +380983635502 | +380686335581",
                         "email": "info@monakoland.com.ua",
                         "web": "https://monakoland.com.ua/",
                         "likes": "",
                         "social": "https://www.facebook.com/monako | https://www.instagram.com/monako_ua",
                         "builtwith": "Facebook Pixel",
                         "relevance": 0.16,
                         "weight": 6,
                         "review": "",
                         "id": 6,
                         "category": "",
                         "service": "",
                         "logo": "/static/images/monako.png",
                         "keyword": "fgh monako",
                         "ratings": "",
                         "sqr": 5.82
                     },
                    {
                        "search_type": "people",
                        "title": "Carnitas' Snack Shack - Embarcadero",
                        "address": "1004 N Harbor Dr, San Diego, CA 92101, USA",
                        "phone": "(619) 696-7675",
                        "email": "embarcadero@carnitassnackshack.com",
                        "web": "http://carnitassnackshack.com/",
                        "social": "https://facebook.com/carnitassnacks | https://instagram.com/carnitassnacks | https://twitter.com/carnitassnacks",
                        "likes": "17K /17K",
                        "service": "$/Delivery · Contactless delivery · Takeout…",
                        "logo": "/static/images/cooper.jpg",
                        "descr": "Carnitas’ Snack Shack is San Diego’s original pork house offering a wide variety of indulgent food, l",
                        "category": "Comfort Food Restaurant",
                        "builtwith": "Facebook Pixel; Google Analytics; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.4;12,000"
                    },
                    {
                        "search_type": "business",
                        "title": "Fogo de Chão Brazilian Steakhouse",
                        "address": "668 Sixth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 338-0500",
                        "email": "info@fogodechao.com",
                        "web": "https://fogodechao.com/location/san-diego/",
                        "social": "https://facebook.com/fogo | https://facebook.com/fogorestaurants | https://instagram.com/fogo | https://tiktok.com/@fogodechao | https://twitter.com/fogorestaurants",
                        "likes": "515K /522K",
                        "service": "$$$",
                        "logo": "https://scontent-mia3-2.xx.fbcdn.net/v/t39.30808-1/241662257_4085718231555198_1222945952331458793_n.png?stp=dst-png_p200x200&_nc_cat=1&ccb=1-7&_nc_sid=5f2048&_nc_ohc=24OaX5nU2gIAX_TX3ux&_nc_ht=scontent-mia3-2.xx&oh=00_AfCtmvuEV_UmbwbcIs8t690yJmb-JBnvUFJemniiHCycaA&oe=652EC91B",
                        "descr": "Every bite is a new discovery.",
                        "category": "Brazilian Restaurant",
                        "builtwith": "Google Analytics; Google Tag; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.3;1,000"
                    },
                    {
                        "search_type": "business",
                        "title": "The Melting Pot",
                        "address": "901 Fifth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 234-5554",
                        "email": "info@gaslampfondue.com",
                        "web": "https://www.meltingpot.com/san-diego-gaslamp-ca/",
                        "social": "https://facebook.com/meltingpotsandiegogaslamp | https://tiktok.com/@themeltingpotrestaurants | https://twitter.com/meltingpotgslmp | https://yelp.com/biz/the-melting-pot-san-diego-2",
                        "likes": "11K /11K",
                        "service": "$$",
                        "logo": "https://scontent-atl3-2.xx.fbcdn.net/v/t39.30808-1/348229150_244001978281163_3786753955532269897_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=6mgMpcA7C4EAX_iUHf7&_nc_ht=scontent-atl3-2.xx&oh=00_AfCzszfHxFKaGLH_X65SdxAVUpSp83NlzblHgEmoNXyczA&oe=652EF414",
                        "descr": "The San Diego/Gaslamp Melting Pot is a fondue lovers paradise where patrons can \"dip into something d",
                        "category": "Fondue Restaurant",
                        "builtwith": "Google Analytics",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.5;100"
                    },
                    {
                        "search_type": "business",
                        "title": "The Grant Grill",
                        "address": "326 Broadway, San Diego, CA 92101, USA",
                        "phone": "(619) 744-2077",
                        "email": "pablo.leonardo@luxurycollection.com\ntheusgrant@theusgranthotel.com",
                        "web": "https://www.grantgrill.com/",
                        "social": "https://facebook.com/grantgrill | https://instagram.com/grantgrill | https://twitter.com/usgranthotel | https://yelp.com/biz/grant-grill-san-diego",
                        "likes": "1K /1K",
                        "service": "$$$/Outdoor seating",
                        "logo": "https://scontent-bos5-1.xx.fbcdn.net/v/t39.30808-1/348438188_781966413652005_4113643020024806385_n.jpg?stp=dst-jpg_p200x200&_nc_cat=106&ccb=1-7&_nc_sid=5f2048&_nc_ohc=Lt0Q1mmXSsYAX-5MNd0&_nc_ht=scontent-bos5-1.xx&oh=00_AfCPQkhVvo4afHsVfrv5UnzL4c_IWs7aZeGOKDOgkFSCKw&oe=652DDECA",
                        "descr": "California Cuisine. Craft Cocktails. Fine Wines. Iconic Setting.",
                        "category": "Local business",
                        "builtwith": "Google Analytics",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "business",
                        "title": "Dobson's Bar & Restaurant",
                        "address": "956 Broadway Cir, San Diego, CA 92101, USA",
                        "phone": "(619) 231-6771",
                        "email": "marcos@dobsonsrestaurant.com",
                        "web": "http://dobsonsrestaurant.com/",
                        "social": "https://facebook.com/401712866596529 | https://instagram.com/dobsonssandiego | https://twitter.com/dobsonssandiego",
                        "likes": "4.7K /4.7K",
                        "service": "/Dine in · Outdoor seating · In-store collection",
                        "logo": "https://scontent-cpt1-1.xx.fbcdn.net/v/t39.30808-1/346053402_201967359309673_8129260492166892803_n.jpg?stp=cp6_dst-jpg_p200x200&_nc_cat=110&ccb=1-7&_nc_sid=5f2048&_nc_ohc=cv9CXb43zoMAX-kfUT0&_nc_ht=scontent-cpt1-1.xx&oh=00_AfAbwwgMI1fuOqnMbJk5nly9rfK0TnfbU0LPJV0zLlSe7A&oe=652E3DEA",
                        "descr": "Dobson's Bar and Restaurant is a hidden treasure in the heart of Downtown San Diego/Gaslamp Quarter.",
                        "category": "American Restaurant",
                        "builtwith": "Google Analytics; Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "business",
                        "title": "World Famous",
                        "address": "711 Pacific Beach Dr, San Diego, CA 92109, USA",
                        "phone": "(858) 272-3100",
                        "email": "manager@worldfamouspb.com",
                        "web": "http://worldfamouspb.com/",
                        "social": "https://facebook.com/154979564574517 | https://instagram.com/worldfamouspb | https://twitter.com/worldfamouspb | https://yelp.com/biz/world-famous-san-diego",
                        "likes": "8.6K /8.5K",
                        "service": "$$/Dine-in · Outdoor seating · Curbside pickup",
                        "logo": "https://scontent-hou1-1.xx.fbcdn.net/v/t39.30808-1/291331876_405772691575808_3339006906707915263_n.jpg?stp=c12.0.200.200a_dst-jpg_p200x200&_nc_cat=101&ccb=1-7&_nc_sid=5f2048&_nc_ohc=8pj9U7UWAn4AX_SwCP7&_nc_ht=scontent-hou1-1.xx&oh=00_AfCkcCATcRieUH-XFUfqbTYGor4F7mOlO_Uuicvlq239Og&oe=652EA2BB",
                        "descr": "California Coastal Cuisine",
                        "category": "Seafood Restaurant",
                        "builtwith": "Google Analytics; Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.3;"
                    },
                    {
                        "search_type": "business",
                        "title": "Parma Cucina Italiana",
                        "address": "3850 Fifth Ave, San Diego, CA 92103, USA",
                        "phone": "(619) 543-0049",
                        "email": "info@parmaitaliankitchen.com",
                        "web": "https://www.parmaitaliankitchen.com/",
                        "social": "https://facebook.com/314812141967389 | https://yelp.com/biz/parma-cucina-italiana-san-diego",
                        "likes": "2.7K /2.7K",
                        "service": "$$/Outdoor seating · Curbside pickup",
                        "logo": "https://scontent.fdcf1-1.fna.fbcdn.net/v/t39.30808-1/279296157_405205308277819_1543719269828462233_n.jpg?stp=c47.0.200.200a_dst-jpg_p200x200&_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_ohc=fAOBgM_UMDAAX_HOlxg&_nc_ht=scontent.fdcf1-1.fna&oh=00_AfDY1H-d_BuOvWk5XQSy5AiBnhoqp4SLqPg8ijYHTzqftw&oe=652EAB06",
                        "descr": "Located in the heart of Hillcrest in San Diego CA, Parma offers authentic Italian food, a great vari",
                        "category": "Italian Restaurant",
                        "builtwith": "Google Analytics; Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.7;"
                    },
                    {
                        "search_type": "web",
                        "title": "Vessel Restaurant",
                        "address": "1551 Shelter Island Dr, San Diego, CA 92106, USA",
                        "phone": "(619) 819-8195",
                        "email": "aveves@konakaimarina.com\njhornick@sdkonakai.com\nkonakai@20twostudio.com\nreservations@sdkonakai.com\nsales@sdkonakai.com\nweddings@sdkonakai.com",
                        "web": "https://www.resortkonakai.com/shelter-island-restaurant/?utm_source=gmb-restaurant&utm_medium=organic&utm_campaign=gmb",
                        "social": "https://facebook.com/resortkonakai | https://facebook.com/vesselrestaurant | https://instagram.com/konakairesortsd | https://twitter.com/konakaisd",
                        "likes": "7.1K /7.4K",
                        "service": "$$$/Outdoor seating",
                        "logo": "https://scontent-atl3-1.xx.fbcdn.net/v/t39.30808-1/340471042_565780218696070_1757121604426728949_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_ohc=T2eaLoDu3hwAX9twvuS&_nc_ht=scontent-atl3-1.xx&oh=00_AfDbp-vDQ_wbC79QzWafJH8qGHlT4O1asLgkB4GYUwIT8g&oe=652E8B26",
                        "descr": "An idyllic island-style San Diego resort with astounding bay and marina views.",
                        "category": "Hotel resort",
                        "builtwith": "Stripe; Woocommerce",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.2;"
                    },
                    {
                        "search_type": "web",
                        "title": "STK Steakhouse",
                        "address": "600 F St, San Diego, CA 92101, USA",
                        "phone": "(619) 354-5988",
                        "email": "marketing@togrp.com",
                        "web": "https://stksteakhouse.com/venues/san-diego/",
                        "social": "https://facebook.com/eatstk | https://facebook.com/stkhouse | https://instagram.com/eatstk | https://tiktok.com/@eatstk | https://twitter.com/eatstk",
                        "likes": "121K /123K",
                        "service": "",
                        "logo": "https://scontent.fman2-2.fna.fbcdn.net/v/t39.30808-1/369214987_699280635567098_6878000983017625695_n.jpg?stp=dst-jpg_p200x200&_nc_cat=110&ccb=1-7&_nc_sid=5f2048&_nc_ohc=ubKu3rgMEeEAX8-wHQV&_nc_ht=scontent.fman2-2.fna&oh=00_AfAh194l92sqKYxfLkMr-LLPtqFq5YYtgw4aodPWwJkcqw&oe=652E3071",
                        "descr": "STK Steakhouse combines the modern steakhouse and chic lounge into one VIBE DINING experience.",
                        "category": "Steakhouse",
                        "builtwith": "Google Tag; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.3;"
                    },
                    {
                        "search_type": "web",
                        "title": "Prado",
                        "address": "1549 El Prado, San Diego, CA 92101, USA",
                        "phone": "(619) 557-9441",
                        "email": "social@dinecrg.com",
                        "web": "https://www.pradobalboa.com/",
                        "social": "https://facebook.com/pradobalboapark",
                        "likes": "9.9K /10K",
                        "service": "$$$/Outdoor seating",
                        "logo": "https://scontent-hou1-1.xx.fbcdn.net/v/t39.30808-1/308012631_541442107984182_8656254196429818391_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=5f2048&_nc_ohc=VwIRqeCtW_UAX_EVmlf&_nc_ht=scontent-hou1-1.xx&oh=00_AfCHjU42cOHegPbFUlUilkGQRuukIOU_5V7LlseVWxsjXQ&oe=652F1C5A",
                        "descr": "The Prado offers California cuisine with Latin flair located in the heart of San Diego's Balboa Park",
                        "category": "Restaurant",
                        "builtwith": "Google Tag; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.4;"
                    },
                    {
                        "search_type": "web",
                        "title": "Juniper & Ivy",
                        "address": "2228 Kettner Blvd, San Diego, CA 92101, USA",
                        "phone": "(619) 269-9036",
                        "email": "peyton@baybirdinc.com",
                        "web": "http://juniperandivy.com/",
                        "social": "https://facebook.com/pages/juniper-ivy-restaurant/543482805704452 | https://instagram.com/juniperandivy",
                        "likes": "11K /11K",
                        "service": "/Dine-in · Outdoor seating · In-store pickup",
                        "logo": "https://scontent-hou1-1.xx.fbcdn.net/v/t39.30808-1/359758062_284074247623867_5668692159333947679_n.png?stp=dst-png_p200x200&_nc_cat=101&ccb=1-7&_nc_sid=5f2048&_nc_ohc=Na37KiVlTH0AX9w7fZh&_nc_ht=scontent-hou1-1.xx&oh=00_AfD636Qw9mKO3ZJCPPosxX1G47S1BSvAY_QHwtegjsJCog&oe=652E51FC",
                        "descr": "From the entirely-locally-sourced and inventive menu, to the unique ambiance, and the impeccable ser",
                        "category": "American Restaurant",
                        "builtwith": "Facebook Pixel; Google Analytics; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "web",
                        "title": "Kettner Exchange",
                        "address": "2001 Kettner Blvd, San Diego, CA 92101, USA",
                        "phone": "(619) 255-2001",
                        "email": "info@kettnerexchange.com",
                        "web": "http://www.kettnerexchange.com/",
                        "social": "https://facebook.com/kettnerexchange | https://instagram.com/kettnerexchange",
                        "likes": "11K /11K",
                        "service": "/Dine in · Outdoor seating · Roadside collection…",
                        "logo": "https://scontent-cpt1-1.xx.fbcdn.net/v/t39.30808-1/301810278_454173656741128_5851578499807169833_n.jpg?stp=dst-jpg_p200x200&_nc_cat=101&ccb=1-7&_nc_sid=5f2048&_nc_ohc=UD3iPn1EFZ0AX-FjPL9&_nc_ht=scontent-cpt1-1.xx&oh=00_AfDpM6_jo3HdeY1MIdd5b5HhWfFWj_6-ZHRKNstxl5B0nw&oe=652EB7A7",
                        "descr": "",
                        "category": "Bar",
                        "builtwith": "Facebook Pixel; Google Tag; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "web",
                        "title": "Water Grill San Diego",
                        "address": "615 J St, San Diego, CA 92101, USA",
                        "phone": "(619) 717-6992",
                        "email": "ajames@kingsseafood.com",
                        "web": "https://www.watergrill.com/sandiego",
                        "social": "https://facebook.com/watergrillsandiego | https://instagram.com/watergrill | https://linkedin.com/company/kings-seafood-company",
                        "likes": "2.7K /2.8K",
                        "service": "$$$/Outdoor seating",
                        "logo": "https://scontent-dfw5-1.xx.fbcdn.net/v/t39.30808-1/348303036_940756237161483_2336536779679372324_n.jpg?stp=dst-jpg_p200x200&_nc_cat=105&ccb=1-7&_nc_sid=5f2048&_nc_ohc=gUVFG9lR7WoAX-81OmI&_nc_ht=scontent-dfw5-1.xx&oh=00_AfAQ0A4ZT7bWplCZvb9MkJHWHhJWtnbckh-zFFOx9U9SVg&oe=652DE45C",
                        "descr": "San Diego's seafood destination",
                        "category": "Seafood Restaurant",
                        "builtwith": "Google Tag; Shopify",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.4;"
                    },
                    {
                        "search_type": "web",
                        "title": "Mister A's",
                        "address": "2550 Fifth Ave 12th floor, San Diego, CA 92103, USA",
                        "phone": "(619) 239-1377",
                        "email": "info@asrestaurant.com",
                        "web": "https://www.asrestaurant.com/",
                        "social": "https://facebook.com/bertrandatmisteras | https://instagram.com/misterasrestaurant",
                        "likes": "10K /10K",
                        "service": "$$$$/Outdoor seating",
                        "logo": "https://scontent.forf1-4.fna.fbcdn.net/v/t39.30808-1/292076724_450564703741473_1201421822187855334_n.jpg?stp=dst-jpg_p200x200&_nc_cat=104&ccb=1-7&_nc_sid=5f2048&_nc_ohc=0bziImlHtqcAX9wrE8M&_nc_ht=scontent.forf1-4.fna&oh=00_AfAdOh9wpZWVRB_LlnwsuwyZC7bddHJYPtqvIagmROLM1A&oe=652DA6F5",
                        "descr": "Bertrand at Mister A's",
                        "category": "American Restaurant",
                        "builtwith": "Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "web",
                        "title": "Lionfish Modern Coastal Cuisine – San Diego",
                        "address": "435 Fifth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 738-7200",
                        "email": "hello@lionfishsd.com",
                        "web": "https://lionfishsd.com/",
                        "social": "https://facebook.com/lionfishsd | https://instagram.com/lionfishsd | https://twitter.com/lionfishsd",
                        "likes": "6.3K /6.5K",
                        "service": "/Outdoor seating · In-store collection",
                        "logo": "https://scontent.flhe7-2.fna.fbcdn.net/v/t39.30808-1/291692629_718802456066791_6656024244312436463_n.png?stp=dst-png_p200x200&_nc_cat=109&ccb=1-7&_nc_sid=5f2048&_nc_ohc=GOk5_tvMGagAX89pJEZ&_nc_ht=scontent.flhe7-2.fna&oh=00_AfCTQ0hRERDArURQQVRTRIELsB7uBU9pewgkkzZ-Up37og&oe=652EB641",
                        "descr": "The taste of fresh sustainable seafood has come to the Gaslamp Quarter.",
                        "category": "Seafood Restaurant",
                        "builtwith": "Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.4;"
                    },
                    {
                        "search_type": "web",
                        "title": "Herb & Wood",
                        "address": "2210 Kettner Blvd, San Diego, CA 92101, USA",
                        "phone": "(619) 955-8495",
                        "email": "events@herbandwood.com\ninfo@herbandwood.com",
                        "web": "https://www.herbandwood.com/",
                        "social": "https://facebook.com/herbandwood | https://facebook.com/squarespace | https://instagram.com/herbandwood | https://instagram.com/squarespace | https://twitter.com/squarespace | https://yelp.com/biz/herb-and-wood-san-diego",
                        "likes": "5.5K /5.9K",
                        "service": "$$$/Dine-in · Outdoor seating",
                        "logo": "https://scontent-dfw5-1.xx.fbcdn.net/v/t39.30808-1/274706830_4625366830924714_2782819584614438236_n.png?_nc_cat=101&ccb=1-7&_nc_sid=5f2048&_nc_ohc=kmTORKG74RMAX9TCDgM&_nc_ht=scontent-dfw5-1.xx&oh=00_AfCMN8QqxWPKx2oJ-BDiitIn-rGctIvEcn8xbpt5-iA4wg&oe=652EC02E",
                        "descr": "Herb & Wood is a fresh and exciting new concept from Malarkey and Puffer. Capturing flavors that rel",
                        "category": "Local business",
                        "builtwith": "Facebook Pixel; Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 9,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "web",
                        "title": "Greystone Prime Steakhouse & Seafood",
                        "address": "658 Fifth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 232-0225",
                        "email": "events@sandiegodininggroup.com",
                        "web": "http://www.greystonesteakhouse.com/",
                        "social": "https://facebook.com/greystonesteakhouse | https://instagram.com/greystonesteak | https://twitter.com/greystone_steak | https://yelp.com/biz/greystone-the-steakhouse-san-diego | https://youtube.com/channel/uc-cu83gr88axnpbipv3vmrq",
                        "likes": "26K /26K",
                        "service": "$$$/Dine-in · Outdoor seating · Curbside pickup",
                        "logo": "https://scontent-atl3-1.xx.fbcdn.net/v/t39.30808-1/326344366_2280480072119418_2137512960424903417_n.jpg?stp=dst-jpg_p200x200&_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_ohc=7okRVsnuyxEAX_XfaU_&_nc_ht=scontent-atl3-1.xx&oh=00_AfAxtRWx-hy4K_l4uBXeRuNhgdxKfmpp6RTRK1EBcdgHSw&oe=652EA9D5",
                        "descr": "Voted one of the Top 5 Steakhouses in California and San Diego. Home to the $100 Steak. Indulge in ou",
                        "category": "Steakhouse",
                        "builtwith": "Facebook Pixel; Google Analytics; Google Tag; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.3;"
                    },
                    {
                        "search_type": "web",
                        "title": "Starlite",
                        "address": "3175 India St, San Diego, CA 92103, USA",
                        "phone": "(619) 618-2830",
                        "email": "info@ch-projects.com\nmanager@starlitesandiego.com\nmanger@starlitesandiego.com\nstarlite@ch-projects.com",
                        "web": "http://www.starlitesandiego.com/",
                        "social": "https://facebook.com/starlitesandiego | https://instagram.com/starlitesd",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Facebook Pixel",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "web",
                        "title": "Farmers Table Little Italy",
                        "address": "550 W Date St Ste A, San Diego, CA 92101, USA",
                        "phone": "(619) 255-0958",
                        "email": "acfarmerstable@gmail.com\nalbertovfarmerscv@gmail.com\neventsfarmerstable@gmail.com\ntacfarmerstable@gmail.com\ntalbertovfarmerscv@gmail.com\nteventsfarmerstable@gmail.com",
                        "web": "https://myfarmerstable.com/",
                        "social": "https://facebook.com/farmerstablesd | https://instagram.com/farmerstablesd | https://twitter.com/farmerstablesd",
                        "likes": "9.6K /10K",
                        "service": "$$",
                        "logo": "https://scontent-iad3-1.xx.fbcdn.net/v/t39.30808-1/348271414_769063418030803_3779015333091998180_n.jpg?stp=dst-jpg_p200x200&_nc_cat=108&ccb=1-7&_nc_sid=5f2048&_nc_ohc=OSkcyBYaznsAX_MEodu&_nc_ht=scontent-iad3-1.xx&oh=00_AfBRGbZWX1G9jLHaEucs8dWAGjFeYH_pJOraIpMHHLOcvQ&oe=652F0B49",
                        "descr": "Fresh farm-to-table fare in a rustic setting. Dine at any of our four Locations in San Diego: La Mes",
                        "category": "American Restaurant",
                        "builtwith": "Google Tag; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "3.7;"
                    },
                    {
                        "search_type": "web",
                        "title": "Monello",
                        "address": "750 W Fir St, San Diego, CA 92101, USA",
                        "phone": "(619) 501-0030",
                        "email": "events@lovebencotto.com\ninfo@lovemonello.com",
                        "web": "http://www.lovemonello.com/",
                        "social": "https://facebook.com/lovemonello | https://instagram.com/lovemonello | https://twitter.com/lovemonello",
                        "likes": "6.7K /6.8K",
                        "service": "$$/Dine-in · Outdoor seating · Curbside pickup",
                        "logo": "https://scontent-lax3-2.xx.fbcdn.net/v/t39.30808-1/305325392_489446853191366_5862255355339907378_n.jpg?stp=dst-jpg_p200x200&_nc_cat=111&ccb=1-7&_nc_sid=5f2048&_nc_ohc=6BoRCqktfdwAX9jLCTg&_nc_ht=scontent-lax3-2.xx&oh=00_AfCvRF8YB-40jWTx9MzEKGPQcXfPfizE0wchH8mnfieIWQ&oe=652E9EBE",
                        "descr": "Luigi Mineo from Sicily: “Now I have Monello, I do not miss Italy that much.\" Serving: Lunch Ape",
                        "category": "Italian Restaurant",
                        "builtwith": "Google Analytics",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.4;"
                    },
                    {
                        "search_type": "web",
                        "title": "The Butcher's Cut",
                        "address": "644 Fifth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 235-8144",
                        "email": "events@sandiegodininggroup.com\ntevents@sandiegodininggroup.com",
                        "web": "http://www.butcherscutsteakhouse.com/",
                        "social": "https://facebook.com/the-butchers-cut-347734962517878 | https://instagram.com/butcherscutsd | https://twitter.com/butcherscutsd | https://youtube.com/channel/uckxlzzuvcgougitzsujo9eg",
                        "likes": "954/983",
                        "service": "/Dine in · Outdoor seating · In-store collection",
                        "logo": "https://scontent-jnb1-1.xx.fbcdn.net/v/t39.30808-1/294932063_566648378328124_8581294383317499225_n.jpg?stp=dst-jpg_p200x200&_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_ohc=hRCFLfj_ZdkAX8L2Sns&_nc_ht=scontent-jnb1-1.xx&oh=00_AfAG0PbN3tUQ427G0CC1usDvQBbetxgNCDJkfMb1eADwhQ&oe=652ECA42",
                        "descr": "A contemporary grill and premiere steakhouse that offers an unmatched selection of beef, fish, and p",
                        "category": "Steakhouse",
                        "builtwith": "Google Tag; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4;"
                    },
                    {
                        "search_type": "web",
                        "title": "Nobu San Diego",
                        "address": "207 Fifth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 814-4124",
                        "email": "globalpress@noburestaurants.com\nsandiego@noburestaurants.com",
                        "web": "https://www.noburestaurants.com/sandiego/home/?utm_source=google&utm_medium=Yext",
                        "social": "https://facebook.com/noburestaurants | https://instagram.com/noburestaurants | https://twitter.com/noburestaurants",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Google Analytics; Google Tag; Stripe",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.2;"
                    },
                    {
                        "search_type": "web",
                        "title": "Saltwater Seafood & Steak",
                        "address": "565 Fifth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 525-9990",
                        "email": "events@saltwatersandiego.com\nevents@sandiegodininggroup.com\ntevents@sandiegodininggroup.com",
                        "web": "https://saltwatersandiego.com/",
                        "social": "https://facebook.com/swfinedining | https://instagram.com/saltwater_sd | https://twitter.com/saltwater_sd",
                        "likes": "1.2K /1.2K",
                        "service": "$$$/Dine-in · Outdoor seating · In-store pickup",
                        "logo": "https://scontent.fric1-1.fna.fbcdn.net/v/t39.30808-1/391680070_739930108131772_1757481544891976858_n.jpg?stp=c9.9.182.182a_dst-jpg_p200x200&_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_ohc=qzyH_r00MuoAX-lP-sw&_nc_ht=scontent.fric1-1.fna&oh=00_AfD31WsJ2FYD_UlGES0bQLxL78uhoHIs_6riICMWPuLKBg&oe=652D8C21",
                        "descr": "Saltwater was established on the foundation to deliver a one-of-a-kind fine dining experience to the",
                        "category": "Seafood restaurant",
                        "builtwith": "Google Tag; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.2;"
                    },
                    {
                        "search_type": "web",
                        "title": "Gaslamp Fish House",
                        "address": "411 Broadway, San Diego, CA 92101, USA",
                        "phone": "(619) 795-3800",
                        "email": "gaslampfishhouse@gmail.com",
                        "web": "http://gaslampfishhouse.com/",
                        "social": "https://facebook.com/gaslampfishhouse | https://instagram.com/gaslampfishhouse | https://twitter.com/gaslampfish",
                        "likes": "2K /2K",
                        "service": "$$$/Dine-in · Outdoor seating · Curbside pickup",
                        "logo": "https://scontent-lga3-2.xx.fbcdn.net/v/t39.30808-1/360169098_736595435141729_4464065776573626765_n.jpg?stp=dst-jpg_p200x200&_nc_cat=101&ccb=1-7&_nc_sid=5f2048&_nc_ohc=ku6niuAPg24AX_6OECT&_nc_ht=scontent-lga3-2.xx&oh=00_AfAMcuaSWSUdj_lyB1wYB77F7c6PchwwFPfrb-u4laVI2g&oe=652D44F1",
                        "descr": "Gaslamp Fish House has been serving the best fish, seafood & steaks in Gaslamp district. Craft cockta",
                        "category": "Seafood Restaurant",
                        "builtwith": "Google Tag; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.2;"
                    },
                    {
                        "search_type": "web",
                        "title": "Petrini's San Diego",
                        "address": "610 W Ash St #100, San Diego, CA 92101, USA",
                        "phone": "(619) 595-0322",
                        "email": "info@petrinis-sandiego.com",
                        "web": "https://petrinis-sandiego.com/",
                        "social": "https://facebook.com/petrinissandiego | https://yelp.com/biz/petrinis-san-diego-2",
                        "likes": "750/764",
                        "service": "/Dine in · Outdoor seating · Roadside collection",
                        "logo": "https://scontent-jnb1-1.xx.fbcdn.net/v/t39.30808-1/294978077_413072700844229_4563656004683046910_n.jpg?stp=dst-jpg_p200x200&_nc_cat=111&ccb=1-7&_nc_sid=5f2048&_nc_ohc=hEkxIeE4WM0AX-5XDEC&_nc_ht=scontent-jnb1-1.xx&oh=00_AfB5hSNC75Y2pwzfl4qfaRMwgIvRx8u93AurVATHwi4wow&oe=652D9C09",
                        "descr": "Family owned and operated, Petrini’s Restaurant serves Italian peasant cuisine and “Pizza to Die",
                        "category": "Italian Restaurant",
                        "builtwith": "Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.2;"
                    },
                    {
                        "search_type": "web",
                        "title": "Asti Ristorante",
                        "address": "728 Fifth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 232-8844",
                        "email": "info@astisandiego.com",
                        "web": "http://astisandiego.com/",
                        "social": "https://facebook.com/182044791809098 | https://instagram.com/astisandiego | https://twitter.com/astisandiego | https://yelp.com/biz/asti-ristorante-san-diego",
                        "likes": "4.4K /4.3K",
                        "service": "$$/Dine-in · Outdoor seating · In-store pickup",
                        "logo": "https://scontent-lga3-1.xx.fbcdn.net/v/t39.30808-1/302114074_440074454841823_4863640227283962023_n.png?stp=dst-png_p200x200&_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=iJLbfNNErycAX_u7Z7L&_nc_ht=scontent-lga3-1.xx&oh=00_AfDkFWe46M5G8RVogFvb4XjvPl4iXS_d8GJVkwxpw_pa7g&oe=652F0FB7",
                        "descr": "Over 20 years, Asti has been serving San Diego exquisite Italian, seafood and steaks. Located on 5th",
                        "category": "Italian Restaurant",
                        "builtwith": "Facebook Pixel; Google Analytics; Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.3;"
                    },
                    {
                        "search_type": "web",
                        "title": "Top of the Market - San Diego",
                        "address": "750 N Harbor Dr, San Diego, CA 92101, USA",
                        "phone": "(619) 234-4867",
                        "email": "",
                        "web": "http://www.topofthemarketsd.com/",
                        "social": "https://facebook.com/thefishmarkets | https://instagram.com/thefishmarkets | https://twitter.com/thefishmarkets",
                        "likes": "4.5K /4.6K",
                        "service": "",
                        "logo": "https://scontent.fmnl4-3.fna.fbcdn.net/v/t39.30808-1/348267369_2883877505081209_4304403015172097968_n.png?stp=dst-png_p200x200&_nc_cat=110&ccb=1-7&_nc_sid=5f2048&_nc_ohc=CJt5RvX4IMcAX8d4Eoj&_nc_ht=scontent.fmnl4-3.fna&oh=00_AfBbjv9SOjudXAdayQpmYURgD_vc-y0046huNpZOvtiDgA&oe=652D77A5",
                        "descr": "Welcome to the official Facebook page for The Fish Market Restaurants. We're glad you're here and inv",
                        "category": "Seafood Restaurant",
                        "builtwith": "Facebook Pixel; Google Analytics; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "web",
                        "title": "CUCINA urbana",
                        "address": "505 Laurel St, San Diego, CA 92101, USA",
                        "phone": "(619) 239-2222",
                        "email": "contact@urbankitchengroup.com",
                        "web": "http://www.cucinaurbana.com/",
                        "social": "https://facebook.com/cucina | https://instagram.com/cucinarestaurants",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Google Tag; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "web",
                        "title": "Baci Restaurant",
                        "address": "1955 Morena Blvd, San Diego, CA 92110, USA",
                        "phone": "(619) 275-2094",
                        "email": "info@sandiegobaci.com",
                        "web": "http://sandiegobaci.com/",
                        "social": "https://facebook.com/sandiegobaci | https://yelp.com/biz/baci-restaurant-san-diego",
                        "likes": "765/759",
                        "service": "$$$/Dine-in · Outdoor seating",
                        "logo": "https://scontent-iad3-2.xx.fbcdn.net/v/t39.30808-1/301971438_404073618531227_5950795770997176499_n.png?stp=dst-png_p200x200&_nc_cat=111&ccb=1-7&_nc_sid=5f2048&_nc_ohc=aovOuZnD1o8AX8h_kX9&_nc_ht=scontent-iad3-2.xx&oh=00_AfCZvEz5QiUi5lAVsQm9dKQlyAEQ2v5RLDrdzMpR2BjzdA&oe=652D87E2",
                        "descr": "Baci Ristorante is a family-owned, fine dining, Italian restaurant.",
                        "category": "Italian Restaurant",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.8;"
                    },
                    {
                        "search_type": "web",
                        "title": "Animae",
                        "address": "969 Pacific Hwy, San Diego, CA 92101, USA",
                        "phone": "(619) 432-1225",
                        "email": "animae@puffermalarkeycollective.com",
                        "web": "https://www.animaesd.com/",
                        "social": "https://facebook.com/animaesd | https://instagram.com/animae.sandiego",
                        "likes": "2.9K /3.1K",
                        "service": "/Dine in",
                        "logo": "https://scontent-jnb1-1.xx.fbcdn.net/v/t39.30808-1/358681572_665591722277705_5477927646676492791_n.png?stp=dst-png_p200x200&_nc_cat=102&ccb=1-7&_nc_sid=5f2048&_nc_ohc=zDrTxuWElSYAX-ousjO&_nc_ht=scontent-jnb1-1.xx&oh=00_AfBzdwqPECDw69zAuVqYWTPJ0ENFmA_mAOdexFM4hh0zSQ&oe=652EA091",
                        "descr": "ANIMAE’s eclectic West Coast cuisine, layered with Asian influences, comes to life under Executive Ch",
                        "category": "Asian fusion restaurant",
                        "builtwith": "Facebook Pixel; Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 8,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "web",
                        "title": "The Westgate Room Restaurant",
                        "address": "1055 2nd Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 238-1818",
                        "email": "grandamerica@wearebreadandbutter.com\ninfo@westgatehotel.com\nsales@westgatehotel.com",
                        "web": "https://www.westgatehotel.com/dining/the-westgate-room/",
                        "social": "https://facebook.com/westgatehotel | https://instagram.com/westgatehotel | https://linkedin.com/company/the-westgate-hotel",
                        "likes": "10K /10K",
                        "service": "$$$",
                        "logo": "https://scontent-lga3-1.xx.fbcdn.net/v/t39.30808-1/278336098_10160078477988210_895815551878769555_n.jpg?stp=dst-jpg_p200x200&_nc_cat=110&ccb=1-7&_nc_sid=5f2048&_nc_ohc=vERnbdXER_UAX8M0FMM&_nc_oc=AQk0GSDN1myzDBZbtjp0ZWuOGo2qhhiDEeKU8_xNcE9YYnaX1VXZcbP0iYlWTptriEY&_nc_ht=scontent-lga3-1.xx&oh=00_AfCptK6v0dvF5klXPz8XkAkTNDth2H0zrWC8iaOkStZJcg&oe=652E4CF3",
                        "descr": "The Westgate, San Diego’s premiere hotel continues to deliver on its original vision of classic luxur",
                        "category": "Hotel",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "business",
                        "title": "The Crack Shack - Little Italy",
                        "address": "2266 Kettner Blvd, San Diego, CA 92101, USA",
                        "phone": "(619) 795-3299",
                        "email": "",
                        "web": "https://locations.crackshack.com/ca/san-diego/san-diego/",
                        "social": "https://facebook.com/crackshacklittleitaly | https://facebook.com/thecrackshackrestaurant | https://instagram.com/getcrackshacked | https://twitter.com/getcrackshacked | https://yelp.com/biz/the-crack-shack-san-diego-2",
                        "likes": "4.5K /4.7K",
                        "service": "",
                        "logo": "https://scontent.fsan1-2.fna.fbcdn.net/v/t39.30808-1/307099903_3161644137467964_2350992954822118364_n.jpg?stp=dst-jpg_p200x200&_nc_cat=101&ccb=1-7&_nc_sid=5f2048&_nc_ohc=893J2q6aUYAAX9li97C&_nc_ht=scontent.fsan1-2.fna&oh=00_AfAiCJYNe-i2TD3wE6wXZ-5cmf_nD3Xl7EyVuwrc2xhu5g&oe=652DA2D3",
                        "descr": "",
                        "category": "",
                        "builtwith": "Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "business",
                        "title": "The Fish Market - San Diego",
                        "address": "750 N Harbor Dr, San Diego, CA 92101, USA",
                        "phone": "(619) 232-3474",
                        "email": "",
                        "web": "http://www.thefishmarket.com/",
                        "social": "https://facebook.com/thefishmarkets | https://instagram.com/thefishmarkets | https://twitter.com/thefishmarkets",
                        "likes": "4.5K /4.6K",
                        "service": "",
                        "logo": "https://scontent-mia3-2.xx.fbcdn.net/v/t39.30808-1/348267369_2883877505081209_4304403015172097968_n.png?stp=dst-png_p200x200&_nc_cat=110&ccb=1-7&_nc_sid=5f2048&_nc_ohc=CJt5RvX4IMcAX-MgljU&_nc_ht=scontent-mia3-2.xx&oh=00_AfBPaFZjffiLLFmDQMExW4Y_xI5ebFVUT3zap0y3A7_BnQ&oe=652D77A5",
                        "descr": "Welcome to the official Facebook page for The Fish Market Restaurants. We're glad you're here and inv",
                        "category": "Seafood Restaurant",
                        "builtwith": "Facebook Pixel; Google Analytics; Google Tag; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.4;"
                    },
                    {
                        "search_type": "business",
                        "title": "Bandar Restaurant",
                        "address": "845 Fourth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 238-0101",
                        "email": "info@bandarrestaurant.com",
                        "web": "http://www.bandarrestaurant.com/",
                        "social": "https://facebook.com/pages/bandar-restaurant-persian-steakhouse/117524838267123 | https://instagram.com/bandarrestaurant | https://twitter.com/bandarsandiego",
                        "likes": "/2K",
                        "service": "$$$/Dine-in · Outdoor seating · In-store pickup",
                        "logo": "https://scontent-ord5-2.xx.fbcdn.net/v/t39.30808-1/240113530_327403308835578_6343069388462066568_n.jpg?stp=dst-jpg_p200x200&_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_ohc=W_TJXO-Lg6IAX9vEIQX&_nc_ht=scontent-ord5-2.xx&oh=00_AfB2xeaodn5v4du4OfANWgv__5AI1JgBUN9Cdr4h8d_saA&oe=652D93CE",
                        "descr": "Our Award Wining Menu Features: Fresh Prime Steaks, World Famous Chicken, Seafood and Classic Persia",
                        "category": "Persian/Iranian Restaurant",
                        "builtwith": "Google Analytics; Woocommerce; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "3.9;"
                    },
                    {
                        "search_type": "business",
                        "title": "ADDISON",
                        "address": "5200 Grand Del Mar Way, San Diego, CA 92130, USA",
                        "phone": "(858) 314-2000",
                        "email": "",
                        "web": "http://www.addisondelmar.com/?utm_source=google+Maps&utm_medium=seo+maps&utm_campaign=seo+maps&y_source=1_MTU2Mzc2NTEtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
                        "social": "https://instagram.com/addisonrestaurant",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "business",
                        "title": "The Lion's Share",
                        "address": "629 Kettner Blvd, San Diego, CA 92101, USA",
                        "phone": "(619) 564-6924",
                        "email": "info@lionssharesd.com",
                        "web": "http://www.lionssharesd.com/",
                        "social": "https://instagram.com/thelionssharesd",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.7;"
                    },
                    {
                        "search_type": "business",
                        "title": "Pushkin Hookah Lounge & Bar",
                        "address": "750 Sixth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 496-1908",
                        "email": "pushkinrestaurant@gmail.com",
                        "web": "http://pushkinrestaurantsd.com/",
                        "social": "https://facebook.com/pushkinsandiego",
                        "likes": "4.1K /4.2K",
                        "service": "$$/Dine-in · Outdoor seating · Curbside pickup…",
                        "logo": "https://scontent-hou1-1.xx.fbcdn.net/v/t39.30808-1/348221708_936793240977848_5636437861072572375_n.jpg?stp=dst-jpg_p200x200&_nc_cat=106&ccb=1-7&_nc_sid=5f2048&_nc_ohc=Y6FiGsd3Q9IAX_u1jkE&_nc_ht=scontent-hou1-1.xx&oh=00_AfC87ANXSr5VsRrUy2X0MYmXUYXGRdQVSDkl8ptalZ0QYA&oe=652D4E14",
                        "descr": "At Pushkin you’ll find European cuisine elegantly crafted with modern flavor and best hookah in SD.",
                        "category": "Hookah Lounge",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "business",
                        "title": "Callie",
                        "address": "1195 Island Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 255-9696",
                        "email": "info@calliesd.com",
                        "web": "https://calliesd.com/",
                        "social": "https://facebook.com/callierestaurant | https://instagram.com/callierestaurant | https://instagram.com/folklor.la",
                        "likes": "1.1K /1.3K",
                        "service": "$$$/Dine-in",
                        "logo": "https://scontent-lga3-2.xx.fbcdn.net/v/t39.30808-1/291634428_366815268892464_6433015383704039168_n.jpg?stp=dst-jpg_p200x200&_nc_cat=105&ccb=1-7&_nc_sid=5f2048&_nc_ohc=7CsbGZRiQA4AX9TZBT-&_nc_ht=scontent-lga3-2.xx&oh=00_AfDlh2ivBKVDmB8xGS7aMQzZJgX0Qc8U6IE6CEQSUvsgYw&oe=652E8625",
                        "descr": "Callie is the first solo project by Chef Travis Swikard. Derived from the Greek \"kallos,\" meaning th",
                        "category": "Restaurant",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.7;"
                    },
                    {
                        "search_type": "business",
                        "title": "Civico 1845",
                        "address": "1845 India St, San Diego, CA 92101, USA",
                        "phone": "(619) 431-5990",
                        "email": "info@civico1845.com",
                        "web": "https://www.civico1845.com/?y_source=1_OTIzMTMwNDItNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
                        "social": "https://facebook.com/civico1845 | https://instagram.com/civico1845",
                        "likes": "4.5K /4.5K",
                        "service": "$$/Outdoor seating · In-store pickup",
                        "logo": "https://scontent-atl3-1.xx.fbcdn.net/v/t39.30808-1/305645903_757150465637528_6974819960015011535_n.jpg?stp=dst-jpg_p200x200&_nc_cat=111&ccb=1-7&_nc_sid=5f2048&_nc_ohc=cYfR1rRSp9oAX-PeAja&_nc_ht=scontent-atl3-1.xx&oh=00_AfC0FsKsccQ8gGiIbsvCsiMK0jKuBNzbaFTqeXgHeXGATQ&oe=652E7F73",
                        "descr": "Civico 1845 features a new generation of Italian cuisine - fresh, light, authentic, and vegan. Exper",
                        "category": "Italian Restaurant",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.4;"
                    },
                    {
                        "search_type": "business",
                        "title": "La Puerta",
                        "address": "560 Fourth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 696-3466",
                        "email": "",
                        "web": "https://lapuertasd.com/?utm_source=Business-Listings",
                        "social": "https://facebook.com/lapuertasd | https://instagram.com/lapuertasd | https://twitter.com/lapuertasd",
                        "likes": "13K /13K",
                        "service": "$$/Dine-in · Outdoor seating",
                        "logo": "https://scontent-bos5-1.xx.fbcdn.net/v/t39.30808-1/341096390_536554598642282_6559122814341441084_n.jpg?stp=dst-jpg_p200x200&_nc_cat=106&ccb=1-7&_nc_sid=5f2048&_nc_ohc=F3GaiB59nBoAX99nkuA&_nc_ht=scontent-bos5-1.xx&oh=00_AfDkLRysjFytyvEGUq4k5r3n4CgSRKjcuisFWN2LYIWpnQ&oe=652E4EA8",
                        "descr": "Improving people's lives one tequila shot at a time!",
                        "category": "Mexican Restaurant",
                        "builtwith": "Google Analytics; Google Tag; Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "business",
                        "title": "Lou & Mickey's",
                        "address": "224 Fifth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 237-4900",
                        "email": "ajames@kingsseafood.com\ncontactus@louandmickeys.com\nkcarpenter@kingsseafood.com\nmmalicdem@louandmickeys.com",
                        "web": "https://www.louandmickeys.com/",
                        "social": "https://facebook.com/louandmickeys | https://instagram.com/louandmickeys | https://twitter.com/louandmickeys",
                        "likes": "3.5K /3.5K",
                        "service": "$$/Outdoor seating",
                        "logo": "https://scontent.fsac1-1.fna.fbcdn.net/v/t39.30808-1/361374022_763874489079629_4046450032993819922_n.jpg?stp=dst-jpg_p200x200&_nc_cat=106&ccb=1-7&_nc_sid=5f2048&_nc_ohc=HnccU2_vqp4AX_PHNGE&_nc_ht=scontent.fsac1-1.fna&oh=00_AfDhtT9WgFS7NF4LIHsmq-PRes_6BzLtREQryCREr_b27A&oe=652EE2A4",
                        "descr": "Famous Steaks, Choice Seafood & Exotic Drinks",
                        "category": "Steakhouse",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "business",
                        "title": "Born and Raised",
                        "address": "1909 India St, San Diego, CA 92101, USA",
                        "phone": "(619) 202-4577",
                        "email": "info@bornandraisedsteak.com",
                        "web": "http://bornandraisedsteak.com/",
                        "social": "",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "business",
                        "title": "Trust",
                        "address": "3752 Park Blvd, San Diego, CA 92103, USA",
                        "phone": "(619) 795-6901",
                        "email": "",
                        "web": "http://www.trustrestaurantsd.com/",
                        "social": "https://instagram.com/trustrestaurantsd",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 7,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "business",
                        "title": "Morning Glory",
                        "address": "550 W Date St Suite #C, San Diego, CA 92101, USA",
                        "phone": "(619) 629-0302",
                        "email": "",
                        "web": "https://morningglorybreakfast.com/",
                        "social": "",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 6,
                        "rel": 1.0,
                        "ratings": "4.3;"
                    },
                    {
                        "search_type": "business",
                        "title": "North Italia",
                        "address": "7055 Friars Rd, San Diego, CA 92108, USA",
                        "phone": "(619) 343-2301",
                        "email": "info@northitalia.com",
                        "web": "https://www.northitalia.com/locations/san-diego-ca-fashion-valley/",
                        "social": "https://facebook.com/northitalia | https://instagram.com/eatnorthitalia",
                        "likes": "71K /72K",
                        "service": "$$",
                        "logo": "https://scontent-lga3-1.xx.fbcdn.net/v/t39.30808-1/339926738_240439661735594_4662538898389715658_n.jpg?stp=dst-jpg_p200x200&_nc_cat=108&ccb=1-7&_nc_sid=5f2048&_nc_ohc=SnxSaf5srf8AX-seihj&_nc_ht=scontent-lga3-1.xx&oh=00_AfBko-thQrnnQgmrOuX33dvLehHfIwOgGUMI9Rz_d70kxw&oe=652E6412",
                        "descr": "Not all carbs are created equal.",
                        "category": "Italian Restaurant",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 6,
                        "rel": 1.0,
                        "ratings": "4.4;"
                    },
                    {
                        "search_type": "business",
                        "title": "Hob Nob Hill",
                        "address": "2271 First Ave., San Diego, CA 92101, USA",
                        "phone": "(619) 239-8176",
                        "email": "hobnobhill1944@gmail.com",
                        "web": "https://hobnobhill.com/?y_source=1_MTQ2MTA4ODEtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
                        "social": "",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 6,
                        "rel": 1.0,
                        "ratings": "4.4;"
                    },
                    {
                        "search_type": "business",
                        "title": "Hodad's Downtown",
                        "address": "945 Broadway, San Diego, CA 92101, USA",
                        "phone": "(619) 234-6323",
                        "email": "",
                        "web": "http://hodadies.com/",
                        "social": "",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Google Analytics; Stripe; Woocommerce",
                        "keyword": "San Diego restaurants",
                        "weight": 6,
                        "rel": 1.0,
                        "ratings": "4.6;"
                    },
                    {
                        "search_type": "business",
                        "title": "Cannonball",
                        "address": "3105 Ocean Front Walk, San Diego, CA 92109, USA",
                        "phone": "(858) 228-9304",
                        "email": "cannonballsd@belmontpark.com",
                        "web": "http://www.cannonballsd.com/",
                        "social": "https://facebook.com/cannonballsd | https://instagram.com/cannonballsd | https://twitter.com/cannonballsd",
                        "likes": "7.5K /7.5K",
                        "service": "/Outdoor seating",
                        "logo": "https://scontent-cgk1-2.xx.fbcdn.net/v/t39.30808-1/313248816_512409964232618_2537696942828203172_n.jpg?stp=dst-jpg_p200x200&_nc_cat=106&ccb=1-7&_nc_sid=5f2048&_nc_ohc=a2yv1octDloAX9h6ccd&_nc_ht=scontent-cgk1-2.xx&oh=00_AfC5QwqW9JCchPM-jp4EcUkiJBNR1pO2s8ZIfhkZJGTXHQ&oe=652D3A4A",
                        "descr": "Sushi with a splash! Besides the delicious inventive sushi and California cuisine inspired by Pacifi",
                        "category": "Sushi Restaurant",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 6,
                        "rel": 1.0,
                        "ratings": "4.3;"
                    },
                    {
                        "search_type": "business",
                        "title": "Davanti Enoteca",
                        "address": "1655 India St, San Diego, CA 92101, USA",
                        "phone": "(619) 237-9606",
                        "email": "",
                        "web": "http://www.davantienoteca.com/sandiego",
                        "social": "https://facebook.com/davantienoteca | https://facebook.com/davantisandiego | https://instagram.com/davanti_enoteca | https://twitter.com/davanti_enoteca",
                        "likes": "5.4K /5.4K",
                        "service": "$$",
                        "logo": "https://scontent-dfw5-1.xx.fbcdn.net/v/t39.30808-1/294673837_1030915094354034_4429320768649353835_n.png?stp=dst-png_p200x200&_nc_cat=105&ccb=1-7&_nc_sid=5f2048&_nc_ohc=03WxtN8ArAMAX_Nlmx3&_nc_ht=scontent-dfw5-1.xx&oh=00_AfDibqiqryBXGwLUbH0KILI1VRskhH1L3M1KzMhpmgLoQQ&oe=652DC6BA",
                        "descr": "A member of the Francesca's Restaurant Group; Davanti Enoteca is a wine bar that offers shareable pl",
                        "category": "Italian Restaurant",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 6,
                        "rel": 1.0,
                        "ratings": "4.4;"
                    },
                    {
                        "search_type": "business",
                        "title": "Fort Oak",
                        "address": "1011 Fort Stockton Dr, San Diego, CA 92103, USA",
                        "phone": "(619) 722-3398",
                        "email": "",
                        "web": "https://fortoaksd.com/",
                        "social": "https://instagram.com/fortoaksd",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "",
                        "keyword": "San Diego restaurants",
                        "weight": 6,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    },
                    {
                        "search_type": "business",
                        "title": "Cafe Sevilla of San Diego",
                        "address": "353 Fifth Ave, San Diego, CA 92101, USA",
                        "phone": "(619) 233-5979",
                        "email": "",
                        "web": "https://www.cafesevilla.com/",
                        "social": "",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Facebook Pixel; Google Tag",
                        "keyword": "San Diego restaurants",
                        "weight": 6,
                        "rel": 1.0,
                        "ratings": "4.3;"
                    },
                    {
                        "search_type": "business",
                        "title": "Ironside Fish & Oyster",
                        "address": "1654 India St, San Diego, CA 92101, USA",
                        "phone": "(619) 269-3033",
                        "email": "",
                        "web": "https://ironsidefishandoyster.com/",
                        "social": "",
                        "likes": "",
                        "service": "",
                        "logo": "",
                        "descr": "",
                        "category": "",
                        "builtwith": "Wordpress",
                        "keyword": "San Diego restaurants",
                        "weight": 6,
                        "rel": 1.0,
                        "ratings": "4.5;"
                    }
                ]
            }
        },
        "POST /api/records/1/": () => {
             return {
                 "status": 200,
                 "message": "",
                 "dv": 1,
                 "size": 60,
                 "records": [
                     {
                         "title": "monakoland.com.ua",
                         "descr": "web",
                         "address": "",
                         "phone": "+380631086911 | +380980898107 | +380682726929 | +380961872131 | +380665274190 | +380976754215 | +380678044434 | +380322987742 | +380983635502 | +380686335581",
                         "email": "info@monakoland.com.ua",
                         "web": "https://monakoland.com.ua/",
                         "likes": "",
                         "social": "https://www.facebook.com/monako | https://www.instagram.com/monako_ua",
                         "search_type": "web",
                         "builtwith": "Facebook Pixel",
                         "relevance": 0.16,
                         "weight": 6,
                         "review": "",
                         "id": 6,
                         "category": "",
                         "service": "",
                         "logo": "/media/images/monako.png",
                         "keyword": "fgh monako",
                         "ratings": "",
                         "sqr": 5.82
                     },
                     {
                         "search_type": "web",
                         "title": "Carnitas' Snack Shack - Embarcadero",
                         "address": "1004 N Harbor Dr, San Diego, CA 92101, USA",
                         "phone": "(619) 696-7675",
                         "email": "embarcadero@carnitassnackshack.com",
                         "web": "http://carnitassnackshack.com/",
                         "social": "https://facebook.com/carnitassnacks | https://instagram.com/carnitassnacks | https://twitter.com/carnitassnacks",
                         "likes": "17K /17K",
                         "service": "$/Delivery · Contactless delivery · Takeout…",
                         "logo": "/media/images/28e4138ebdfed085b7edf7deda1b9bf3.png",
                         "descr": "Carnitas’ Snack Shack is San Diego’s original pork house offering a wide variety of indulgent food, l",
                         "category": "Comfort Food Restaurant",
                         "builtwith": "Facebook Pixel; Google Analytics; Woocommerce; Wordpress",
                         "keyword": "San Diego restaurants",
                         "weight": 9,
                         "rel": 1.0,
                         "id": 5.0,
                         "ratings": "4.4;12,000"
                     },
                 ]
             }
         },
        "POST /api/aprove/": () => {
            return {
                "status": 200,
                "message": "",
                "dv": 0,
                "variant": "success",
                "orderlink": "Your query was submitted: [Payment: OK, Amount: $1, Quantity: 60 records, Link: http://127.0.0.1:8000/api/gms/ch_3N18hIGefNSPz2RI1z83Opv4/]\n - use this link to find your final results in case your session expires",
                "oid": "ch_3N18hIGefNSPz2RI1z83Opv4"
            }
        },
        "GET /api/lki_test_2/": () => {
            return {
                "status": 200,
                "size": 20,
                "message": "",
                "records": [
                    {
                        "title": "VillageWorks Fredrikinkatu",
                        "descr": "Text Text Text Text",
                        "address": "Fredrikinkatu 61, 00100 Helsinki, Finland",
                        "phone": "010 2710670",
                        "email": "info@villageworks.com\nsales@villageworks.com",
                        "web": "https://villageworks.com/fi/toimistohotelli/fredrikinkatu/",
                        "likes": "2k/3k",
                        "social": "https://facebook.com/villageworkshq | https://instagram.com/villageworkshq | https://linkedin.com/company/villageworkshq",
                        "search_type": "0",
                        "builtwith": "Google Analytics",
                        "relevance": 1.0,
                        "weight": 9,
                        "id": 1,
                        "category": "Payroll Services, Tax Services, Accountants",
                        "service": "sfsdfsd",
                        "logo": "/media/images/28e4138ebdfed085b7edf7deda1b9bf3.png",
                        "keyword": "31008 Coworking Space",
                        "ratings": "4.1;",
                        "sqr": 16.79
                    },
                    {
                        "title": "Spaces - Helsinki, Postitalo Spaces",
                        "descr": "",
                        "address": "",
                        "phone": "",
                        "email": "",
                        "web": "",
                        "likes": "",
                        "social": "",
                        "search_type": "0",
                        "builtwith": "",
                        "relevance": "",
                        "weight": "",
                        "id": 2,
                        "category": "",
                        "service": "",
                        "logo": "/media/images/65d78e7363a3c8fa5907dffbb182f3ab.jpeg",
                        "keyword": "",
                        "ratings": "",
                        "sqr": ""
                    },
                    {
                        "title": "Spaces - Helsinki, Postitalo Spaces",
                        "descr": "",
                        "address": "",
                        "phone": "",
                        "email": "",
                        "web": "",
                        "likes": "",
                        "social": "",
                        "search_type": "0",
                        "builtwith": "",
                        "relevance": "",
                        "weight": "",
                        "id": 2,
                        "category": "",
                        "service": "",
                        "logo": "/media/images/81d76dba5f16920f03f7675e149d189a.j[eg",
                        "keyword": "",
                        "ratings": "",
                        "sqr": ""
                    },
                    {
                        "title": "Spaces - Helsinki, Postitalo Spaces",
                        "descr": "",
                        "address": "",
                        "phone": "",
                        "email": "",
                        "web": "",
                        "likes": "",
                        "social": "",
                        "search_type": "0",
                        "builtwith": "",
                        "relevance": "",
                        "weight": "",
                        "id": 2,
                        "category": "",
                        "service": "",
                        "logo": "/media/images/1111f7b99735ef78dd693ecbc1acfed3.jpeg",
                        "keyword": "",
                        "ratings": "",
                        "sqr": ""
                    },
                    {
                        "title": "Spaces - Helsinki, Postitalo Spaces",
                        "descr": "",
                        "address": "",
                        "phone": "",
                        "email": "",
                        "web": "",
                        "likes": "",
                        "social": "",
                        "search_type": "0",
                        "builtwith": "",
                        "relevance": "",
                        "weight": "",
                        "id": 2,
                        "category": "",
                        "service": "",
                        "logo": "/media/images/ac7e6c9e31d9655d0832f89511377e4a.jpeg",
                        "keyword": "",
                        "ratings": "",
                        "sqr": ""
                    },
                    {
                        "title": "Spaces - Helsinki, Postitalo Spaces",
                        "descr": "",
                        "address": "",
                        "phone": "",
                        "email": "",
                        "web": "",
                        "likes": "",
                        "social": "",
                        "search_type": "0",
                        "builtwith": "",
                        "relevance": "",
                        "weight": "",
                        "id": 2,
                        "category": "",
                        "service": "",
                        "logo": "/media/images/ad3d8c174ba14bd7a6249326370714b1.png",
                        "keyword": "",
                        "ratings": "",
                        "sqr": ""
                    },
                    {
                        "title": "Spaces - Helsinki, Postitalo Spaces",
                        "descr": "",
                        "address": "",
                        "phone": "",
                        "email": "",
                        "web": "",
                        "likes": "",
                        "social": "",
                        "search_type": "0",
                        "builtwith": "",
                        "relevance": "",
                        "weight": "",
                        "id": 2,
                        "category": "",
                        "service": "",
                        "logo": "/media/images/b5653ea486e100c9bddbc3593b0b3cc9.jpeg",
                        "keyword": "",
                        "ratings": "",
                        "sqr": ""
                    },
                    {
                        "title": "Spaces - Helsinki, Postitalo Spaces",
                        "descr": "",
                        "address": "",
                        "phone": "",
                        "email": "",
                        "web": "",
                        "likes": "",
                        "social": "",
                        "search_type": "0",
                        "builtwith": "",
                        "relevance": "",
                        "weight": "",
                        "id": 2,
                        "category": "",
                        "service": "",
                        "logo": "/media/images/d492ea24c001c16157cdb3e9f3284597.png",
                        "keyword": "",
                        "ratings": "",
                        "sqr": ""
                    },
                ],
                "total_records": 55,

            }
        },
        "GET /api/lki_test/": () => {
            return {
                "status": 200,
                "size": 20,
                "message": "",
                "records": [
                    {
                        "title": "VillageWorks Fredrikinkatu",
                        "descr": "Kotoisat ja edulliset toimistotilat. VillageWorks Fredrikinkatu, lempinimelt\u00e4\u00e4n Freda, sijaitsee erinomaisella paikalla Helsingin ytimess\u00e4.",
                        "address": "Fredrikinkatu 61, 00100 Helsinki, Finland",
                        "phone": "010 2710670",
                        "email": "info@villageworks.com\nsales@villageworks.com",
                        "web": "https://villageworks.com/fi/toimistohotelli/fredrikinkatu/",
                        "likes": "2k/3k",
                        "social": "https://www.facebook.com/topofthemarketsd | https://instagram.com/villageworkshq | https://linkedin.com/company/villageworkshq",
                        "search_type": "0",
                        "builtwith": "Google Analytics",
                        "relevance": 1.0,
                        "weight": 9,
                        "id": 2,
                        "category": "Payroll Services, Tax Services, Accountants",
                        "service": "$$",
                        "logo": "/media/images/65d78e7363a3c8fa5907dffbb182f3ab.jpeg",
                        "keyword": "31008 Coworking Space",
                        "ratings": "4.1;2,612",
                        "sqr": 16.79,
                        "review": "1,946"},
                    {
                        "title": "Spaces - Helsinki, Postitalo Spaces",
                        "descr": "L\u00f6yd\u00e4 juuri sinulle sopivat ty\u00f6tilat kohteessa Mannerheiminaukio 1. Regus tarjoaa t\u00e4ysin varustetut ty\u00f6tilat kaiken kokoisille tiimeille. Vuokraa p\u00e4iv\u00e4ksi, kuukaudeksi tai pidemm\u00e4ksi ajaksi.",
                        "address": "Mannerheiminaukio 1, 00100 Helsinki, Finland",
                        "phone": "09 35423000",
                        "email": "",
                        "web": "https://www.regus.com/fi-fi/finland/helsinki/mannerheiminaukio-1-4458?utm_source=yext_places_gmb&utm_medium=places&utm_campaign=yext_traffic&utm_content=4458",
                        "likes": "",
                        "social": "https://facebook.com/regusglobal | https://instagram.com/regusglobal | https://linkedin.com/company/regus | https://twitter.com/regusglobal",
                        "search_type": "0",
                        "builtwith": "Google Analytics; Sitecore",
                        "relevance": 1.0,
                        "weight": 8,
                        "id": 1,
                        "category": "",
                        "service": "$$$",
                        "logo": "/media/images/28e4138ebdfed085b7edf7deda1b9bf3.png",
                        "keyword": "31008 Coworking Space",
                        "ratings": "4.3;",
                        "sqr": 16.0
                    },
                    {
                        "title": "Mesta Coworking",
                        "descr": "Tietosuojaseloste REKISTERINPIT\u00c4J\u00c4 Oy Mastemas LtdY-tunnus 1070283-1Tarttilantie 2803400 Vihti mirka@mestacoworking.com+358 40 510 8622 Rekisterin nimi:\u00a0 Mesta Coworking Asiakasrekisteri \u00a0 YLEIST\u00c4 Yksityisyytesi on meille t\u00e4rke\u00e4 asia. Jotta voimme palvella sinua mahdollisimman hyvin ja kehitt\u00e4\u00e4 toimintaamme, on meid\u00e4n kuitenkin ker\u00e4tt\u00e4v\u00e4 ja k\u00e4sitelt\u00e4v\u00e4 tiettyj\u00e4 sinua koskevia tietoja. T\u00e4m\u00e4 tietosuojaseloste kertoo, mit\u00e4 henkil\u00f6tietoja ker\u00e4\u00e4mme, kuinka niit\u00e4 k\u00e4sittelemme, sek\u00e4 mit\u00e4 oikeuksia [\u2026]",
                        "address": "Hiihtom\u00e4entie 18, 00810 Helsinki, Finland",
                        "phone": "040 5108622",
                        "email": "arto@mestacoworking.com\nmirka@mestacoworking.com",
                        "web": "http://mestacoworking.com/",
                        "likes": "",
                        "social": "https://facebook.com/mestacoworking | https://facebook.com/mirka | https://facebook.com/policy | https://instagram.com/mestacoworking | https://instagram.com/mirkanalimova | https://linkedin.com/company/mestacoworking | https://linkedin.com/in/mirkanalimova",
                        "search_type": "0",
                        "builtwith": "Facebook Pixel; Google Tag; Woocommerce; Wordpress",
                        "relevance": 1.0,
                        "weight": 9,
                        "id": 5,
                        "category": "",
                        "service": "/Dine-in · Outdoor seating · In-store pickup",
                        "logo": "",
                        "keyword": "31008 Coworking Space",
                        "ratings": "4.8;612",
                        "sqr": 15.32
                    },
                    {
                        "title": "Wonderland Work",
                        "descr": "Upea Coworking-tila Helsingiss\u00e4. Ravintolan, neuvotteluhuoneiden ja tapahtumatilan yhdistelm\u00e4. Kaikki, mit\u00e4 tarvitset saman katon alla!",
                        "address": "Konepajankuja 1, 00510 Helsinki, Finland",
                        "phone": "040 0803533",
                        "email": "espoo@wonderlandwork.fi\nhello@wonderlandwork.fi",
                        "web": "https://wonderlandwork.fi/",
                        "likes": "",
                        "social": "https://facebook.com/wonderlandwork | https://instagram.com/wonderlandwork_coworking | https://youtube.com/channel/uchbyleykwv3skslv7m8_tdw",
                        "search_type": "0",
                        "builtwith": "Woocommerce",
                        "relevance": 1.0,
                        "weight": 9,
                        "id": 6,
                        "category": "",
                        "service": "",
                        "logo": "",
                        "keyword": "31008 Coworking Space",
                        "ratings": "2.0;12",
                        "sqr": 15.05
                    },
                    {
                        "title": "Spaces - Helsinki, Spaces Tripla",
                        "descr": "L\u00f6yd\u00e4 juuri sinulle sopivat ty\u00f6tilat kohteessa Firdonkatu 2. Regus tarjoaa t\u00e4ysin varustetut ty\u00f6tilat kaiken kokoisille tiimeille. Vuokraa p\u00e4iv\u00e4ksi, kuukaudeksi tai pidemm\u00e4ksi ajaksi.",
                        "address": "Firdonkatu 2 Workery West, 6th floor, 00520 Helsinki, Finland",
                        "phone": "09 23110100",
                        "email": "",
                        "web": "https://www.regus.com/fi-fi/finland/helsinki/firdonkatu-2-5069?utm_source=yext_places_gmb&utm_medium=places&utm_campaign=yext_traffic&utm_content=5069",
                        "likes": "",
                        "social": "https://facebook.com/regusglobal | https://instagram.com/regusglobal | https://linkedin.com/company/regus | https://twitter.com/regusglobal",
                        "search_type": "0",
                        "builtwith": "Google Analytics; Sitecore",
                        "relevance": 1.0,
                        "weight": 8,
                        "id": 3,
                        "category": "",
                        "service": "",
                        "logo": "",
                        "keyword": "31008 Coworking Space",
                        "ratings": "4.8;12,000",
                        "sqr": 14.34
                    },
                    {
                        "title": "Sofia Helsinki",
                        "descr": "Loistava tapa tehd\u00e4 t\u00f6it\u00e4! Fiksu ja joustava ratkaisu t\u00e4m\u00e4n p\u00e4iv\u00e4n ty\u00f6ntekoon. Edustavat ja toimivat tilat Helsingin historiallisessa keskustassa.",
                        "address": "Sofiankatu 4 C, 00170 Helsinki, Finland",
                        "phone": "040 1448432",
                        "email": "harri.kangas@sofiahelsinki.fi\ninfo@sofiahelsinki.fi\njoni.hinkkanen@sofiahelsinki.fi\nravintola@sofiahelsinki.fi",
                        "web": "https://sofiahelsinki.fi/",
                        "likes": "",
                        "social": "https://facebook.com/sofiahelsinkisenaatintori | https://instagram.com/sofiahelsinki | https://linkedin.com/company/sofiafuturefarm",
                        "search_type": "0",
                        "builtwith": "Facebook Pixel; Woocommerce; Wordpress",
                        "relevance": 1.0,
                        "weight": 9,
                        "id": 14,
                        "category": "",
                        "service": "",
                        "logo": "",
                        "keyword": "31008 Coworking Space",
                        "ratings": "3.8;1,000",
                        "sqr": 13.82
                    },
                    {
                        "title": "VillageWorks Suomitalo",
                        "descr": "VillageWorksin toimistohotelleissa ty\u00f6nteko on helppoa, sujuvaa ja yhteis\u00f6llist\u00e4. Toimistot \u2713 Coworking \u2713 Kokoustilat \u2713 Virtuaaltioimisto \u2713 Tapahtumatilat",
                        "address": "L\u00f6nnrotinkatu 5, 00120 Helsinki, Finland",
                        "phone": "010 2710670",
                        "email": "info@villageworks.com\nsales@villageworks.com",
                        "web": "https://villageworks.com/fi/",
                        "likes": "",
                        "social": "https://facebook.com/villageworkshq | https://instagram.com/villageworkshq | https://linkedin.com/company/villageworkshq",
                        "search_type": "0",
                        "builtwith": "Google Analytics",
                        "relevance": 1.0,
                        "weight": 9,
                        "id": 17,
                        "category": "",
                        "service": "$$$/Dine-in · Outdoor seating · In-store pickup",
                        "logo": "",
                        "keyword": "31008 Coworking Space",
                        "ratings": "4.2;",
                        "sqr": 13.56
                    },
                    {
                        "title": "VillageWorks Lauttasaari",
                        "descr": "Oma toimistotila, coworking, kokoustilat, auditorio ja saunallinen tapahtumatila \u2013 rento Lauttasaari on t\u00e4yden palvelun toimistohotelli.",
                        "address": "Heikkil\u00e4ntie 7, 00210 Helsinki, Finland",
                        "phone": "010 2710670",
                        "email": "info@villageworks.com\nlauttasaari@villageworks.com\nsales@villageworks.com",
                        "web": "https://villageworks.com/fi/toimistohotelli/lauttasaari/",
                        "likes": "",
                        "social": "https://facebook.com/villageworkshq | https://instagram.com/villageworkshq | https://linkedin.com/company/villageworkshq",
                        "search_type": "0",
                        "builtwith": "Google Analytics",
                        "relevance": 1.0,
                        "weight": 9,
                        "id": 20,
                        "category": "",
                        "service": "",
                        "logo": "",
                        "keyword": "31008 Coworking Space",
                        "ratings": "",
                        "sqr": 13.34
                    },
                    {
                        "title": "Valo Hotel",
                        "descr": "VALO Hotel & Work on uudenlainen hybridihotelli Helsingin Ruskeasuolla, jossa luonto on k\u00e4den ulottuvilla ja kaupungin kulttuuri vain ratikkamatkan p\u00e4\u00e4ss\u00e4.",
                        "address": "Mannerheimintie 109, 00280 Helsinki, Finland",
                        "phone": "010 3404000",
                        "email": "reception.helsinki@valo.fi",
                        "web": "https://valo.fi/",
                        "likes": "",
                        "social": "https://facebook.com/valohotelwork | https://instagram.com/valohotelwork | https://linkedin.com/company/14839264 | https://youtube.com/channel/uccjml0gh2g8d577n_9tgdga",
                        "search_type": "0",
                        "builtwith": "Woocommerce",
                        "relevance": 1.0,
                        "weight": 9,
                        "id": 21,
                        "category": "",
                        "service": "",
                        "logo": "",
                        "keyword": "31008 Coworking Space",
                        "ratings": "4.4;",
                        "sqr": 13.28
                    },
                    {
                        "title": "VillageWorks Etel\u00e4esplanadi",
                        "descr": "VillageWorksin toimistotilat Etel\u00e4esplanadilla sijaitsee arvokkaalla alueella suoraan Havis Amandan patsasta vastap\u00e4\u00e4t\u00e4. Vuokraa oma toimisto Etel\u00e4esplanadilta.",
                        "address": "Etel\u00e4esplanadi 2, 00130 Helsinki, Finland",
                        "phone": "010 2710670",
                        "email": "info@villageworks.com\nsales@villageworks.com",
                        "web": "https://villageworks.com/fi/toimistohotelli/etelaesplanadi/",
                        "likes": "",
                        "social": "https://facebook.com/villageworkshq | https://instagram.com/villageworkshq | https://linkedin.com/company/villageworkshq",
                        "search_type": "0",
                        "builtwith": "Google Analytics",
                        "relevance": 1.0,
                        "weight": 9,
                        "id": 22,
                        "category": "",
                        "service": "",
                        "logo": "",
                        "keyword": "31008 Coworking Space",
                        "ratings": "4;",
                        "sqr": 13.21
                    },
                    {
                        "title": "VillageWorks Valas",
                        "descr": "VillageWorks Valas on ainutlaatuinen ja urbaani toimistohotelli Kalliossa. T\u00e4\u00e4lt\u00e4 l\u00f6yd\u00e4t inspiroivat ty\u00f6tilat ja toimistot, joissa ty\u00f6rauha on taattu.",
                        "address": "H\u00e4meentie 31, 00500 Helsinki, Finland",
                        "phone": "010 2710670",
                        "email": "info@villageworks.com\nsales@villageworks.com\nvalas@villageworks.com",
                        "web": "https://villageworks.com/fi/toimistohotelli/kallio/",
                        "likes": "",
                        "social": "https://facebook.com/villageworkshq | https://instagram.com/villageworkshq | https://linkedin.com/company/villageworkshq",
                        "search_type": "0",
                        "builtwith": "Google Analytics",
                        "relevance": 1.0,
                        "weight": 9,
                        "id": 23,
                        "category": "",
                        "service": "",
                        "logo": "",
                        "keyword": "31008 Coworking Space",
                        "ratings": "4.7;",
                        "sqr": 13.16
                    },
                    {
                        "title": "Helsinki Think Company",
                        "descr": "A community that provides tools, support and opportunities for impact-seeking individuals, so they can give their ideas a go.",
                        "address": "Yliopistonkatu 4, 00100 Helsinki, Finland",
                        "phone": "044 7229873",
                        "email": "aleksandra@thinkcompany.fi\nbutterman@thinkcompany.fi\ncansu@thinkcompany.fi\ncristina@thinkcompany.fi\neetu-petteri@thinkcompany.fi\neeva-stiina@thinkcompany.fi\neeva@thinkcompany.fi\nelina.nikkanen@thinkcompany.fi\nelli@thinkcompany.fi\neveliina@thinkcompany.fi",
                        "web": "http://www.thinkcompany.fi/",
                        "likes": "",
                        "social": "https://facebook.com/helsinkithinkcompany | https://instagram.com/helsinkithinkco | https://linkedin.com/company/helsinkithinkcompany | https://linkedin.com/in/cristinavalledevicente | https://linkedin.com/in/eeva-stiina-niemi-a7568013b | https://linkedin.com/in/eevasii | https://linkedin.com/in/eveliina-holopainen-795449213 | https://linkedin.com/in/heidiollitervo | https://linkedin.com/in/iina-lummepuro | https://linkedin.com/in/jani-lehto | https://linkedin.com/in/katariina-tonttila-b45313176 | https://linkedin.com/in/marihannakarjalainen | https://linkedin.com/in/venla-jusslin | https://twitter.com/helsinkithinkco",
                        "search_type": "0",
                        "builtwith": "Google Tag",
                        "relevance": 1.0,
                        "weight": 9,
                        "id": 24,
                        "category": "",
                        "service": "",
                        "logo": "",
                        "keyword": "31008 Coworking Space",
                        "ratings": "4.7;",
                        "sqr": 13.1
                    },
                    {
                        "title": "Innovation Home Kamppi",
                        "descr": "Innovation Home tarjoaa ainutlaatuisia ja joustavia toimistoratkaisuja keskeisill\u00e4 sijainneilla! Meilt\u00e4 l\u00f6yd\u00e4t laadukkaat yksityiset toimistot eri kokoisille tiimeille sek\u00e4 viihtyis\u00e4t coworking-ty\u00f6tilat. Lis\u00e4ksi meilt\u00e4 l\u00f6yd\u00e4t useita paljon erilaisia kokous- ja tapahtumatiloja, sek\u00e4 studiohuoneita mm. podcastien ja webinaarien tuottamiseen.",
                        "address": "Kansakoulukatu 3, 00100 Helsinki, Finland",
                        "phone": "040 6700141",
                        "email": "",
                        "web": "http://www.innovationhome.fi/",
                        "likes": "",
                        "social": "https://facebook.com/ihcoworking | https://instagram.com/innovationhome_coworking | https://linkedin.com/company/innovationhome",
                        "search_type": "0",
                        "builtwith": "Google Tag; Wordpress",
                        "relevance": 1.0,
                        "weight": 8,
                        "id": 8,
                        "category": "",
                        "service": "",
                        "logo": "",
                        "keyword": "31008 Coworking Space",
                        "ratings": "3.9;",
                        "sqr": 13.0
                    },
                    {
                        "title": "Epicenter",
                        "descr": "Rent a desk or a flexible office, host a knowledge driven event, book a conference, join an innovation program, or just stop by.",
                        "address": "Mikonkatu 9, 00100 Helsinki, Finland",
                        "phone": "040 6267032",
                        "email": "hello@epicenterhelsinki.com",
                        "web": "https://weareepicenter.com/helsinki/",
                        "likes": "",
                        "social": "https://facebook.com/epicenterhelsinki | https://instagram.com/epicenterhelsinki | https://linkedin.com/company/epicenter-helsinki | https://twitter.com/epicenterh",
                        "search_type": "0",
                        "builtwith": "Wordpress",
                        "relevance": 1.0,
                        "weight": 9,
                        "id": 27,
                        "category": "",
                        "service": "",
                        "logo": "",
                        "keyword": "31008 Coworking Space",
                        "ratings": "4.5;",
                        "sqr": 12.95
                    },
                    {
                        "title": "The Office at College Hill",
                        "descr": "Welcome to The Office @ College Hill, Macon GA's newest private office and \r\ncoworking space.",
                        "address": "1305 Hardeman Ave #100, Macon, GA 31201, USA",
                        "phone": "(478) 731-1213",
                        "email": "theofficeatcollegehill@gmail.com",
                        "web": "https://www.theofficecollegehill.com/",
                        "likes": "",
                        "social": "https://facebook.com/theofficecollegehill | https://instagram.com/theofficeatcollegehill",
                        "search_type": "0",
                        "builtwith": "Google Analytics",
                        "relevance": 1.0,
                        "weight": 9,
                        "id": 29,
                        "category": "",
                        "service": "",
                        "logo": "",
                        "keyword": "31008 Coworking Space",
                        "ratings": "4.4;",
                        "sqr": 12.85
                    },
                    {
                        "title": "Workland Esplanadi",
                        "descr": "Offices, virtual offices, coworking. Workland's flexible and comfortable solutions fit freelancers as well as larger companies. Tallinn, Riga, Vilnius, Kaunas.",
                        "address": "Pohjoisesplanadi 39, 00100 Helsinki, Finland",
                        "phone": "050 5995138",
                        "email": "",
                        "web": "https://wrkland.com/",
                        "likes": "",
                        "social": "https://facebook.com/worklandestonia | https://instagram.com/wrkland | https://linkedin.com/company/workland-offices",
                        "search_type": "0",
                        "builtwith": "Facebook Pixel; Google Tag",
                        "relevance": 1.0,
                        "weight": 8,
                        "id": 9,
                        "category": "",
                        "service": "",
                        "logo": "",
                        "keyword": "31008 Coworking Space",
                        "ratings": "4.5;",
                        "sqr": 12.84
                    },
                    {
                        "title": "Workland Teurastamo",
                        "descr": "Workland was brought to life with a vision to provide an alternative to traditional office space rental where any kind of working style is supported.",
                        "address": "Lautatarhankatu 10, 00580 Helsinki, Finland",
                        "phone": "050 5703615",
                        "email": "",
                        "web": "https://wrkland.com/",
                        "likes": "",
                        "social": "https://facebook.com/worklandestonia | https://instagram.com/wrkland | https://linkedin.com/company/workland-offices",
                        "search_type": "0",
                        "builtwith": "Facebook Pixel; Google Tag",
                        "relevance": 1.0,
                        "weight": 8,
                        "id": 12,
                        "category": "",
                        "service": "",
                        "logo": "",
                        "keyword": "31008 Coworking Space",
                        "ratings": "4.6;",
                        "sqr": 12.48
                    },
                    {
                        "title": "Regus - Helsinki Luna House",
                        "descr": "L\u00f6yd\u00e4 juuri sinulle sopivat ty\u00f6tilat kohteessa Luna House. Regus tarjoaa t\u00e4ysin varustetut ty\u00f6tilat kaiken kokoisille tiimeille. Vuokraa p\u00e4iv\u00e4ksi, kuukaudeksi tai pidemm\u00e4ksi ajaksi.",
                        "address": "Luna House, Mannerheimintie 12 B 5.krs, 00100 Helsinki, Finland",
                        "phone": "09 2516600",
                        "email": "",
                        "web": "https://www.regus.com/fi-fi/finland/helsinki/luna-house-257?utm_source=yext_places_gmb&utm_medium=places&utm_campaign=yext_traffic&utm_content=257",
                        "likes": "",
                        "social": "https://facebook.com/regusglobal | https://instagram.com/regusglobal | https://linkedin.com/company/regus | https://twitter.com/regusglobal",
                        "search_type": "0",
                        "builtwith": "Google Analytics; Sitecore",
                        "relevance": 1.0,
                        "weight": 8,
                        "id": 13,
                        "category": "",
                        "service": "",
                        "logo": "",
                        "keyword": "31008 Coworking Space",
                        "ratings": "4.6;",
                        "sqr": 12.38
                    },
                    {
                        "title": "Regus - Helsinki, Vallila",
                        "descr": "L\u00f6yd\u00e4 juuri sinulle sopivat ty\u00f6tilat kohteessa Kumpulantie 3. Regus tarjoaa t\u00e4ysin varustetut ty\u00f6tilat kaiken kokoisille tiimeille. Vuokraa p\u00e4iv\u00e4ksi, kuukaudeksi tai pidemm\u00e4ksi ajaksi.",
                        "address": "Kumpulantie 3 3rd Floor, 00520 Helsinki, Finland",
                        "phone": "09 85607300",
                        "email": "",
                        "web": "https://www.regus.com/fi-fi/finland/helsinki/kumpulantie-3-5276?utm_source=yext_places_gmb&utm_medium=places&utm_campaign=yext_traffic&utm_content=5276",
                        "likes": "",
                        "social": "https://facebook.com/regusglobal | https://instagram.com/regusglobal | https://linkedin.com/company/regus | https://twitter.com/regusglobal",
                        "search_type": "0",
                        "builtwith": "Google Analytics; Sitecore",
                        "relevance": 1.0,
                        "weight": 8,
                        "id": 15,
                        "category": "",
                        "service": "",
                        "logo": "",
                        "keyword": "31008 Coworking Space",
                        "ratings": "4.3;",
                        "sqr": 12.2
                    },
                    {
                        "title": "Spacent",
                        "descr": "Meet our wonderful team and reach out to us! Read more about Spacent's history, goals in sustainability and helping organisations to find solutions to the modern workspace demand.",
                        "address": "Kaivokatu 12, 00100 Helsinki, Finland",
                        "phone": "+358456732505",
                        "email": "anni.vuolle-apiala@spacent.com\nannika.hameenheimo@spacent.com\nchristopher.berner@spacent.com\neetu.ristaniemi@spacent.com\nhello@spacent.com\njatta.nemeth@spacent.com\nlotta.lehti@spacent.com\notto.pirttimaa@spacent.com\npetteri.susi@spacent.com\npinja.hokkanen@spacent.com",
                        "web": "http://www.spacent.com/",
                        "likes": "",
                        "social": "https://facebook.com/spacent | https://instagram.com/spacent.ltd | https://linkedin.com/company/spacent | https://linkedin.com/in/annika-hameenheimo | https://linkedin.com/in/anniv-a | https://linkedin.com/in/christopher-berner-130532116 | https://linkedin.com/in/eetu-ristaniemi-a1969b37 | https://linkedin.com/in/jattanemeth | https://linkedin.com/in/lotta-lehti-8216a4151 | https://linkedin.com/in/otto-pirttimaa | https://linkedin.com/in/petterisusi | https://linkedin.com/in/pinja-valkama-841007180 | https://linkedin.com/in/pinjahokkanen | https://linkedin.com/in/samikurvinen",
                        "search_type": "0",
                        "builtwith": "",
                        "relevance": 1.0,
                        "weight": 7,
                        "id": 4,
                        "category": "",
                        "service": "",
                        "logo": "",
                        "keyword": "31008 Coworking Space",
                        "ratings": "",
                        "sqr": 12.19
                    }
                ],
                "total_records": 55
            }
        }
    }
);

export default fixture;
