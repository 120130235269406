<template>
  <div class="content-main pb-4 pt-4">
    <div class="container-m">
      <b-row>
<!--        <b-col cols="12">-->
<!--          <h1 class="text-center pb-3 pt-3">What is the difference between Web and Business search?</h1>-->
<!--        </b-col>-->
        <b-col cols="12" class="rbd">
          <p>The search results table includes the following:</p>
          <ul style="list-style: none" class="gray_arrow">
<!--            <li class="gr_text">-->
            <li>
              <p>Thousands Business Leads per single search.<br>  Up to 50 000 leads for a bulk search<br> Results in a few minutes or less depending on the search volume</p>
            </li>
            <li>
              <p>
                Full Business Name<br>Postal Address separated by Street, City, Country and Zip<br>Phone number<br>Website Link<br>Email Address
              </p>
            </li>
            <li>
              <p>
                Links to Social Media Handles (Instagram, Linkedin, Facebook, Twitter, Yelp, TikTok)<br>
                Get social data such as number of followers on Instagram, average like count etc<br>
                Get review scores for each lead from Yelp, Facebook and Google<br>
                Track which leads are actively running paid ads on Facebook/Messenger/Instagram or Yelp<br>
                Track which leads are using Facebook Pixel or Google Remarketing<br>
                BuiltWith features (tools and frameworks that a particular web site is built with)
              </p>
            </li>
            <li>
              <p>
                Web page Text relevance score<br>Web page Page Rank score<br>
                D-C score representing how good and rich is the data for each company/webpage (integrates Web Page Rank, Text relevance and amount of data fields available)
              </p>
            </li>
            <li>
              <p>
                Ability to Sort Results by any columns (Name, City, State, Page Rank, Relevance, DC Rank, email, etc)<br>
                Detect if business website is Mobile Friendly or uses Google Analytics/Schema/Wordpress etc<br>
                CSV/Excel/Google Sheet Export Options<br>
                Live Support See Bottom Right
              </p>
            </li>
         </ul>
<!--          <h4>What is the difference between Business search and Web search?</h4>-->
<!--          <p>Use Business search for something that has a physical address or office location, <br>-->
<!--            whereas Web search is better used for something general on the web that does not necessarily has a physical location. <br>-->
<!--            Here are examples of Business vs Web search</p>-->
<!--          <p>-->
<!--          <b-card-group deck>-->
<!--              <b-card title="Business search">-->
<!--                <b-card-text>-->
<!--                  <ul style="list-style: none" class="gray_arrow">-->
<!--                    <li class="gr_text">San Diego restaurants</li>-->
<!--                    <li class="gr_text">Boston Auto repairs</li>-->
<!--                    <li class="gr_text">Los Angeles McDonald's</li>-->
<!--                    <li class="gr_text">New York lawyers</li>-->
<!--                    <li class="gr_text">San Francisco gyms</li>-->
<!--                  </ul>-->
<!--                </b-card-text>-->
<!--              </b-card>-->
<!--              <b-card title="Web search">-->
<!--                <b-card-text>-->
<!--                  <ul style="list-style: none" class="gray_arrow">-->
<!--                    <li class="gr_text">Australian Travel blogs</li>-->
<!--                    <li class="gr_text">1000 ecommerce websites</li>-->
<!--                    <li class="gr_text">Top fashion brands</li>-->
<!--                    <li class="gr_text">Top US banks</li>-->
<!--                    <li class="gr_text">Top US Law schools</li>-->
<!--                  </ul>-->
<!--                </b-card-text>-->
<!--              </b-card>-->
<!--            </b-card-group>-->
<!--          </p>-->
           <p>Start your lead generation, contact location, list building process with Dat-Cat!</p>
           <p>Please provide stable internet connection during query execution (dont turn on VPN, etc) to not break the session and get final results in the table. Otherwise your results can be obtained through the link provided above the resulting table and will be emailed to you.</p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutView',
  components: {}
}
</script>

<style scoped>
h1 {
  font-size: 28px;
}
ul {
  list-style: none;
}
</style>
