<style></style>

<template>
  <div class="d-flex flex-column navbar-dat-cat" :style="{ marginTop: getMarginNavbar + 'px' }">
    <header>
      <div v-if="!isHomePage">
        <navbar-about-pages></navbar-about-pages>
      </div>

      <div v-else-if="showNavbarOnTable">
        <navbar-on-table></navbar-on-table>
      </div>

      <div v-else>
        <nav-bar></nav-bar>
      </div>

      <main>
        <router-view></router-view>
      </main>
    </header>

  </div>
</template>

<script>
import NavBar from "@/views/chunks/NavBar.vue";
// import {currentRenderingInstance} from "vue/src/core/instance/render";
import NavbarOnTable from "@/views/chunks/NavbarOnTable.vue";
import NavbarAboutPages from "@/views/chunks/NavbarAboutPages.vue";
export default {
  name: "NavbarRouters",
  components: { NavBar, NavbarOnTable, NavbarAboutPages },
  data() {
    return {
      footerHeight: 0,
      navbarHeight: 0,
      windowHeight: 0,
      windowWidth: 0,
      // currentTable: false,
      // onSearchTable: false,
      // SearchTableActivated: false,
      // LastKeywordItemsActivated: false,
      // LastKeywordsActivated: true,
      submitPayment: false,
      isHomePage: true,
      heightCalculated: false,
    };
  },
  computed: {
    showNavbarOnTable() {
      return ((this.SearchTableActivated && this.submitPayment) || this.LastKeywordItemsActivated) && this.windowWidth > 980;
    },

    SearchTableActivated: {
     get() {
        return this.$store.getters.isSearchTable;
      },
      set(value) {
        this.$store.commit('SET_isSearchTable', value)
      },
   },

    LastKeywordsActivated: {
     get() {
        return this.$store.getters.isLK;
      },
      set(value) {
        this.$store.commit('SET_isLK', value)
      },
   },

    LastKeywordItemsActivated: {
     get() {
        return this.$store.getters.isLKI;
      },
      set(value) {
        this.$store.commit('SET_isLKI', value)
      },
   },

    calculateMtAutoHeight: function () {
      return Math.max(0, (this.windowHeight - (this.navbarHeight + this.footerHeight))/2);
    },

    getMarginNavbar: function () {
      if (this.isHomePage) {
        if ((this.SearchTableActivated && this.submitPayment) || this.LastKeywordItemsActivated) {
          return 10;
        } else {
          return this.calculateMtAutoHeight;
        }
      } else {
        return 10;
      }
    }
  },
  created() {
    // Установка значения isHomePage при инициализации компонента
    this.checkIsHomePage();

    // Слушатель маршрута для обновления значения isHomePage
    this.$router.afterEach(() => {
      this.checkIsHomePage();
    });
  },
  mounted() {

    this.$bus.$on('submitPayment', (value) => {
      this.submitPayment = value;
    });

    this.$nextTick(() => {
      this.calculateParentAndNavbarHeight();
      this.heightCalculated = true;
    });

    // Добавляем слушатель события resize
    window.addEventListener("resize", this.calculateParentAndNavbarHeight);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.calculateParentAndNavbarHeight);
  },
  methods: {
    // currentRenderingInstance() {
    //   return currentRenderingInstance
    // },
    calculateParentAndNavbarHeight() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
      const heightNavbar = document.querySelector(".navbar-dat-cat");
      const heightFooter = document.querySelector("footer");
      this.navbarHeight = heightNavbar.clientHeight;
      this.footerHeight = heightFooter.clientHeight;
    },
    checkIsHomePage() {
      this.isHomePage = this.$route.path === '/';
    },
  },

  // watch: {
  //   searchTableActivated: function (newValue) {
  //     this.searchTableActivated = newValue;
  //   },
  //   lastKeywordItemsActivated: function (newValue) {
  //     this.lastKeywordItemsActivated = newValue;
  //   }
  // }
};
</script>
