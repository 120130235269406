<script>
import axios from 'axios';
export default {
  name: "PopularTable",
  data() {
    return {
      canShow: false,
      isTextHover: false,
      fakeItems: [
        {keyword: "Test Keyword 1 Test Keyword 1 Test Keyword 1 Test Keyword 1"},
        {keyword: "San Francisco"},
        {keyword: "New York"},
        {keyword: "San Diego"},
        {keyword: "San Francisco"},
        {keyword: "New York"},
        {keyword: "San Diego"},
        {keyword: "San Francisco"},
        {keyword: "New York"},
        {keyword: "New York"},
      ],
      items: [],
      fields: [
        { key: 'keyword', label: 'Popular' }
      ]
    }
  },

  computed: {
    itemsFormatted() {
      if (this.canShow) {
        return this.items;
      } else {
        return this.fakeItems;
      }
    },
  },

  methods: {
    getData() {
      axios.get('https://' + window.location.host + '/api/pk/')
        .then(response => {
          this.items = response.data;
        })
        .catch(error => {
          // console.error('Error fetching data:', error);
        });
    },

    viewClick: function (data) {
      // this.$emit('change_view_component', data);
      this.$store.commit('SET_keywordLKI', data);
      this.$emit('on-lki', 'LastKeywordItems');
    },
  },

  mounted() {
    this.getData();
    this.$bus.$on('show-lastKeyWords', () => { this.canShow = true });
  },
}
</script>

<template>
  <div class="popular_table" :class="{ 'show_on_table': !canShow }">
    <b-table :items="itemsFormatted" :fields="fields" borderless small>
      <template #head(keyword)="data">
        <div class="d-flex justify-content-start align-items-center" style="height: 30px;">
          <div class="head-logo">
            <svg width="16" height="16">
              <path fill="#de4747"
                d="M14.5 12a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h13zM15 2l-.676 4.733-1.352-1.353-3.381 3.381a.955.955 0 01-1.352 0L6.211 6.733l-3.719 3.718a.477.477 0 01-.676 0l-.676-.676a.477.477 0 010-.676l4.394-4.394a.956.956 0 011.353 0l2.028 2.028 2.704-2.705-1.351-1.352L15 2z">
              </path>
            </svg>
          </div>
          <div class="head-text">
            {{ data.label }}
          </div>
        </div>
      </template>

      <template #cell(keyword)="data">
        <div class="wrapp-keywords">
          <span class="keyword-cell d-inline-block text_truncated"
              v-on:click='viewClick({ "value": data.item.view, "idx": 1 })'
              style="max-width: 200px;"
        >
          {{ data.value }}
        </span>
        </div>
      </template>

    </b-table>
  </div>
</template>


<style scoped>
table {
  border: 1px solid #dee2e6;
}

/deep/ .table>tbody>tr>td {
  padding: 5px 5px 5px 20px;
  height: 30px;
}

/deep/ .table>tbody>tr:first-child>td {
  padding-top: 10px;
}

/deep/ .table>tbody>tr:last-child>td {
  padding-bottom: 10px;
}

/deep/ .table>thead>tr>th {
  padding-left: 20px;
  border-bottom: 2px solid #dee2e6;
}

.keyword-cell {
  cursor: pointer;
}

.keyword-cell:hover {
  text-decoration: underline;
  color: #0056b3;
}

.wrapp-keywords {
  position: relative;
}

.text_truncated {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text_hover {
  position: absolute;
  white-space: normal;
  background-color: #fff;
  border: 1px solid #dee2e6;
  font-size: 110%;
  padding: 5px;
  max-width: 200px;
  visibility: hidden;
  top: 0;
  left: 0;
}

.wrapp-keywords:hover .text_hover {
  visibility: visible !important;
}

.head-logo svg {
  margin-right: 10px;
}

.head-text {
  color: rgb(51, 51, 51);
  font-weight: 600;
  padding-top: 1px;
}

.head-logo svg {
  margin-right: 10px;
  color: #968cff;
}

.custom-tooltip-color {
  background-color: white;
  color: black;
}

.show_on_table {
  visibility: hidden;
}
</style>