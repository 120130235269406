<template>
  <div class="search-bulk">
    <div class="input-group">
      <b-form-textarea
        class="inputRadiusBulk"
        size="sm"
        :value="list_search"
      >
<!--        {{ list_search }}-->
      </b-form-textarea>
    </div>
  </div>
</template>

<script>

export default {
  name: "SearchBulk",
  props: {
    list_search: {
      type: String,
      required: true,
    },
  },

  computed: {
  },

}
</script>

<style scoped>

.inputRadiusBulk {
  border-radius: 0;
  min-height: 12rem;
  max-height: 16rem;
  height: 4rem;
  padding-left: 1rem;
}

</style>