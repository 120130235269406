import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueResource from 'vue-resource';
import VueCookies from 'vue-cookies'
import Loading from 'vue-loading-overlay';
import JsonCSV from 'vue-json-csv'
import VueHead from "vue-head";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-loading-overlay/dist/vue-loading.css';
import '@/static/css/custom.css';


Vue.config.productionTip = false;
Vue.prototype.$bus = new Vue()

Vue.use(VueHead);
Vue.use(VueResource);
Vue.use(Loading);
Vue.use(VueCookies);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin)

Vue.component('downloadCsv', JsonCSV)

new Vue({
  router,
  store,
  delimiters: ['${', '}'],
  render: h => h(App)
}).$mount('#app')
