<template>
  <div class="content-main pb-4 pt-4">
    <div class="container-m">
      <b-row class="mb-4">
        <b-col cols="12 rbd">
          <h1 class="text-center pb-3 pt-3">How our pricing works?</h1>
          <p class="fs-5 text-body-secondary">
           It’s basically $1 per 1000 search results in <b>Web</b> search and $1 per 100 search results in <b>Business</b> and <b>People</b> search. First 10 recordsa are Free.
          </p>
          <p class="fs-5 text-body-secondary">So 1000 records will cost $1, 1520 records -$2, 22 330 records - $23.<br>
            Just choose the amount of records you want to be returned and the system will prorate the $ amount to be paid. You will be charged for only the number of records returned. For example, if you requested 2000 records ($2), but your search returned 750 records you will be charged for only 750 records (i.e. $1).</p>
          <p class="fs-5 text-body-secondary">
            In Bulk search the price is $1 per query/keyword minimum.
          </p>
        </b-col>
      </b-row>

      <b-row class="row-margin">
        <b-col cols="12 rbd">
          <h1 class="text-center pb-3 pt-3">
            How do I know how many records my query returns? <br>How to choose the amount for my search?
          </h1>
          <p class="fs-5 text-body-secondary">
            To get an idea on how many records your query can return:
          </p>
          <ul class="gray_arrow">
            <li>
              Query capacity bar shows how much is left in your query. For example, you requested 200 records, but after
              finishing the search query capacity bar shows about 10%, that means you can get ~2000 records for this
              query.
            </li>
            <li>
              So start with smaller amounts (100, 200, etc.) to get an idea. Otherwise, you can start with a bigger
              number and be charged only for amount the query returns, in case your requested more than the query
              returns.
            </li>
            <li>
              Please see the similar searches in the live search table just below the search bar. Notice Query Capacity
              bar, showing how many records you can expect from such query
            </li>
            <li>
              Otherwise, just Google your search and see how many records Google returns and compare to similar
              searches, thus you will have an idea how many records such a query should return
            </li>
          </ul>
          <p>If you believe that more data is available for your query than what the system returned, please contact us
            in the live chat window, down right corner of the page. We will assist you to fulfill your
            requirements..</p>
        </b-col>
      </b-row>
<!--      <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">-->
<!--        <div class="col">-->
<!--          <div class="card mb-4 rounded-3 shadow-sm">-->
<!--            <div class="card-header py-3">-->
<!--              <h4 class="my-0 fw-normal">Free</h4>-->
<!--            </div>-->
<!--            <div class="card-body">-->
<!--              <h1 class="card-title pricing-card-title">$0<small class="text-body-secondary fw-light">/mo</small></h1>-->
<!--              <ul class="list-unstyled mt-3 mb-4">-->
<!--                <li>10 users included</li>-->
<!--                <li>2 GB of storage</li>-->
<!--                <li>Email support</li>-->
<!--                <li>Help center access</li>-->
<!--              </ul>-->
<!--              <button type="button" class="w-100 btn btn-lg btn-outline-primary">Sign up for free</button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col">-->
<!--          <div class="card mb-4 rounded-3 shadow-sm">-->
<!--            <div class="card-header py-3">-->
<!--              <h4 class="my-0 fw-normal">Pro</h4>-->
<!--            </div>-->
<!--            <div class="card-body">-->
<!--              <h1 class="card-title pricing-card-title">$15<small class="text-body-secondary fw-light">/mo</small></h1>-->
<!--              <ul class="list-unstyled mt-3 mb-4">-->
<!--                <li>20 users included</li>-->
<!--                <li>10 GB of storage</li>-->
<!--                <li>Priority email support</li>-->
<!--                <li>Help center access</li>-->
<!--              </ul>-->
<!--              <button type="button" class="w-100 btn btn-lg btn-primary">Get started</button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col">-->
<!--          <div class="card mb-4 rounded-3 shadow-sm border-primary">-->
<!--            <div class="card-header py-3 text-bg-primary border-primary">-->
<!--              <h4 class="my-0 fw-normal">Enterprise</h4>-->
<!--            </div>-->
<!--            <div class="card-body">-->
<!--              <h1 class="card-title pricing-card-title">$29<small class="text-body-secondary fw-light">/mo</small></h1>-->
<!--              <ul class="list-unstyled mt-3 mb-4">-->
<!--                <li>30 users included</li>-->
<!--                <li>15 GB of storage</li>-->
<!--                <li>Phone and email support</li>-->
<!--                <li>Help center access</li>-->
<!--              </ul>-->
<!--              <button type="button" class="w-100 btn btn-lg btn-primary">Contact us</button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "PricingView"
}
</script>

<style scoped>
h1 {
  font-size: 28px;
}

.row-margin {
  margin-top: 5rem !important;
}
</style>