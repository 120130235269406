<template>
  <div>
        <div id="card-error" v-show="errorMessage" class="info text-error p-1">{{errorMessage}}</div>
        <div id="card-success" v-show="successMessage" class="info text-success p-1">{{successMessage}}</div>

        <div class="card-element text-left mb-3">
          <label for="card-email-element" class="font-gray">Email:</label>
          <b-input type="email"
                   class="form-control font-gray"
                   id="card-email-element"
                   :state="emailState"
                   v-model="cardEmail"
                   trim />
          <div id="email_message" class="msg text-danger">{{ validateEmailMessage }}</div>
        </div>
        <div class="card-element text-left mb-3">
          <label class="font-gray form-">Card Number:</label>
          <div class="form-control" id="card-number-element"></div>
        </div>

        <div class="card-element text-left mb-3">
          <label class="font-gray">Expiry Date:</label>
          <div class="form-control"  id="card-expiry-element"></div>
        </div>

        <div class="card-element text-left">
          <label class="font-gray">CVC:</label>
          <div class="form-control" id="card-cvc-element"></div>
          <span><i class="bi bi-card-heading"></i></span>
        </div>

        <div class="mt-2">
          <b-row class="mb-2 mt-4">
            <div v-show="freeSearchErrorMessage" class="text-danger">{{ freeSearchErrorMessage }}</div>
            <button class="btn btn-success shadow-sm form-control" :disabled="lockSubmit" @click="freePurchase">Free search (10 rec.)
              <b-spinner
                  class="spinner"
                  variant="light"
                  type="border"
                  v-show="showLoadSpinner"
              ></b-spinner>
                  <b-badge v-show="fqc > 0" variant="light">{{ fqc }} requests<span class="sr-only"></span></b-badge>
            </button>
          </b-row>
          <b-row>
            <button class="btn btn-warning shadow-sm form-control" :disabled="lockSubmit" @click="purchase">Full search
              <b-spinner
                  class="spinner"
                  variant="light"
                  type="border"
                  v-show="showLoadSpinner"
              ></b-spinner>
            </button>
          </b-row>
        </div>
    </div>
</template>
<script>
    const axios = require('axios');
    import cookies from 'vue-cookies'
    import stripePromise from './stripe.js';

    export default {
      name: 'StripComponent',
      data() {
        return {
          cardNumber: null,
          cardExpiry: null,
          cardCVC: null,
          cardEmail: "",
          stripeValidationError: "",
          stripe: null,
          lockSubmit: false,
          showLoadSpinner: false,
          errorMessage: "",
          successMessage: "",
          freeSearchErrorMessage: "",
          validateAmountMessage: "",
          validateQuantityMessage: "",
          validateEmailMessage: "",
          submitPayment: false,
        }
      },
      computed: {
        sfrm: {
          get() {
            return this.$store.getters.sfrm;
          },
          set(value) {
            this.$store.dispatch("actSfrm", value);
          }
        },
        fqc: {
          get(){ return this.$store.getters.fqc }
        },
        emailState() {
          let patt = /^(?<email>[\p{L}\p{N}._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8})$/igu;
          return patt.test(this.cardEmail.trim());
        },
      },
      methods: {
        async purchase() {
          let self = this;
          self.lockSubmit = true;
          self.showLoadSpinner = true;
          try {
            let keywordsQuantity = this.sfrm.q.length;
            let quantity = this.sfrm.limit;
            let amount = this.sfrm.amount;
            let email = this.cardEmail;

            this.submitPayment = true;
            this.$bus.$emit('submitPayment', this.submitPayment);

            self.clearMessages();
            await self.$nextTick();

            if (keywordsQuantity > quantity) {
              let msg = `The amount (${keywordsQuantity}) of keywords must be less than the amount (${quantity}) selected in the payment plan`;
              await self.$store.dispatch("actMSG", {"status": "5001", "message": msg, "variant": "danger"});
              return
            }
            const data = await self.dataValidation(quantity, amount, email);
            if (!data){
              let msg = {
                  "status": 5001,
                  "message": "Server connection error.",
                  "variant": "danger"
              }
              await self.$store.dispatch("actMSG", msg);
              return
            }

            if (data && data.status && data.status === 5001) {
              if (data['errors']) {
                self.showMessages(data);
              }
              let msg = {
                  "status": data['status'] || 5001,
                  "message": data['message'] || "Unkown error",
                  "variant": data['variant'] || "danger"
              }
              await self.$store.dispatch("actMSG", msg);
              return;
            }

            const {token, error} = await self.stripe.createToken(self.cardNumber);
            if (error) {
              self.setMessage(error.message, "error")
              let msg = {
                    "status": data['status'] || 5001,
                    "message": data['message'] || error.message || "Create token error",
                    "variant": data['variant'] || "danger"
                }
              await self.$store.dispatch("actMSG", msg);
              return
            }

            let response = await self.processTransaction(token.id);
            let oid = response['oid'] || "";
            let fp = response['fp'] || "";
            let orderlink = response['orderlink'] || ""
            if (oid){
              await this.$store.dispatch('actOid', oid);
              await this.$store.dispatch('actFp',fp);
              await this.$store.dispatch('actMSG', response['message']);
              await this.$store.dispatch('actDv', response['dv'] || 0);
              await this.$store.dispatch('actOrderLink', orderlink);
              await this.$store.dispatch('actSearchRequest');
              await self.$bus.$emit('on_search');
            }
            self.$bus.$emit('change_view_component', {'value': "", 'idx': 2})
          } catch (error) {
            if (error.response && error.response.status && error.response.statusText) {
              await self.$store.dispatch("actMSG", {"status": "5001",
                "message": error.response.statusText + " " + error.response.status, "variant": "danger"});
            } else {
              await self.$store.dispatch("actMSG", {"status": "5001", "message": "Unknown transaction error.", "variant": "danger"});
            }
          }finally {
            self.lockSubmit = false;
            self.showLoadSpinner = false;
            await self.$nextTick();
          }
        },

        async freePurchase() {
          let self = this;
          self.lockFreeSubmit = true;
          self.showLoadSpinner = true;
          try {
            this.submitPayment = true;
            self.clearMessages();
            await self.$nextTick();

            const {token, error} = await self.stripe.createToken(self.cardNumber);
            if (error) {
              self.$parent.setMessage(error.message, "error")
              return
            }

            let response = await self.processFreeTransaction(token);
            if (!response.data && response.status === 5001){
              self.freeSearchErrorMessage = response.message || "Free request error";
              return
            }
            let oid = response['oid'] || "";
            let fp = response['fp'] || "";
            let orderlink = response['orderlink'] || ""
            if (oid){
              await this.$store.dispatch('actOid', oid);
              await this.$store.dispatch('actFp',fp);
              await this.$store.dispatch('actMSG', response['message']);
              await this.$store.dispatch('actDv', response['dv'] || 0);
              await this.$store.dispatch('actOrderLink', orderlink);
              await this.$store.dispatch('actSearchRequest');
              await self.$bus.$emit('on_search');
            }
            self.$bus.$emit('change_view_component', {'value': "", 'idx': 2})
          } catch (error) {
            if (error.response && error.response.status && error.response.statusText) {
              await self.$store.dispatch("actMSG", {"status": "5001",
                "message": error.response.statusText + " " + error.response.status, "variant": "danger"});
            } else {
              await self.$store.dispatch("actMSG", {"status": "5001",
                "message": "Unknown erorr.", "variant": "danger"});
            }
          } finally {
            self.lockFreeSubmit = false;
            self.showLoadSpinner = false;
            self.$nextTick();
          }
        },

        async processTransaction(token) {
          let self = this;
          let payload = JSON.parse(JSON.stringify(self.$store.getters.sfrm))
          let descr = `Search Request: [${this.sfrm.q.join('|')}]`;
          if (descr) {
            payload['description'] = (descr.length <= 125) ? descr : descr.substring(0, 126);
          }
          payload['token'] = token;
          payload['email'] = this.cardEmail;
          payload['activetab'] = self.$store.getters.getActiveTab;
          payload['searchmode'] = self.$store.getters.getSearchMode;
          try {
            const options = {
              //url: '/api/aprove/',
              url: 'api/pis/',
              method: 'POST',
              headers: {
                "X-CSRFToken": cookies.get("csrftoken"),
                'Content-Type': 'application/json',
                "X-Requested-With": "XMLHttpRequest"
              },
              data: payload
            };
            let response = await axios(options);
            if (response && response.data && Object.hasOwn(response.data, "status") && response.data['status'] === 5001){
              let msg = Object.hasOwn(response.data, "message") ? response.data['message'] : "Unknown payment error";
              await self.$store.dispatch("actMSG", {"status": "5001", "message": msg, "variant": "danger"});
              return
            }
            return response.data;
          } catch (error) {
            if (error.response && error.response.status) {
              let dtm = (Object.hasOwn(error.response, "data") && Object.hasOwn(error.response.data, "message")) ? error.response.data.message : "Unknown payment error."
              let msg =  dtm ? dtm : error.response.statusText
              await self.$store.dispatch("actMSG", {"status": "5001", "message": msg, "variant": "danger"});
            } else {
              await self.$store.dispatch("actMSG", {"status": "5001", "message": "Unknown erorr.", "variant": "danger"});
            }
          }
        },

        async processFreeTransaction(token) {
          let self = this;
          let payload = JSON.parse(JSON.stringify(self.$store.getters.sfrm))
          let descr = `Search Request: [${this.sfrm.q.join('|')}]`;
          if (descr) {
            payload['description'] = (descr.length <= 125) ? descr : descr.substring(0, 126);
          }
          payload['token'] = token.id;
          payload['email'] = this.cardEmail;
          payload['activetab'] = self.$store.getters.getActiveTab;
          payload['searchmode'] = self.$store.getters.getSearchMode;
          payload['amount'] = 0;
          try {
            const options = {
              //url: `/api/sf_error_test/`,
              url: `/api/sf/`,
              method: 'POST',
              headers: {
                "X-CSRFToken": cookies.get("csrftoken"),
                'Content-Type': 'application/json',
                "X-Requested-With": "XMLHttpRequest"
              },
              data: payload
            };
            let response = await axios(options);
            return response.data;
          } catch (error) {
            let msg = error.response.statusText || "Free transaction error"
            console.error(msg)
          }
        },

        async dataValidation(quantity, amount, email) {
          let self = this;
          try {
            const options = {
              url: '/api/vldt/',
              method: 'POST',
              headers: {
                "X-CSRFToken": cookies.get("csrftoken"),
                'Content-Type': 'application/json',
                "X-Requested-With": "XMLHttpRequest"
              },
              data: {"quantity": quantity, "amount": amount, "email": email, "activeTab": self.$store.getters.getActiveTab}
            };

            let response = await axios(options);
            if (response && response.data && Object.hasOwn(response.data, "status") && response.data['status'] === 5001){
              let msg = response.data.errors && response.data.errors[0]['message'] ? response.data.errors[0]['message'] : "Fields validation error";
              //self.showMessages(response.data)
              self.setMessage(msg, "error")
              return
            }
            return response.data;
          } catch (error) {
            if (error.response && error.response.status) {
              let dtm = (Object.hasOwn(error.response, "data") && Object.hasOwn(error.response.data, "message")) ? error.response.data.message : "Unknown validation error."
              let msg =  dtm ? dtm : error.response.statusText
              await self.$store.dispatch("actMSG", {"status": "5001", "message": msg, "variant": "danger"});
            } else {
              await self.$store.dispatch("actMSG", {"status": "5001", "message": "Unknown error.", "variant": "danger"});
            }
          }
        },

        async createAndMountFormElements() {
          let elements = await this.stripe.elements();
          this.cardNumber = elements.create('cardNumber');
          this.cardNumber.mount("#card-number-element");
          this.cardExpiry = elements.create("cardExpiry");
          this.cardExpiry.mount("#card-expiry-element");
          this.cardCvc = elements.create("cardCvc");
          this.cardCvc.mount("#card-cvc-element");
          this.cardNumber.on("change", this.setValidationError);
          this.cardExpiry.on("change", this.setValidationError);
          this.cardCvc.on("change", this.setValidationError);
        },

        setValidationError(event) {
          this.stripeValidationError = event.error ? event.error.message : "";
          this.setMessage(this.stripeValidationError, 'error')
        },

        setMessage(messageText, messageType) {
          this.errorMessage = "";
          this.successMessage = "";
          switch (messageType) {
            case 'error': {
              this.errorMessage = messageText
              break;
            }
            case 'success': {
              this.successMessage = messageText;
              break
            }
            default:
              break;
          }
        },

        showMessages(data) {
          let self = this;
          try {
            if (data && data.errors && data.errors.length > 0) {
              data.errors.forEach(function (value, idx, arr) {
                if (value['field'] && value['field'].length > 0) {
                  let fld = value['field'].charAt(0).toUpperCase() + value['field'].slice(1);
                  let property = "validate" + fld + "Message";
                  self[property] = value['message']
                }
              });
            }
          } catch (error) {
            console.log(error.message);
          }
        },

        clearMessages() {
          let self = this;
          let fields = ['email']
          try {
            fields.forEach(function (value, idx, arr) {
                let fld = value.charAt(0).toUpperCase() + value.slice(1);
                let property = "validate" + fld + "Message";
                self[property] = ""
            });
          } catch (error) {
            console.log(error.message);
          }
        },
      },

      beforeDestroy() {
        this.cardNumber.destroy();
        this.cardExpiry.destroy();
        this.cardCvc.destroy();
      },

      async mounted() {
        this.stripe = await stripePromise;
        await this.createAndMountFormElements();
      }
    }
</script>
<style>
  .font-gray{
    color: rgb(117, 117, 117);
    font-weight: 500;
    font-size: 12px;
  }
  .info{
    border-radius: 4px;
  }
  .text-error{
    background-color: rgba(220,53,69);
    color: #fff;
  }
  .text-success{
    background-color: rgba(25,135,84);
    color: #fff;
  }
  .msg {
    font-weight: 500;
    font-size: 12px;
  }
  .spinner {
    width: 1rem !important;
    height: 1rem !important;
    margin-left: 2rem;
  }
</style>
