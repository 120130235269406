<template>
  <div>
    <b-dropdown :text="showSelectedPay" id="plan-dropdown" menu-class="w-100">
      <b-dropdown-form v-on:submit.prevent>
        <b-dropdown-item-button v-for="item in showPrices" :key="item.price" @click="selectPay(item)">
          {{ item.price }}$ - {{ item.records }}
        </b-dropdown-item-button>
        <b-dropdown-divider></b-dropdown-divider>
        <b-form-input v-if="activeTab === 'web'" id="plan-custom" v-model="amount" :max="max_amount" :min="min_amount"
          placeholder=">5000" size="sm" type="number" @blur="inputPay(amount)">
        </b-form-input>
        <b-form-input v-else id="plan-custom" v-model="amount" :max="max_amount" :min="min_amount" placeholder=">500"
          size="sm" type="number" @blur="inputPay(amount)">
        </b-form-input>
      </b-dropdown-form>
    </b-dropdown>
  </div>
</template>


<script>
export default {
  name: "DropdownPrices",
  data() {
    return {
      businessPrices: [
        { price: 1, records: 60 },
        { price: 2, records: 200 },
        { price: 5, records: 500 },
      ],
      webPrices: [
        { price: 1, records: 1000 },
        { price: 2, records: 2000 },
        { price: 5, records: 5000 },
        // { price: 1, records: 100 },
        // { price: 2, records: 200 },
        // { price: 5, records: 500 },
      ],
      currentPay: { price: 1, records: 100 },
      validateMsg: "",
      max_amount: 50000,
      min_amount: 500,
      amount: undefined,
    }
  },


  computed: {
    sfrm: {
      get() {
        return this.$store.getters.sfrm;
      },
      set(value) {
        this.$store.dispatch("actSfrm", value);
      },
    },

    activeTab() {
      return this.$store.getters.getActiveTab;
    },

    showSelectedPay() {
      return `${this.currentPay.price}$ - ${this.currentPay.records} records`;
    },

    showPrices() {
      if (this.activeTab === 'web') {
        return this.webPrices;
      } else {
        return this.businessPrices;
      }
    },
  },

  methods: {
    selectPay(item) {
      this.currentPay = item;
      let amount = this.currentPay.price;
      let limit = this.currentPay.records;
      let querySize = this.sfrm.q.length;

      if (limit < querySize) {
        this.validateMsg = `The amount (${querySize}) of keywords must be less than the amount (${limit}) selected in the payment plan`;
      } else {
        this.sfrm.amount = amount;
        this.sfrm.limit = limit;
        this.$store.dispatch("actSfrm", JSON.parse(JSON.stringify(this.sfrm)));
      }
    },

    inputPay(limit) {
      if( isNaN(limit) && this.activeTab === 'web') {
        limit = this.webPrices[0].records
      } else if( isNaN(limit) && this.activeTab === 'business') {
        limit = this.businessPrices[0].records
      }

      let amount = 0.01;

      if (limit < this.min_amount || limit > this.max_amount) {
        this.validateMsg = `Value must be between ${this.min_amount} and ${this.max_amount}`;
      } else {
        if (this.activeTab === 'web') {
          amount = Math.ceil(limit * 0.001);
        } else {
          amount = Math.ceil(limit * 0.01);
        }
        this.currentPay = { price: amount, records: limit }
        this.sfrm.amount = amount;
        this.sfrm.limit = limit;
        this.$store.dispatch("actSfrm", JSON.parse(JSON.stringify(this.sfrm)));
      }
    },

    resetAmount(value) {
      if (value === 'web') {
        this.currentPay = this.webPrices[0];
        this.min_amount = 0;
      } else {
        this.currentPay = this.businessPrices[0];
        this.min_amount = 0
      }
    }
  },

  created() {
    this.resetAmount(this.activeTab);
    this.sfrm.amount = 1;
    if (this.activeTab === 'web') {
      this.sfrm.limit = this.webPrices[0].records;
    } else {
      this.sfrm.limit = this.businessPrices[0].records;
    }
  },

  mounted() {
    this.$store.watch(
      (state) => state.activeTab,
      (newValue, oldValue) => {
        this.resetAmount(newValue);
      }
    );
  }

}
</script>


<style scoped>
#plan-dropdown {
  width: 100%;
}

.dropdown-menu {
  width: 100%;
}
</style>
