<template>
  <div class="export_data">
    <div class="export-dropdown" @mouseenter="showDropdown" @mouseleave="hideDropdown">
      <a class="row justify-content-end align-items-center">
<!--        <svg aria-label="columns" class="fa fa-columns" focusable="false" height="17" role="img" viewBox="0 0 512 512"-->
<!--          width="17" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path-->
<!--            d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM232 432H54a6 6 0 0 1-6-6V112h184v320zm226 0H280V112h184v314a6 6 0 0 1-6 6z"-->
<!--            fill="#0c2499"></path>-->
<!--        </svg>-->
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 48 48">
          <path fill="#169154" d="M29,6H15.744C14.781,6,14,6.781,14,7.744v7.259h15V6z"></path>
          <path fill="#18482a" d="M14,33.054v7.202C14,41.219,14.781,42,15.743,42H29v-8.946H14z"></path>
          <path fill="#0c8045" d="M14 15.003H29V24.005000000000003H14z"></path>
          <path fill="#17472a" d="M14 24.005H29V33.055H14z"></path>
          <g>
            <path fill="#29c27f" d="M42.256,6H29v9.003h15V7.744C44,6.781,43.219,6,42.256,6z"></path>
            <path fill="#27663f" d="M29,33.054V42h13.257C43.219,42,44,41.219,44,40.257v-7.202H29z"></path>
            <path fill="#19ac65" d="M29 15.003H44V24.005000000000003H29z"></path>
            <path fill="#129652" d="M29 24.005H44V33.055H29z"></path>
          </g>
          <path fill="#0c7238"
                d="M22.319,34H5.681C4.753,34,4,33.247,4,32.319V15.681C4,14.753,4.753,14,5.681,14h16.638 C23.247,14,24,14.753,24,15.681v16.638C24,33.247,23.247,34,22.319,34z"></path>
          <path fill="#fff"
                d="M9.807 19L12.193 19 14.129 22.754 16.175 19 18.404 19 15.333 24 18.474 29 16.123 29 14.013 25.07 11.912 29 9.526 29 12.719 23.982z"></path>
        </svg>


        <p class="textHeaderTable text-center">Export</p>
      </a>
      <div class="export-dropdown__content" v-if="isDropdownVisible">
        <ul>
          <li>
            <button @click="exportCsv(articles)">CSV</button>
          </li>
          <li>
            <button @click="exportExcel(articles)">Excel</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { unparse } from "papaparse";
import * as XLSX from "xlsx";

export default {
  name: "ExportData",
  data() {
    return {
      isDropdownVisible: false
    };
  },
  methods: {
    exportCsv(items) {
      const csvData = items.map((item) => ({
        title: item.title,
        address: `"${item.address.replace(/"/g, '""')}"`,
        phone: item.phone,
        email: `"${item.email.replace(/\//g, "\n").replace(/"/g, '""')}"`,
        web: item.web,
        social: item.social,
        category: item.category,
        builtwith: item.builtwith,
        keyword: item.keyword,
        weight: item.weight,
        ratings: item.ratings,
        relevance: item.relevance,
        likes: item.likes,
        service: item.service,
        descr: item.descr,
        logo: item.logo
      }));
      const csv = unparse(csvData, { delimiter: ";" });
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const data = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(data);
    },

    exportExcel(items) {
      const worksheet = XLSX.utils.json_to_sheet(items);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const data = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.xlsx");
      link.click();
    },

    showDropdown() {
      this.isDropdownVisible = true;
    },

    hideDropdown() {
      this.isDropdownVisible = false;
    }
  },

  computed: {
    articles() {
      return this.$store.getters.data;
    },
  },
};
</script>

<style scoped>
.export_data {
  display: flex;
  justify-content: flex-end;
}

p {
  margin-bottom: 0;
}

.export-dropdown {
  position: relative;
}

.export-dropdown__content {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  min-width: 70px;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.export-dropdown__content ul {
  padding: 10px 0;
  margin: 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.export-dropdown__content li {
  padding: 0px;
}

.export-dropdown__content li button {
  font-size: 14px;
  display: block;
  width: 100%;
  border: none;
  background: transparent;
  color: #0056b3;
}

.export-dropdown__content li button:hover {
  background: #dee2e6;
}

.export-dropdown:hover .export-dropdown__content {
  display: block;
}

.textHeaderTable {
  padding-left: 0.5em;
  padding-right: 2em;
}
</style>
