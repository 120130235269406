<script>
import { mapActions } from 'vuex';
export default {
  name: "AboutView",
  methods: {
    ...mapActions(['actSetTab']),
    changeActiveTab(value) {
      this.actSetTab(value);
    }
  }
}
</script>

<template>
  <div>
    <div class="container-l d-flex  flex-column align-items-center justify-content-center card-margin">
      <div class="card" style="width: 100%;">
        <div class="card-header">
          <h4 class="text-center">What is Dat-Cat?</h4>
        </div>
        <div class="card-body">
          <div class="card-body-text">
            <p>Dat-Cat is the search engine that returns the data in the form of the table. The data can be exported in
              multiple formats (Excel, CSV, Google Sheet, JSON). </p>
            <p>
              Currently, there are three types of search:
              <a href="#web"><b>Web</b></a>,
              <a href="#business"><b>Business</b></a> and
              <a href="#people"><b>People</b></a>
            </p>
          </div>
        </div>
      </div>

      <div id="web" class="card card-margin-2" style="width: 100%;">
        <div class="card-body">
          <div class="card-body-text">
            <p><b>Web</b> search extracts the web data in the form of the Table enriched with
              contact data (social, email, phone and other).</p>
          </div>
        </div>
        <img class="card-img-bottom" src="/static/images/web.png" alt="Card image cap">
      </div>
      <div id="business" class="card card-margin" style="width: 100%;">
        <div class="card-body">
          <div class="card-body-text">
            <p><b>Business</b> search finds business leads in any category across any location in the world.
              Comparing to Web search, Business search contains more business specific fields (see the image), contains
              more emails data ( &#126;60%-70% comparing to &#126;40%-50% in Web search), is more precise and in general
              returns
              more search results.</p>
          </div>
        </div>
        <img class="card-img-bottom" src="/static/images/business.png" alt="Card image cap">
      </div>
      <div id="people" class="card card-margin" style="width: 100%;">
        <div class="card-body">
          <div class="card-body-text">
            <p><b>People</b> search allows you to search people info (First, Last Name, Job Title, Company Name,
              Industry, Email, Phone, Address, Socials and other) in any industry across any location in the world.</p>
          </div>

        </div>
        <img class="card-img-bottom" src="/static/images/people.png" alt="Card image cap">
      </div>

      <!--      <div class="card mt-2 mb-2" style="width: 100%;">-->
      <!--        <div class="card-header">-->
      <!--          <h4 class="text-center">Integrated score S and page rank PR</h4>-->
      <!--        </div>-->
      <!--        <div class="card-body d-flex flex-column align-items-center">-->
      <!--          <div>-->
      <!--            <p>Search results are ordered by score "S" (last column), which tells you how good the record is. It integrates Page Rank (PR- order of the page in Google search results),-->
      <!--              record text relevance (Rel, textual content on a webpage) and number of value fields filled.</p>-->
      <!--            <p>So the best records are at the top and at the end you might find some noisy data. To filter/sort noisy data simply click on the column headers S or PR</p>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <img style="width: 14rem;" class="card-img-bottom align-self-center" src="/static/images/clean_data.png" alt="Card image cap">-->
      <!--      </div>-->

      <div class="card card-margin" style="width: 100%;">
        <div class="row no-gutters justify-content-center">
          <div class="card-header">
            <h4 class="text-center">Integrated score S and page rank PR</h4>
          </div>
        </div>
        <div class="row no-gutters justify-content-center">
          <div class="col-sm-4">
            <div class="card-body card-clean-data">
              <p>Search results are ordered by score "S" (last column), which tells you how good the record is. It
                integrates Page Rank (PR- order of the page in Google search results),-->
                record text relevance (Rel, textual content on a webpage) and number of value fields filled.</p>
              <p>So the best records are at the top and at the end you might find some noisy data. To filter/sort noisy
                data simply click on the column headers S or PR</p>
            </div>
          </div>
          <div class="col-sm-4 wrapp-clean_data-img" style="padding-top: 1.25em;">
            <img style="width: 12rem;" src="/static/images/clean_data.png" class="card-img" alt="clean data image">
          </div>
        </div>
      </div>

      <div class="card card-margin" style="width: 100%;">
        <div class="card-header d-flex align-items-center justify-content-center">
          <h4 class="text-center">Search Results Fields</h4>
        </div>
        <div class="card-body d-flex flex-column align-items-center">
          <div class="card-body-text col-sm-8">
            <p>The search results table includes the following:</p>
            <ul>
              <li>
                <p>Thousands Business Leads per single search.<br> Up to 50 000 leads for a bulk search<br> Results in a
                  few minutes or less depending on the search volume</p>
              </li>
              <li>
                <p>
                  Full Business Name<br>Postal Address separated by Street, City, Country and Zip<br>Phone
                  number<br>Website Link<br>Email Address
                </p>
              </li>
              <li>
                <p>
                  Links to Social Media Handles (Instagram, Linkedin, Facebook, Twitter, Yelp, TikTok)<br>
                  Get social data such as number of followers on Instagram, average like count etc<br>
                  Get review scores for each lead from Yelp, Facebook and Google<br>
                  Track which leads are actively running paid ads on Facebook/Messenger/Instagram or Yelp<br>
                  Track which leads are using Facebook Pixel or Google Remarketing<br>
                  BuiltWith features (tools and frameworks that a particular web site is built with)
                </p>
              </li>
              <li>
                <p>
                  Web page Text relevance score<br>Web page Page Rank score<br>
                  D-C score representing how good and rich is the data for each company/webpage (integrates Web Page
                  Rank, Text relevance and amount of data fields available)
                </p>
              </li>
              <li>
                <p>
                  Ability to Sort Results by any columns (Name, City, State, Page Rank, Relevance, DC Rank, email,
                  etc)<br>
                  Detect if business website is Mobile Friendly or uses Google Analytics/Schema/Wordpress etc<br>
                  CSV/Excel/Google Sheet Export Options<br>
                  Live Support See Bottom Right
                </p>
              </li>
            </ul>
            <p>Start your lead generation, contact location, list building process with Dat-Cat!</p>
            <p>Please provide stable internet connection during query execution (dont turn on VPN, etc) to not break the
              session and get final results in the table. Otherwise your results can be obtained through the link
              provided above the resulting table and will be emailed to you.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
p {
  margin-bottom: 0;
}

.card {
  border: none;
}

.card-margin-2 {
  margin-top: 2.5rem;
}

.card-margin {
  margin-top: 5rem;
}

.card-header {
  background-color: rgb(255, 255, 255);
  border-bottom: none;
}

.card-body-text {
  margin-left: 10rem;
  margin-right: 10rem;
}

.card-clean-data {
  padding-left: 0;
  padding-right: 0;
}

ul {
  margin-left: 50px;
  list-style-type: disc;
}

.wrapp-clean_data-img {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 900px) {
  .card-body-text {
    margin-left: 0;
    margin-right: 0;
  }

  @media (max-width: 576px) {
    .wrapp-clean_data-img {
      justify-content: center;
    }
  }
}
</style>