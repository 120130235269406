export function getCsrfToken (){
  return document.querySelector('input[name="csrfmiddlewaretoken"]').value;
}

export function connectSPBWebsocket() {
  return new WebSocket("wss://" + window.location.host + "/api/spb/");
}

export function connectFSQWebsocket() {
  return new WebSocket("wss://" + window.location.host + '/api/fsq/');
}

export function connectPeopleWebsocket() {
  return new WebSocket("wss://" + window.location.host + '/api/peoples/');
}

export function connectLKWebSocket() {
  return new WebSocket("wss://" + window.location.host + "/api/lk/");
}
