<script>
export default {
    data() {
        return {
            text: '',
        }
    },
    methods: {
        sendTextToParent() {
            this.$emit('text-changed', this.text);
        }
    }
}
</script>

<template>
    <div class="input_filter_search">
        <p>Filter:</p>
        <b-input
          maxlength="20"
          size="sm"
          v-model="text"
          @input="sendTextToParent"
          :id="`type-search`"
          :type="'search'"
          style="max-width: 190px;"
        >
        </b-input>
    </div>
</template>

<style>
.input_filter_search {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.input_filter_search p {
    margin-right: 5px;
    margin-bottom: 0;
}

.input_filter_search p {
    white-space: nowrap;
}
</style>