<template>
  <div class="content-main pb-4 pt-4">
    <div class="container-m">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center pb-3 pt-3">Terms and Conditions</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h3 id="terms-of-use">TERMS OF USE</h3><p>Unless otherwise specified, the terms of use detailed in this section apply generally when using this Website.</p>

<p>Single or additional conditions of use or access may apply in specific scenarios and in such cases are additionally indicated within this document.</p>

<p>By using this Website, Users confirm to meet the following requirements:</p>
<ul>
<li>Users may not qualify as Consumers;</li>
</ul>

<h4 id="account-registration">Account registration</h4>
<p>To use the Service Users must register or create a User account, providing all required data or information in a complete and truthful manner.<br>
Failure to do so will cause unavailability of the Service.</p>

<p>Users are responsible for keeping their login credentials confidential and safe. For this reason, Users are also required to choose passwords that meet the highest standards of strength permitted by this Website.</p>

<p>By registering, Users agree to be fully responsible for all activities that occur under their username and password.<br>
Users are required to immediately and unambiguously inform the Owner via the contact details indicated in this document, if they think their personal information, including but not limited to User accounts, access credentials or personal data, have been violated, unduly disclosed or stolen.</p>
<h5 id="conditions-for-account-registration">Conditions for account registration</h5>
<p>Registration of User accounts on this Website is subject to the conditions outlined below. By registering, Users agree to meet such conditions.</p>
<ul>
<li>Accounts registered by bots or any other automated methods are not permitted.</li>
</ul>

<ul>
<li>Unless otherwise specified, each User must register only one account.</li>
</ul>

<ul>
<li>Unless explicitly permitted, a User account may not be shared with other persons.</li>
</ul>

<h5 id="account-termination">Account termination</h5>
<p>Users can terminate their account and stop using the Service at any time by doing the following:</p>
<ul>
<li>By using the tools provided for account termination on this Website.</li>
</ul>

<ul>
<li>By directly contacting the Owner at the contact details provided in this document.</li>
</ul>

<h5 id="account-suspension-and-deletion">Account suspension and deletion</h5>
<p>The Owner reserves the right, at its sole discretion, to suspend or delete at any time and without notice, User accounts which it deems inappropriate, offensive or in violation of these Terms.</p>

<p>The suspension or deletion of User accounts shall not entitle Users to any claims for compensation, damages or reimbursement.</p>

<p>The suspension or deletion of accounts due to causes attributable to the User does not exempt the User from paying any applicable fees or prices.</p>
<h4 id="content-on-this_application">Content on this Website</h4>
<p>Unless where otherwise specified or clearly recognizable, all content available on this Website is owned or provided by the Owner or its licensors.</p>

<p>The Owner undertakes its utmost effort to ensure that the content provided on this Website infringes no applicable legal provisions or third-party rights. However, it may not always be possible to achieve such a result.<br>
In such cases, without prejudice to any legal prerogatives of Users to enforce their rights, Users are kindly asked to preferably report related complaints using the contact details provided in this document.</p>
<h5 id="rights-regarding-content-on-this_application-all-rights-reserved">Rights regarding content on this Website - All rights reserved</h5>
<p>The Owner holds and reserves all intellectual property rights for any such content.</p>

<p>Users may not therefore use such content in any way that is not necessary or implicit in the proper use of the Service.</p>
<h4 id="content-provided-by-users">Content provided by Users</h4>
<p>The Owner allows Users to upload, share or provide their own content to this Website.</p>

<p>By providing content to this Website, Users confirm that they are legally allowed to do so and that they are not infringing any statutory provisions and/or third-party rights.</p>
<h5 id="rights-regarding-content-provided-by-users">Rights regarding content provided by Users</h5>
<p>Users acknowledge and accept that by providing their own content on this Website they grant the Owner a non-exclusive, fully paid-up and royalty-free license to process such content solely for the operation and maintenance of this Website as contractually required.</p>

<p>To the extent permitted by applicable law, Users waive any moral rights in connection with content they provide to this Website.</p>
<p>Users acknowledge, accept and confirm that all content they provide through this Website is provided subject to the same general conditions set forth for content on this Website.</p>
<h4 id="access-to-external-resources">Access to external resources</h4>
<p>Through this Website Users may have access to external resources provided by third parties. Users acknowledge and accept that the Owner has no control over such resources and is therefore not responsible for their content and availability.</p>

<p>Conditions applicable to any resources provided by third parties, including those applicable to any possible grant of rights in content, result from each such third parties’ terms and conditions or, in the absence of those, applicable statutory law.</p>
<h4 id="acceptable-use">Acceptable use</h4>
<p>This Website and the Service may only be used within the scope of what they are provided for, under these Terms and applicable law.</p>

<p>Users are solely responsible for making sure that their use of this Website and/or the Service violates no applicable law, regulations or third-party rights.</p>
<p>Therefore, <strong>the Owner reserves the right to take any appropriate measure to protect its legitimate interests including by denying Users access to this Website or the Service, terminating contracts, reporting any misconduct performed through this Website or the Service to the competent authorities – such as judicial or administrative authorities - whenever Users engage or are suspected to engage in any of the following activities:</strong></p>

<ul>
<li>violate laws, regulations and/or these Terms;</li>
<li>infringe any third-party rights;</li>
<li>considerably impair the Owner’s legitimate interests;</li>
<li>offend the Owner or any third party.</li>
</ul>

<h4 id="api-usage-terms">API usage terms</h4>
<p>Users may access their data relating to this Website via the Application Program Interface (API). Any use of the API, including use of the API through a third-party product/service that accesses this Website, is bound by these Terms and, in addition, by the following specific terms:</p>

<ul>
<li>the User expressly understands and agrees that the Owner bears no responsibility and shall not be held liable for any damages or losses resulting from the User’s use of the API or their use of any third-party products/services that access data through the API.</li>
</ul>


<h3 id="terms-and-conditions-of-sale">TERMS AND CONDITIONS OF SALE</h3><h4 id="paid-products">Paid Products</h4>
<p>Some of the Products provided on this Website, as part of the Service, are provided on the basis of payment.</p>

<p>The fees, duration and conditions applicable to the purchase of such Products are described below and in the dedicated sections of this Website.</p>
<h4 id="product-description">Product description</h4>
<p>Prices, descriptions or availability of Products are outlined in the respective sections of this Website and are subject to change without notice.</p>

<p>While Products on this Website are presented with the greatest accuracy technically possible, representation on this Website through any means (including, as the case may be, graphic material, images, colors, sounds) is for reference only and implies no warranty as to the characteristics of the purchased Product.</p>

<p>The characteristics of the chosen Product will be outlined during the purchasing process.</p>
<h4 id="purchasing-process">Purchasing process</h4>
<p>Any steps taken from choosing a Product to order submission form part of the purchasing process.</p>

<p>The purchasing process includes these steps:</p>

<ul>
<li>Users must choose the desired Product and verify their purchase selection.</li>
<li>After having reviewed the information displayed in the purchase selection, Users may place the order by submitting it.</li>
</ul>

<h4 id="order-submission">Order submission</h4>
<p>When the User submits an order, the following applies:</p>

<ul>
<li>The submission of an order determines contract conclusion and therefore creates for the User the obligation to pay the price, taxes and possible further fees and expenses, as specified on the order page.</li>
<li>In case the purchased Product requires active input from the User, such as the provision of personal information or data, specifications or special wishes, the order submission creates an obligation for the User to cooperate accordingly.</li>
<li>Upon submission of the order, Users will receive a receipt confirming that the order has been received.</li>
</ul>

<p>All notifications related to the described purchasing process shall be sent to the email address provided by the User for such purposes.</p>
<h4 id="prices">Prices</h4>
<p>Users are informed during the purchasing process and before order submission, about any fees, taxes and costs (including, if any, delivery costs) that they will be charged.</p>

<p>Prices on this Website are displayed:</p>
<ul>
<li>either exclusive or inclusive of any applicable fees, taxes and costs, depending on the section the User is browsing.</li>
</ul>

<h4 id="offers-and-discounts">Offers and discounts</h4>
<p>The Owner may offer discounts or provide special offers for the purchase of Products. Any such offer or discount shall always be subject to the eligibility criteria and the terms and conditions set out in the corresponding section of this Website.</p>

<p>Offers and discounts are always granted at the Owner’s sole discretion.</p>

<p>Repeated or recurring offers or discounts create no claim/title or right that Users may enforce in the future.</p>

<p>Depending on the case, discounts or offers shall be valid for a limited time only or while stocks last. If an offer or discount is limited by time, the time indications refer to the time zone of the Owner, as indicated in the Owner’s location details in this document, unless otherwise specified.</p>
<h4 id="coupons">Coupons</h4>
<p>Offers or discounts can be based on Coupons.</p>

<p>If breach of the conditions applicable to Coupons occurs, the Owner can legitimately refuse to fulfill its contractual obligations and expressly reserves the right to take appropriate legal action to protect its rights and interests.</p>

<p>Notwithstanding the provisions below, any additional or diverging rules applicable to using the Coupon displayed in the corresponding information page or on the Coupon itself shall always prevail.</p>

<p>Unless otherwise stated, these rules apply to the use of Coupons:</p>

<ul>
<li>Each Coupon is only valid when used in the manner and within the timeframe specified on the website and/or the Coupon;</li>
<li>A Coupon may only be applied, in its entirety, at the actual time of purchase – partial use is not permitted;</li>
<li>Unless otherwise stated, single-use Coupons may only be used once per purchase and therefore may only be applied a single time even in cases involving installment-based purchases;</li>
<li>A Coupon cannot be applied cumulatively;</li>
<li>The Coupon must be redeemed exclusively within the time specified in the offer. After this period, the Coupon will automatically expire, precluding any possibility for the User to claim the relevant rights, including cash-out;</li>
<li>The User is not entitled to any credit/refund/compensation if there is a difference between the value of the Coupon and the redeemed value;</li>
<li>The Coupon is intended solely for non–commercial use. Any reproduction, counterfeiting and commercial trade of the Coupon is strictly forbidden, along with any illegal activity related to the purchase and/or use of the Coupon.</li>
</ul>

<h4 id="methods-of-payment">Methods of payment</h4>
<p>Information related to accepted payment methods are made available during the purchasing process.</p>

<p>Some payment methods may only be available subject to additional conditions or fees. In such cases related information can be found in the dedicated section of this Website.</p>
<p>All payments are independently processed through third-party services. Therefore, this Website does not collect any payment information – such as credit card details – but only receives a notification once the payment has been successfully completed.</p>

<p>If a payment through the available methods fails or is refused by the payment service provider, the Owner shall be under no obligation to fulfill the purchase order. Any possible costs or fees resulting from the failed or refused payment shall be borne by the User.</p>
<h4 id="retention-of-usage-rights">Retention of usage rights</h4>
<p>Users do not acquire any rights to use the purchased Product until the total purchase price is received by the Owner.</p>
<h4 id="delivery">Delivery</h4><h5 id="performance-of-services">Performance of services</h5>
<p>The purchased service shall be performed or made available within the timeframe specified on this Website or as communicated before the order submission.</p>
<h4 id="contract-duration">Contract duration</h4><h5 id="subscriptions">Subscriptions</h5>
<p>Subscriptions allow Users to receive a Product continuously or regularly over time. Details regarding the type of subscription and termination are outlined below.</p>
<h5 id="open-ended-subscriptions">Open-ended subscriptions</h5>
<p>Paid subscriptions begin on the day the payment is received by the Owner.</p>

<p>In order to maintain subscriptions, Users must pay the required recurring fee in a timely manner. Failure to do so may cause service interruptions.</p>
<h5 id="termination-of-open-ended-subscriptions">Termination of open-ended subscriptions</h5>
<p><strong>Open-ended subscriptions may be terminated at any time by sending a clear and unambiguous termination notice to the Owner using the contact details provided in this document, or — if applicable — by using the corresponding controls inside this Application.</strong></p>

<p><strong>Terminations shall take effect 2 days after the notice of termination has been received by the Owner.</strong></p>



<h3 id="liability-and-indemnification">Liability and indemnification</h3><h5 id="indemnification">Indemnification</h5>
<p>The User agrees to indemnify and hold the Owner and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners and employees harmless from and against any claim or demand &NoBreak;— including but not limited to lawyer's fees and costs &NoBreak;— made by any third party due to or in relation with any culpable use of or connection to the Service, violation of these Terms, infringement of any third-party rights or statutory provision by the User or its affiliates, officers, directors, agents, co-branders, partners and employees to the extent allowed by applicable law.</p>
<h5 id="limitation-of-liability">Limitation of liability</h5>
<p>Unless otherwise explicitly stated and without prejudice to applicable statutory product liability provisions, Users shall have no right to claim damages against the Owner (or any natural or legal person acting on its behalf).</p>

<p>This does not apply to damages to life, health or physical integrity, damages resulting from the breach of an essential contractual obligation such as any obligation strictly necessary to achieve the purpose of the contract, and/or damages resulting from intent or gross negligence, as long as this Website has been appropriately and correctly used by the User.</p>

<p>Unless damages have been caused by way of intent or gross negligence, or they affect life, health or physical integrity, the Owner shall only be liable to the extent of typical and foreseeable damages at the moment the contract was entered into.</p>
<p>In any event of liability, the compensation may not exceed the total payments that have been, will be or would be received by the Owner from the User based on the contract over a period of 12 months, or the period of the duration of the Agreement, if shorter.</p>
<h4 id="australian-users">Australian Users</h4><h5 id="limitation-of-liability">Limitation of liability</h5>
<p>Nothing in these Terms excludes, restricts or modifies any guarantee, condition, warranty, right or remedy which the User may have under the Competition and Consumer Act 2010 (Cth) or any similar State and Territory legislation and which cannot be excluded, restricted or modified (non-excludable right). To the fullest extent permitted by law, our liability to the User, including liability for a breach of a non-excludable right and liability which is not otherwise excluded under these Terms of Use, is limited, at the Owner’s sole discretion, to the re-performance of the services or the payment of the cost of having the services supplied again.</p>
<h4 id="us-users">US Users</h4><h5 id="disclaimer-of-warranties">Disclaimer of Warranties</h5>
<p><strong>This Website is provided strictly on an “as is” and “as available” basis.  Use of the Service is at Users’ own risk. To the maximum extent permitted by applicable law, the Owner expressly disclaims all conditions, representations, and warranties — whether express, implied, statutory or otherwise, including, but not limited to, any implied warranty of merchantability, fitness for a particular purpose, or non-infringement of third-party rights. No advice or information, whether oral or written, obtained by user from owner or through the Service will create any warranty not expressly stated herein.</strong></p>

<p><strong>Without limiting the foregoing, the Owner, its subsidiaries, affiliates, licensors, officers, directors, agents, co-branders, partners, suppliers and employees do not warrant that the content is accurate, reliable or correct; that the Service will meet Users’ requirements; that the Service will be available at any particular time or location, uninterrupted or secure; that any defects or errors will be corrected; or that the Service is free of viruses or other harmful components. Any content downloaded or otherwise obtained through the use of the Service is downloaded at users own risk and users shall be solely responsible for any damage to Users’ computer system or mobile device or loss of data that results from such download or Users’ use of the Service.</strong></p>

<p><strong>The Owner does not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered by a third party through the Service or any hyperlinked website or service, and the Owner shall not be a party to or in any way monitor any transaction between Users and third-party providers of products or services.</strong></p>

<p><strong>The Service may become inaccessible or it may not function properly with Users’ web browser, mobile device, and/or operating system. The owner cannot be held liable for any perceived or actual damages arising from Service content, operation, or use of this Service.</strong></p>

<p><strong>Federal law, some states, and other jurisdictions, do not allow the exclusion and limitations of certain implied warranties. The above exclusions may not apply to Users. This Agreement gives Users specific legal rights, and Users may also have other rights which vary from state to state. The disclaimers and exclusions under this agreement shall not apply to the extent prohibited by applicable law.</strong></p>
<h5 id="limitations-of-liability">Limitations of liability</h5>
<p><strong>To the maximum extent permitted by applicable law, in no event shall the Owner, and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees be liable for</strong></p>

<ul>
<li><strong>any indirect, punitive, incidental, special, consequential or exemplary damages, including without limitation damages for loss of profits, goodwill, use, data or other intangible losses, arising out of or relating to the use of, or inability to use, the Service; and</strong></li>
<li><strong>any damage, loss or injury resulting from hacking, tampering or other unauthorized access or use of the Service or User account or the information contained therein;</strong></li>
<li><strong>any errors, mistakes, or inaccuracies of content;</strong></li>
<li><strong>personal injury or property damage, of any nature whatsoever, resulting from User access to or use of the Service;</strong></li>
<li><strong>any unauthorized access to or use of the Owner’s secure servers and/or any and all personal information stored therein;</strong></li>
<li><strong>any interruption or cessation of transmission to or from the Service;</strong></li>
<li><strong>any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Service;</strong></li>
<li><strong>any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Service; and/or</strong></li>
<li><strong>the defamatory, offensive, or illegal conduct of any User or third party.  In no event shall the Owner, and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees be liable for any claims, proceedings, liabilities, obligations, damages, losses or costs in an amount exceeding the amount paid by User to the Owner hereunder in the preceding 12 months, or the period of duration of this agreement between the Owner and User, whichever is shorter.</strong></li>
</ul>


<p><strong>This limitation of liability section shall apply to the fullest extent permitted by law in the applicable jurisdiction whether the alleged liability is based on contract, tort, negligence, strict liability, or any other basis, even if company has been advised of the possibility of such damage.</strong></p>

<p><strong>Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, therefore the above limitations or exclusions may not apply to User.  The terms give User specific legal rights, and User may also have other rights which vary from jurisdiction to jurisdiction.  The disclaimers, exclusions, and limitations of liability under the terms shall not apply to the extent prohibited by applicable law.</strong></p>
<h5 id="indemnification">Indemnification</h5>
<p><strong>The User agrees to defend, indemnify and hold the Owner and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees harmless from and against any and all claims or demands, damages, obligations, losses, liabilities, costs or debt, and expenses, including, but not limited to, legal fees and expenses, arising from</strong></p>

<ul>
<li><strong>User’s use of and access to the Service, including any data or content transmitted or received by User;</strong></li>
<li><strong>User’s violation of these terms, including, but not limited to, User’s breach of any of the representations and warranties set forth in these terms;</strong></li>
<li><strong>User’s violation of any third-party rights, including, but not limited to, any right of privacy or intellectual property rights;</strong></li>
<li><strong>User’s violation of any statutory law, rule, or regulation;</strong></li>
<li><strong>any content that is submitted from User’s account, including third party access with User’s unique username, password or other security measure, if applicable, including, but not limited to, misleading, false, or inaccurate information;</strong></li>
<li><strong>User’s wilful misconduct; or</strong></li>
<li><strong>statutory provision by User or its affiliates, officers, directors, agents, co-branders, partners, suppliers and employees to the extent allowed by applicable law.</strong></li>
</ul>


<h3 id="common-provisions">Common provisions</h3><h4 id="no-waiver">No Waiver</h4>
<p>The Owner’s failure to assert any right or provision under these Terms shall not constitute a waiver of any such right or provision.  No waiver shall be considered a further or continuing waiver of such term or any other term.</p>
<h4 id="service-interruption">Service interruption</h4>
<p>To ensure the best possible service level, the Owner reserves the right to interrupt the Service for maintenance, system updates or any other changes, informing the Users appropriately.</p>

<p>Within the limits of law, the Owner may also decide to suspend or terminate the Service altogether. If the Service is terminated, the Owner will cooperate with Users to enable them to withdraw Personal Data or information in accordance with applicable law.</p>

<p>Additionally, the Service might not be available due to reasons outside the Owner’s reasonable control, such as “force majeure” (eg. labor actions, infrastructural breakdowns or blackouts etc).</p>
<h4 id="service-reselling">Service reselling</h4>
<p>Users may not reproduce, duplicate, copy, sell, resell or exploit any portion of this Website and of its Service without the Owner’s express prior written permission, granted either directly or through a legitimate reselling programme.</p>
<h4 id="privacy-policy">Privacy policy</h4>
<p>To learn more about the use of their Personal Data, Users may refer to the privacy policy of this Website.</p>
<h4 id="intellectual-property-rights">Intellectual property rights</h4>
<p>Without prejudice to any more specific provision of these Terms, any intellectual property rights, such as copyrights, trademark rights, patent rights and design rights related to this Website are the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties relating to intellectual property.</p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsView"
}
</script>

<style scoped>
h1 {
  font-size: 28px;
}
</style>