<script>
import Vue, {defineComponent} from 'vue';
import '../fixtures/records';
import cookies from "vue-cookies";
import {connectSPBWebsocket} from "@/utils/utils";

export default defineComponent({
  name: "SearchProgressBar",
  props: {},
  data: function () {
    return {
      rendered: false,
      progressBarCellWidth: 0,
      progressBarCellStyle: [],
      progress: [],
      progressTotalCells: 53,
      progressTotalFilled: 0,
      connection: null,
      flow: {
        "gloc": {"status": 0},
        "gmap": {"status": 0},
        "yp": {"status": 0},
        "yelp": {"status": 0},
        "bbb": {"status": 0},
        "custom": {"status": 0},
        "parse_social": {"status": 0},
      }
    }
  },
  computed: {
    show(){
      return this.$store.getters.data.length > 0;
    },
    partialQuanity(){
      let result = 0;
      if (this.progressTotalFilled > 0) {
        let tmp = (this.progressTotalFilled / this.progressTotalCells) * 100
        result =  Math.trunc(tmp);
      }
      return result;
    },
    partialRestQuanity(){
      let result = 0;
      try{
        let t = this.$store.getters.data.length || 0;
        let cc = Math.trunc((this.progressTotalFilled / this.progressTotalCells)  * 100)
        if (t !== Infinity && t > 0 && cc !== Infinity && cc > 0) {
          let tmp = (t * 100) / cc;
          result =  Math.trunc(tmp);
        }
      }catch (e){
        console.error(e)
      }
      return result;
    }
  },
  methods: {
    // Calculate progress bar ceil width from parent width
    calculateCellWidth(){
      let parentWidth = this.$parent.$el.clientWidth;
      let cs = getComputedStyle(this.$parent.$el);
      let ml = parseInt(cs.marginLeft)
      let mr = parseInt(cs.marginRight);
      parentWidth += (ml + mr)
      let cellWidth = (parentWidth / this.progressTotalCells) - 1;
      this.progressBarCellWidth = (cellWidth / parentWidth) * 100;
    },

    // Build styles for progress ceils
    initProgress(){
      let currentColor;
      this.progress = [];
      for (let x = 0; x < this.progressTotalCells; x++){
        currentColor = "#a6a6a6";
        let val = -1
        this.progress.push({value: val, style: {backgroundColor: currentColor+" !important", width: this.progressBarCellWidth+"% !important"}})
      }
      this.progressTotalFilled = 0;
    },
    redrawProgress(){
        this.progressTotalFilled = 0
        this.redrawGLoc();
        this.redrawGMap();
        this.redrawYp();
        this.redrawYelp();
        this.redrawBB();
        this.redrawCustom();
    },
    redrawGLoc(){
      let currentColor = "#007bff";
      let val = {value: 1, style: {backgroundColor: currentColor + " !important", width: this.progressBarCellWidth + "% !important"}}
      if(this.flow['gloc']['status'] > 1){
        this.progressTotalFilled += 3;
        for (let x = 0; x < 3; x++) {
          this.$set(this.progress, x, val);
        }
      }
    },
    redrawGMap(){
      let currentColor = "#007bff";
      let val = {value: 1, style: {backgroundColor: currentColor + " !important", width: this.progressBarCellWidth + "% !important"}}
      if(this.flow['gmap']['status'] > 1){
        this.progressTotalFilled += 10;
        for (let x = 3; x < 13; x++){
          this.$set(this.progress, x, val);
        }
      }
    },
    redrawYp(){
      let currentColor = "#007bff";
      let val = {value: 1, style: {backgroundColor: currentColor + " !important", width: this.progressBarCellWidth + "% !important"}}
      if(this.flow.yp['status'] > 1){
        this.progressTotalFilled += 10;
        for (let x = 13; x < 23; x++){
          this.$set(this.progress, x, val);
        }
      }
    },
    redrawYelp(){
      let currentColor = "#007bff";
      let val = {value: 1, style: {backgroundColor: currentColor + " !important", width: this.progressBarCellWidth + "% !important"}}
      if(this.flow.yelp['status'] > 1){
        this.progressTotalFilled += 10;
        for (let x = 23; x < 33; x++){
          this.$set(this.progress, x, val);
        }
      }
    },
    redrawBB(){
      let currentColor = "#007bff";
      let val = {value: 1, style: {backgroundColor: currentColor + " !important", width: this.progressBarCellWidth + "% !important"}}
      if(this.flow.bbb['status'] > 1){
        this.progressTotalFilled += 10;
        for (let x = 33; x < 43; x++){
          this.$set(this.progress, x, val);
        }
      }
    },
    redrawCustom(){
      let currentColor = "#007bff";
      let val = {value: 1, style: {backgroundColor: currentColor + " !important", width: this.progressBarCellWidth + "% !important"}}
      if(this.flow.custom['status'] > 1){
        this.progressTotalFilled += 10;
        for (let x = 43; x < 53; x++){
          this.$set(this.progress, x, val);
        }
      }
    },

    websocket_connect(){
      const self = this;
      // self.connection = new WebSocket("wss://" + window.location.host + '/api/spb/');
      self.connection = connectSPBWebsocket();
      self.connection.onmessage = function (event) {
        let jso = null;
        try {
          if (event.data){
            jso = JSON.parse(event.data);
          }
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
        if (jso){
          self.flow = jso;
          self.redrawProgress();
        }else{
          self.initProgress();
        }
      }
      self.connection.onopen = function (event) {}
      self.connection.onclose = function (event) {
        //self.redrawProgress();
      }
      self.connection.onerror = function (event) {
        console.error("Flow WebSocket error:", event);
      }
    },

    socketSender(){
      let self = this;
      let options = JSON.stringify({"oid": this.$store.getters.oid});
      if(self.connection.readyState === WebSocket.OPEN) {
        self.connection.send(options);
      }
    }
  },
  watch: {
    flow: {
      deep: true,
      handler(newValue, oldValue) {
        this.$bus.$emit('search_progres_bar_flow_change', newValue);
      }
    }
  },
  mounted: function () {
    let self = this;
    self.calculateCellWidth();
    self.initProgress();
    self.websocket_connect();

    // this.websocket_fixture();

    // Include CSRF token in WebSocket headers
    // const csrftoken = document.querySelector('input[name="csrfmiddlewaretoken"]').value;
    const csrftoken = cookies.get("csrftoken");
    self.connection.addEventListener('open', (event) => {
      self.connection.send(JSON.stringify({
          'type': 'websocket.handshake',
          'headers': {
              'X-CSRFToken': csrftoken
          }
      }));
    });
    Vue.prototype.$bus.$on("on_search", self.initProgress);

    self.unsubscribe = self.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === "actSearchRequest") {
          let options = JSON.stringify({"oid": this.$store.getters.oid});
          if(self.connection.readyState === WebSocket.OPEN) {
            self.connection.send(options);
          }
        }
      },
      error: (action, state, error) => {
        console.error(error);
      },
    });

    setTimeout(() => {
      this.rendered = true;
    }, 2000)
  },
  beforeDestroy() {
    if (this.connection !== null) {
      this.connection.close();
      this.connection = null;
    }
  },
})
</script>

<template>
  <div>
    <b-row class="partial_message text-center justify-content-center">
        You extracted &sim;{{ partialQuanity }}% of your query volume. Estimated volume of your query is  &sim;{{ partialRestQuanity }} records.
    </b-row>
    <b-progress class="mb-3">
      <b-progress-bar v-for="(item, index) in progress"
                      class="pb"
                      v-bind:key="index"
                      :value="item.value"
                      :style="item.style">
                      </b-progress-bar>
    </b-progress>
  </div>
</template>

<style scoped>
 .pb {margin-right: 0;}
 .partial_message{
    width: 50%;
    padding: 5px 15px;
    margin: 0 auto !important;
    color: #2dafca;
 }
 .mb-3{
   margin-left: 1rem !important;
   margin-right: 1rem !important;
 }
</style>
