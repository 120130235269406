<template>
  <footer class="footer sticky bottom-0">
    <b-container fluid="sm">
      <b-row align-h="center" align-v="center">
        <b-col cols="12" sm="3" class="d-flex align-items-center justify-content-center">
          <div class="footer-copyright">
            <p class="text-center">© 2024 Dat-Cat</p>
          </div>
        </b-col>
        <b-col cols="12" sm="9">
          <div class="footer-links">
            <router-link to="/" class="footer-link" v-if="!isHomePage">Home</router-link>
            <router-link to="/about" class="footer-link">About</router-link>
            <router-link to="/pricing"  class="footer-link">Pricing</router-link>
            <router-link to="/services" class="footer-link">Services</router-link>
<!--            <router-link to="/features" class="footer-link">Features</router-link>-->
            <!-- <router-link to="/terms" class="footer-link">Terms and Conditions</router-link> -->
            <a href="mailto:info@dat-cat.com" class="footer-link">Contact Us</a>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: "footer-chunk",
  computed: {
    isHomePage() {
      return this.$route.path === '/';
    }
  }
};
</script>

<style scoped>
.container-sm {
  max-width: 661px;
}

.footer {
  background: none;
  border: 1px solid #dee2e6;
}

.footer-copyright p {
  font-size: 14px;
  color: #333;
  margin: 0; /* Чтобы убрать внешние отступы параграфа */
}

.footer-links {
  display: flex;
  justify-content: flex-end;
}

.footer-links a {
  margin-left: 10px;
}

@media (max-width: 593px) {
 .footer-links {
  display: flex;
  justify-content: center;
}

    .footer-col {
  padding-right: 0px;
}
  .footer-link:first-child {
    padding-left: 0px;
    margin-left: 0px;
  }

}




</style>
