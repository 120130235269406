<template>
 <div class="input-group-append">
   <span id="basic-addon1" class="endRadius input-group-text" @click="submitSearch"></span>
 </div>
</template>


<script>
export default {
 name: "SearchButtonSubmit",
 methods: {
   submitSearch() {
     this.$emit('open-modal');
   }
 }
}
</script>




<style scoped>
.endRadius {
 border-radius: 0;
 height: 2.7rem;
 background: url("https://cdn-icons-png.flaticon.com/512/54/54481.png") no-repeat;
 background-size: 1rem;
 padding-left: 3rem;
 padding-right: 0.5rem;
 cursor: pointer;
 background-position: center;
}


.endRadius:hover,
.endRadius:hover span {
 background-color: rgba(0, 0, 0, 0.1);
}
</style>



