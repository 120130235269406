<style scoped>
.toast {
  margin-top: 5rem !important;
}
</style>

<template>
  <b-toast id="toast" v-model="showToast" :variant="msg.variant || 'warning'" class="toast" solid
           title="Warrning Message">
    <template #toast-title>
      <div class="d-flex flex-grow-1 align-items-baseline">
        <b-img blank blank-color="#ff5555" class="mr-2" height="12" width="12"></b-img>
        <strong class="mr-auto">Notice!</strong>
      </div>
    </template>
    {{ msg.message }}
  </b-toast>
</template>

<script>
export default {
  name: "ToastError",

  data() {
    return {
      showToast: false,
    }
  },

  computed: {
    msg: {
      get() {
        let state = this.$store.getters.msg;
        if (state?.['message'] && state["message"].trim().length > 0) {
          this.openToast();
        } else {
          this.closeToast();
        }
        return state;
      },
    },
  },

  methods: {
    openToast() {
      this.showToast = true;
    },

    closeToast() {
      this.showToast = false;
    }
  }
}
</script>