import fixture from 'can-fixture';

fixture({
        "GET /api/test_peoples/": () => {
            return {
                "status": 200,
                "size": 0,
                "records": [
                    {
                        "id": 673,
                        "first_name": "Andrew",
                        "last_name": "Dang",
                        "job_title": "Financial Controller",
                        "company_name": "Anaheim Majestic Garden Hotel",
                        "phone": "+380631086316",
                        "email": "ADang@majesticgardenhotel.com",
                        "address": "Costa Mesa, California, United States",
                        "web": "https://www.majesticgardenhotel.com/",
                        "revenue": "30900000",
                        "employees_count": 115,
                        "founded_year": "2009",
                        "social": "",
                        "source_link": "https://www.datanyze.com/companies/anaheim-majestic-garden-hotel/369003678",
                        "oid": "ch_3OmH4rGefNSPz2RI0pTk63Rc",
                        "timestamp": "2024-03-07 09:51:50.636319 +00:00",
                        "order_item_id": 10655
                    },
                    {
                        "id": 674,
                        "first_name": "Frank",
                        "last_name": "Alzaiem",
                        "job_title": "Director of Banquets",
                        "company_name": "Anaheim Majestic Garden Hotel",
                        "phone": "+380631086911",
                        "email": "FAlzaiem@majesticgardenhotel.com",
                        "address": "Costa Mesa, California, United States",
                        "web": "https://www.majesticgardenhotel.com/",
                        "revenue": "30900000",
                        "employees_count": 115,
                        "founded_year": "2009",
                        "social": "https://www.linkedin.com/in/frank-alzaiem-271a4769",
                        "source_link": "https://www.datanyze.com/companies/anaheim-majestic-garden-hotel/369003678",
                        "oid": "ch_3OmH4rGefNSPz2RI0pTk63Rc",
                        "timestamp": "2024-03-07 09:51:50.636395 +00:00",
                        "order_item_id": 10655
                    },
                    {
                        "id": 675,
                        "first_name": "Khaurontha",
                        "last_name": "Williams",
                        "job_title": "Director, Sales & Marketing",
                        "company_name": "Anaheim Majestic Garden Hotel",
                        "phone": "+380631086515",
                        "email": "KWilliams@majesticgardenhotel.com",
                        "address": "Costa Mesa, California, United States",
                        "web": "https://www.majesticgardenhotel.com/",
                        "revenue": "30900000",
                        "employees_count": 115,
                        "founded_year": "2009",
                        "social": "https://www.linkedin.com/in/cindy-smith-35a3a412",
                        "source_link": "https://www.datanyze.com/companies/anaheim-majestic-garden-hotel/369003678",
                        "oid": "ch_3OmH4rGefNSPz2RI0pTk63Rc",
                        "timestamp": "2024-03-07 09:51:50.636467 +00:00",
                        "order_item_id": 10655
                    },
                    {
                        "id": 676,
                        "first_name": "Angeles",
                        "last_name": "Calderon",
                        "job_title": "Director of Housekeeping",
                        "company_name": "Anaheim Majestic Garden Hotel",
                        "phone": "+380631086781",
                        "email": "ACalderon@majesticgardenhotel.com",
                        "address": "Costa Mesa, California, United States",
                        "web": "https://www.majesticgardenhotel.com/",
                        "revenue": "30900000",
                        "employees_count": 115,
                        "founded_year": "2009",
                        "social": "",
                        "source_link": "https://www.datanyze.com/companies/anaheim-majestic-garden-hotel/369003678",
                        "oid": "ch_3OmH4rGefNSPz2RI0pTk63Rc",
                        "timestamp": "2024-03-07 09:51:50.636546 +00:00",
                        "order_item_id": 10655
                    },
                    {
                        "id": 677,
                        "first_name": "Kim",
                        "last_name": "Painter",
                        "job_title": "General Manager",
                        "company_name": "Anaheim Majestic Garden Hotel",
                        "phone": "+380631086111",
                        "email": "KPainter@majesticgardenhotel.com",
                        "address": "Costa Mesa, California, United States",
                        "web": "https://www.majesticgardenhotel.com/",
                        "revenue": "30900000",
                        "employees_count": 115,
                        "founded_year": "2009",
                        "social": "",
                        "source_link": "https://www.datanyze.com/companies/anaheim-majestic-garden-hotel/369003678",
                        "oid": "ch_3OmH4rGefNSPz2RI0pTk63Rc",
                        "timestamp": "2024-03-07 09:51:50.636624 +00:00",
                        "order_item_id": 10655
                    },
                    {
                        "id": 678,
                        "first_name": "Ato",
                        "last_name": "Silvano",
                        "job_title": "Director, International Sales",
                        "company_name": "Anaheim Majestic Garden Hotel",
                        "phone": "+380631086945",
                        "email": "ASilvano@majesticgardenhotel.com",
                        "address": "Costa Mesa, California, United States",
                        "web": "https://www.majesticgardenhotel.com/",
                        "revenue": "30900000",
                        "employees_count": 115,
                        "founded_year": "2009",
                        "social": "https://www.linkedin.com/in/ato-silvano-7404483b",
                        "source_link": "https://www.datanyze.com/companies/anaheim-majestic-garden-hotel/369003678",
                        "oid": "ch_3OmH4rGefNSPz2RI0pTk63Rc",
                        "timestamp": "2024-03-07 09:51:50.636681 +00:00",
                        "order_item_id": 10655
                    },
                    {
                        "id": 679,
                        "first_name": "Tchi",
                        "last_name": "Willette",
                        "job_title": "Director, Operations",
                        "company_name": "Anaheim Majestic Garden Hotel",
                        "phone": "+380631086278",
                        "email": "TWillette@majesticgardenhotel.com",
                        "address": "Costa Mesa, California, United States",
                        "web": "https://www.majesticgardenhotel.com/",
                        "revenue": "30900000",
                        "employees_count": 115,
                        "founded_year": "2009",
                        "social": "https://www.linkedin.com/in/len-gomez-3436a7156",
                        "source_link": "https://www.datanyze.com/companies/anaheim-majestic-garden-hotel/369003678",
                        "oid": "ch_3OmH4rGefNSPz2RI0pTk63Rc",
                        "timestamp": "2024-03-07 09:51:50.636740 +00:00",
                        "order_item_id": 10655
                    },
                    {
                        "id": 680,
                        "first_name": "Elizabeth",
                        "last_name": "Zant",
                        "job_title": "Manager, Revenue",
                        "company_name": "Anaheim Majestic Garden Hotel",
                        "phone": "+380631086565",
                        "email": "EZant@majesticgardenhotel.com",
                        "address": "Costa Mesa, California, United States",
                        "web": "https://www.majesticgardenhotel.com/",
                        "revenue": "30900000",
                        "employees_count": 115,
                        "founded_year": "2009",
                        "social": "https://www.linkedin.com/in/elizabeth-zant-1186a810",
                        "source_link": "https://www.datanyze.com/companies/anaheim-majestic-garden-hotel/369003678",
                        "oid": "ch_3OmH4rGefNSPz2RI0pTk63Rc",
                        "timestamp": "2024-03-07 09:51:50.636802 +00:00",
                        "order_item_id": 10655
                    },
                    {
                        "id": 681,
                        "first_name": "Steve",
                        "last_name": "Melendez",
                        "job_title": "Food And Beverage Supervisor",
                        "company_name": "Anaheim Majestic Garden Hotel",
                        "phone": "+380631086980",
                        "email": "SMelendez@majesticgardenhotel.com",
                        "address": "Costa Mesa, California, United States",
                        "web": "https://www.majesticgardenhotel.com/",
                        "revenue": "30900000",
                        "employees_count": 115,
                        "founded_year": "2009",
                        "social": "https://www.linkedin.com/in/steve-melendez-9a245a90",
                        "source_link": "https://www.datanyze.com/companies/anaheim-majestic-garden-hotel/369003678",
                        "oid": "ch_3OmH4rGefNSPz2RI0pTk63Rc",
                        "timestamp": "2024-03-07 09:51:50.636864 +00:00",
                        "order_item_id": 10655
                    },
                    {
                        "id": 682,
                        "first_name": "Robert",
                        "last_name": "Gesell",
                        "job_title": "Sales Manager, Association",
                        "company_name": "Anaheim Majestic Garden Hotel",
                        "phone": "+380631086595",
                        "email": "RGesell@majesticgardenhotel.com",
                        "address": "Costa Mesa, California, United States",
                        "web": "https://www.majesticgardenhotel.com/",
                        "revenue": "30900000",
                        "employees_count": 115,
                        "founded_year": "2009",
                        "social": "",
                        "source_link": "https://www.datanyze.com/companies/anaheim-majestic-garden-hotel/369003678",
                        "oid": "ch_3OmH4rGefNSPz2RI0pTk63Rc",
                        "timestamp": "2024-03-07 09:51:50.636923 +00:00",
                        "order_item_id": 10655
                    },
                    {
                        "id": 683,
                        "first_name": "Michaela",
                        "last_name": "Fox",
                        "job_title": "Manager, Front Office",
                        "company_name": "Anaheim Majestic Garden Hotel",
                        "phone": "+380631086342",
                        "email": "MFox@majesticgardenhotel.com",
                        "address": "Costa Mesa, California, United States",
                        "web": "https://www.majesticgardenhotel.com/",
                        "revenue": "30900000",
                        "employees_count": 115,
                        "founded_year": "2009",
                        "social": "",
                        "source_link": "https://www.datanyze.com/companies/anaheim-majestic-garden-hotel/369003678",
                        "oid": "ch_3OmH4rGefNSPz2RI0pTk63Rc",
                        "timestamp": "2024-03-07 09:51:50.636996 +00:00",
                        "order_item_id": 10655
                    },
                    {
                        "id": 684,
                        "first_name": "Jeffery",
                        "last_name": "Shen",
                        "job_title": "International Sales Manager",
                        "company_name": "Anaheim Majestic Garden Hotel",
                        "phone": "+380631086294",
                        "email": "JShen@majesticgardenhotel.com",
                        "address": "Costa Mesa, California, United States",
                        "web": "https://www.majesticgardenhotel.com/",
                        "revenue": "30900000",
                        "employees_count": 115,
                        "founded_year": "2009",
                        "social": "https://www.linkedin.com/in/jeffery-shen-089203a2",
                        "source_link": "https://www.datanyze.com/companies/anaheim-majestic-garden-hotel/369003678",
                        "oid": "ch_3OmH4rGefNSPz2RI0pTk63Rc",
                        "timestamp": "2024-03-07 09:51:50.637059 +00:00",
                        "order_item_id": 10655
                    },
                    {
                        "id": 685,
                        "first_name": "Rani",
                        "last_name": "Flores",
                        "job_title": "Accounting Clerk",
                        "company_name": "Anaheim Majestic Garden Hotel",
                        "phone": "+380631086305",
                        "email": "RFlores@majesticgardenhotel.com",
                        "address": "Costa Mesa, California, United States",
                        "web": "https://www.majesticgardenhotel.com/",
                        "revenue": "30900000",
                        "employees_count": 115,
                        "founded_year": "2009",
                        "social": "https://www.linkedin.com/in/rani-flores-757a70121",
                        "source_link": "https://www.datanyze.com/companies/anaheim-majestic-garden-hotel/369003678",
                        "oid": "ch_3OmH4rGefNSPz2RI0pTk63Rc",
                        "timestamp": "2024-03-07 09:51:50.637130 +00:00",
                        "order_item_id": 10655
                    },
                    {
                        "id": 686,
                        "first_name": "Keren",
                        "last_name": "Garavito",
                        "job_title": "Administrative Assistant, Sales & Catering",
                        "company_name": "Anaheim Majestic Garden Hotel",
                        "phone": "+380631086569",
                        "email": "KGaravito@majesticgardenhotel.com",
                        "address": "Costa Mesa, California, United States",
                        "web": "https://www.majesticgardenhotel.com/",
                        "revenue": "30900000",
                        "employees_count": 115,
                        "founded_year": "2009",
                        "social": "",
                        "source_link": "https://www.datanyze.com/companies/anaheim-majestic-garden-hotel/369003678",
                        "oid": "ch_3OmH4rGefNSPz2RI0pTk63Rc",
                        "timestamp": "2024-03-07 09:51:50.637187 +00:00",
                        "order_item_id": 10655
                    },
                    {
                        "id": 687,
                        "first_name": "Jeff",
                        "last_name": "Van",
                        "job_title": "Engineer",
                        "company_name": "Anaheim Majestic Garden Hotel",
                        "phone": "+380631086395",
                        "email": "JVan@majesticgardenhotel.com",
                        "address": "Costa Mesa, California, United States",
                        "web": "https://www.majesticgardenhotel.com/",
                        "revenue": "30900000",
                        "employees_count": 115,
                        "founded_year": "2009",
                        "social": "https://www.linkedin.com/in/jeff-van-horn-6533639",
                        "source_link": "https://www.datanyze.com/companies/anaheim-majestic-garden-hotel/369003678",
                        "oid": "ch_3OmH4rGefNSPz2RI0pTk63Rc",
                        "timestamp": "2024-03-07 09:51:50.637243 +00:00",
                        "order_item_id": 10655
                    },
                    {
                        "id": 688,
                        "first_name": "Carlos",
                        "last_name": "Roque",
                        "job_title": "Lead Driver",
                        "company_name": "Anaheim Majestic Garden Hotel",
                        "phone": "+380631086746",
                        "email": "CRoque@majesticgardenhotel.com",
                        "address": "Costa Mesa, California, United States",
                        "web": "https://www.majesticgardenhotel.com/",
                        "revenue": "30900000",
                        "employees_count": 115,
                        "founded_year": "2009",
                        "social": "https://www.linkedin.com/in/carlos-roque-bb039941",
                        "source_link": "https://www.datanyze.com/companies/anaheim-majestic-garden-hotel/369003678",
                        "oid": "ch_3OmH4rGefNSPz2RI0pTk63Rc",
                        "timestamp": "2024-03-07 09:51:50.637298 +00:00",
                        "order_item_id": 10655
                    },
                    {
                        "id": 689,
                        "first_name": "Kersie",
                        "last_name": "Nocco",
                        "job_title": "Payroll Specialist",
                        "company_name": "Anaheim Majestic Garden Hotel",
                        "phone": "+380631086436",
                        "email": "KNocco@majesticgardenhotel.com",
                        "address": "Costa Mesa, California, United States",
                        "web": "https://www.majesticgardenhotel.com/",
                        "revenue": "30900000",
                        "employees_count": 115,
                        "founded_year": "2009",
                        "social": "https://www.linkedin.com/in/kersie-nocco-b4a7541ba",
                        "source_link": "https://www.datanyze.com/companies/anaheim-majestic-garden-hotel/369003678",
                        "oid": "ch_3OmH4rGefNSPz2RI0pTk63Rc",
                        "timestamp": "2024-03-07 09:51:50.637354 +00:00",
                        "order_item_id": 10655
                    },
                    {
                        "id": 690,
                        "first_name": "Roberto",
                        "last_name": "Morales",
                        "job_title": "Banquet Captain",
                        "company_name": "Anaheim Majestic Garden Hotel",
                        "phone": "+380631086344",
                        "email": "RMorales@majesticgardenhotel.com",
                        "address": "Costa Mesa, California, United States",
                        "web": "https://www.majesticgardenhotel.com/",
                        "revenue": "30900000",
                        "employees_count": 115,
                        "founded_year": "2009",
                        "social": "https://www.linkedin.com/in/roberto-morales-181219162",
                        "source_link": "https://www.datanyze.com/companies/anaheim-majestic-garden-hotel/369003678",
                        "oid": "ch_3OmH4rGefNSPz2RI0pTk63Rc",
                        "timestamp": "2024-03-07 09:51:50.637410 +00:00",
                        "order_item_id": 10655
                    },
                    {
                        "id": 691,
                        "first_name": "Kasih",
                        "last_name": "Eikin",
                        "job_title": "Admin",
                        "company_name": "Anaheim Majestic Garden Hotel",
                        "phone": "+380631086761",
                        "email": "KEikin@majesticgardenhotel.com",
                        "address": "Costa Mesa, California, United States",
                        "web": "https://www.majesticgardenhotel.com/",
                        "revenue": "30900000",
                        "employees_count": 115,
                        "founded_year": "2009",
                        "social": "https://www.linkedin.com/in/kasih-eikin-93067368",
                        "source_link": "https://www.datanyze.com/companies/anaheim-majestic-garden-hotel/369003678",
                        "oid": "ch_3OmH4rGefNSPz2RI0pTk63Rc",
                        "timestamp": "2024-03-07 09:51:50.637465 +00:00",
                        "order_item_id": 10655
                    },
                    {
                        "id": 692,
                        "first_name": "Robert",
                        "last_name": "Gesell-Flores",
                        "job_title": "Sales Manager, Association",
                        "company_name": "Anaheim Majestic Garden Hotel",
                        "phone": "+380631086378",
                        "email": "RGesell-Flores@majesticgardenhotel.com",
                        "address": "Costa Mesa, California, United States",
                        "web": "https://www.majesticgardenhotel.com/",
                        "revenue": "30900000",
                        "employees_count": 115,
                        "founded_year": "2009",
                        "social": "https://www.linkedin.com/in/robert-gesell-flores-92a15025",
                        "source_link": "https://www.datanyze.com/companies/anaheim-majestic-garden-hotel/369003678",
                        "oid": "ch_3OmH4rGefNSPz2RI0pTk63Rc",
                        "timestamp": "2024-03-07 09:51:50.637522 +00:00",
                        "order_item_id": 10655
                    },
                    {
                        "id": 693,
                        "first_name": "Bonnie",
                        "last_name": "Bird",
                        "job_title": "PBX Operator",
                        "company_name": "Anaheim Majestic Garden Hotel",
                        "phone": "+380631086911",
                        "email": "BBird@majesticgardenhotel.com",
                        "address": "Costa Mesa, California, United States",
                        "web": "https://www.majesticgardenhotel.com/",
                        "revenue": "30900000",
                        "employees_count": 115,
                        "founded_year": "2009",
                        "social": "https://www.linkedin.com/in/bonnie-bird-12a32371",
                        "source_link": "https://www.datanyze.com/companies/anaheim-majestic-garden-hotel/369003678",
                        "oid": "ch_3OmH4rGefNSPz2RI0pTk63Rc",
                        "timestamp": "2024-03-07 09:51:50.637577 +00:00",
                        "order_item_id": 10655
                    }
                ]
            }
        },
    }
);

export default fixture;