<template>
  <div class="d-flex flex-column vh-100">
      <navbar-routers></navbar-routers>
    <footer class="mt-auto">
      <footer-chunk></footer-chunk>
    </footer>
    <free-search-quote></free-search-quote>
  </div>
</template>

<script>
import NavbarRouters from "@/components/NavbarRouters.vue";
import FooterChunk from "@/views/chunks/Footer";
import FreeSearchQuote from '@/components/FreeSearchQuote';

export default {
  name: 'App',
  components: {FooterChunk, NavbarRouters, FreeSearchQuote},
  methods: {},
};
</script>

<style>
body {
  overflow-y: scroll;
}
</style>

