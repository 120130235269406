import Vuex from 'vuex'
import Vue from "vue";
import '../fixtures/records';
import cookies from 'vue-cookies'
const axios = require('axios');
const qs = require('qs');

Vue.use(Vuex);

export default new Vuex.Store({
    state: {

        isSearchTable: false,
        isLK: true,
        isLKI: false,
        keywordLKI: {"value": '', "idx": 0},

        storeTitle: "Google Map Search",
        msg: {"status": "200", "message": "", "variant": "info"},
        sfrm: {"q": [], "limit": 60, "amount": 1, "oid": ""},
        csrf: '',
        oid: '',
        dv: 1,
        fp: "",
        fqc: 0,
        records: [],
        size: 0,
        orderLink: "",
        searchDataReceived: false,
        activeTab: 'web',
        searchMode: false
    },
    mutations: {
        SET_keywordLKI(state, payload) {
            state.keywordLKI.value = payload.value;
            state.keywordLKI.idx = payload.idx;
        },
        SET_isSearchTable(state, payload) {
          state.isSearchTable = payload;
        },
        SET_isLK(state, payload) {
          state.isLK = payload;
        },
        SET_isLKI(state, payload) {
          state.isLKI = payload;
        },
        SET_CSRF(state, payload) {
            state.csrf = payload;
        },
        SET_MSG(state, payload) {
            state.msg = payload;
        },
        SET_DATA(state, payload) {
            state.records= payload;
        },
        SET_OID(state, payload) {
            state.oid = payload;
        },
        SET_FP(state, payload) {
            state.fp = payload;
        },
        SET_FQC(state, payload) {
            state.fqc = payload;
        },
        SET_SFRM(state, payload) {
            state.sfrm = payload;
        },
        SET_DV(state, payload) {
            state.dv = payload;
        },
        SET_SIZE(state, payload) {
            state.size = payload;
        },
        SET_RECEIVED_SEARCH_DATA(state, payload) {
            state.searchDataReceived = payload;
        },
        SET_ACTIVE_TAB(state, payload) {
            state.activeTab = payload;
            localStorage.setItem('activeTab', payload);
        },
        GET_ACTIVE_TAB(state) {
          const storedData = localStorage.getItem('activeTab');
          if (storedData) {
            state.activeTab = storedData;
          }
        },
        SET_ORDERLINK(state, payload){
            state.orderLink = payload;
        },
        SET_SEARCHMODE(state, payload){
            state.searchMode = payload;
        }
    },
    getters: {
        keywordLKI: state => {
          return state.keywordLKI;
        },
        isSearchTable: state => {
          return state.isSearchTable;
        },
        isLK: state => {
          return state.isLK;
        },
        isLKI: state => {
          return state.isLKI;
        },
        orderlink: state => {
            return state.orderLink;
        },
        csrf: state => {
            return state.csrf;
        },
        msg: state =>{
            return state.msg;
        },
        data: state => {
            return state.records;
        },
        oid: state =>{
            return state.oid;
        },
        fp: state =>{
            return state.fp;
        },
        fqc: state =>{
            return state.fqc;
        },
        dv: state => {
            return state.dv;
        },
        sfrm: state => {
            return state.sfrm;
        },
        searchDataReceived: state => () => {
            return state.searchDataReceived;
        },
        getActiveTab: state => {
            return state.activeTab;
        },
        getSortedRecords: state => {
            if(state.dv === 0) {
                return state.records.sort((a, b) => {
                    const filledFieldsA = Object.values(a).filter(value => value !== "").length;
                    const filledFieldsB = Object.values(b).filter(value => value !== "").length;
                    return filledFieldsB - filledFieldsA;
                }).slice(0, 60);
            } else {
                return state.records.sort((a, b) => {
                    const filledFieldsA = Object.values(a).filter(value => value !== "").length;
                    const filledFieldsB = Object.values(b).filter(value => value !== "").length;
                    return filledFieldsB - filledFieldsA;
                })
            }
        },
        getSize: state => {
            return state.size;
        },
        getSearchMode: state => {
            return state.searchMode;
        },
    },
    actions: {
        actIsSearchTable(context, payload) {
          context.commit('SET_isSearchTable', payload);
        },
        actIsLK(context, payload) {
          context.commit('SET_isLK', payload);
        },
        actIsLKI(context, payload) {
          context.commit('SET_isLKI', payload);
        },
        actOid(context, payload) {
            context.commit('SET_OID', payload);
        },
        actFp(context, payload) {
            context.commit('SET_FP', payload);
        },
        actFqc(context, payload) {
            context.commit('SET_FQC', payload);
        },
        actMSG(context, payload) {
            context.commit('SET_MSG', payload);
        },
        actSearchDataReceived(context, payload) {
            context.commit('SET_RECEIVED_SEARCH_DATA', payload);
        },
        actCsrf({commit}, payload) {
            commit('SET_CSRF', payload);
        },
        actSfrm({commit}, payload) {
            commit('SET_SFRM', payload);
        },
        actDv({commit}, payload) {
            commit('SET_DV', payload);
        },
        actSetTab({commit}, payload) {
            commit('SET_ACTIVE_TAB', payload);
        },
        actGetTab({commit}) {
            commit('GET_ACTIVE_TAB');
        },
        actOrderLink({commit}, payload) {
            commit('SET_ORDERLINK', payload);
        },
        actSearchMode({commit}, payload) {
            commit('SET_SEARCHMODE', payload);
        },
        async actSearchRequest(context, payload) {
            let self = this;
            let oid = self.state.oid;
            const options = {
                // url: `/api/records/ch_3OGT9NGefNSPz2RI0N9D3CdG/`,
                url: `api/gms/${oid}/`,
                method: 'POST',
                headers: {
                    "X-CSRFToken": cookies.get("csrftoken"),
                    'Content-Type': 'application/json',
                    "X-Requested-With": "XMLHttpRequest"
                },
                data: payload
            };
            try{
                let response = await axios(options);
                let data = response.data;
                let records = data['records'] || [];
                let size = data['size'] || 0;
                let msg = {
                    "status": data['status'] || "200",
                    "message": data['message'] || "",
                    "variant": data['variant'] || "info"
                }
                context.commit('SET_MSG', msg);
                context.commit('SET_DV', data['dv'] || 0);
                context.commit('SET_DATA', records);
                context.commit('SET_SIZE', size);
                context.commit('SET_SFRM', {...self.state.sfrm});
                context.commit('SET_RECEIVED_SEARCH_DATA', true);
            }catch (error){
                let msg = {"status": "5001", "message": error.message, "variant": "danger"}
                context.commit('SET_MSG', msg);
                context.commit('SET_RECEIVED_SEARCH_DATA', false);

            }finally {
                //Vue.prototype.$bus.$emit('on_search_request', self.state.oid)
            }
        },
        async actPaymentAprove(context, payload) {
            const options = {
                //url: '/api/aprove/',
                url: 'api/pis/',
                method: 'POST',
                headers: {
                    "X-CSRFToken": cookies.get("csrftoken"),
                    'Content-Type': 'application/json',
                    "X-Requested-With": "XMLHttpRequest"
                },
                data: payload
            };
            try {
                const response = await axios(options)
                let data = response.data;
                let msg = {
                    "status": data['status'] || "200",
                    "message": data['message'] || "",
                    "variant": data['variant'] || "info"
                }
                let oid = data['oid'] || "";
                let orderlink = data['orderlink'] || ""
                if (oid){
                    context.commit('SET_OID', data['oid']);
                    context.commit('SET_MSG', msg);
                    context.commit('SET_DV', data['dv'] || 0);
                    context.commit('SET_ORDERLINK', orderlink)
                    await context.dispatch('actSearchRequest');
                }

                Vue.prototype.$bus.$emit('change_view_component', {'value': "", 'idx': 2})
            }catch (error) {
                let msg = {"status": "5001", "message": error.message, "variant": "danger"}
                context.commit('SET_MSG', msg);
            }
        }
    }
});
