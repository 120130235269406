<template>
  <div class="search-bulk">
    <div class="input-group">
      <b-form-input
        placeholder="Search.."
        v-model="searchKey"
        class="inputRadius"
        size="sm"
         @input="emitSearchKey"
      ></b-form-input>
    </div>
  </div>
</template>

<script>

export default {
  name: "SearchBulkSmall",
  data() {
    return {
      searchKey: "",
    }
  },
  methods: {
    emitSearchKey() {
       this.$emit("search-key", this.searchKey);
    },
  }
}
</script>

<style scoped>
  .inputRadius {
  border-radius: 0;
  height: 2.7rem;
  padding-left: 1rem;
}

</style>