import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import AboutView from '@/views/AboutView.vue'
import PricingView from "@/views/PricingView";
import ContactView from "@/views/ContactView";
import TermsView from "@/views/TermsView";
import ServicesView from "@/views/ServicesView.vue";
import FeaturesView from "@/views/FeaturesView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'About',
    component: AboutView
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: PricingView
  },
  {
    path: '/services',
    name: 'Services',
    component: ServicesView
  },
  {
    path: '/terms',
    name: 'Terms and Conditions',
    component: TermsView
  },
  {
    path: '/contact',
    name: 'ContactUs',
    component: ContactView
  },
  {
    path: '/features',
    name: 'Features',
    component: FeaturesView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
