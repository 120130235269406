<style scoped>
button {
  /* Сброс стилей для кнопки */
  margin: 0;
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  text-align: left;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}

/*----- tabshelper styles -----*/
.inputRadiusBulk {
  border-radius: 1rem;
  min-height: 12rem;
  max-height: 16rem;
  height: 4rem;
  padding-left: 1rem;
}

.inputRadius {
  border-radius: 2rem;
  height: 2.7rem;

  padding-left: 1rem;
}

.endRadius {
  border-radius: 2rem;
  height: 2.7rem;
  background: url("https://cdn-icons-png.flaticon.com/512/54/54481.png") no-repeat 1rem;
  background-size: 1rem;
  padding-left: 3rem;
  padding-right: 0.5rem;
  cursor: pointer;
}

.bulk-search__textarea {
  margin-top: 44px;
}

.endRadius:hover,
.endRadius:hover span {
  background-color: rgba(0, 0, 0, 0.1);
}

.helper_container_right_btn__item, .helper_container_left_btn__item {
  border-radius: 1.25rem;
  border: 2px solid #2e2d72;
  background: transparent;
  margin: 0 0px;
  padding: 1px 3px;
  font-size: 13px;
  transition: 0.3s;
}

.helper_container_right_btn__item:hover,
.helper_container_right_btn__item.active,
.helper_container_left_btn__item:hover,
.helper_container_left_btn__item.active {
  background: #2e2d72;
  color: #fff;
}

.helperSelectsRow select {
  padding: 10px;
  border-radius: 1rem 0rem 0rem 1rem;
  width: 100%;
  min-height: 12rem;
  max-height: 16rem;
}

select.textHelperBulk {
  padding: 1rem;
  -webkit-appearance: listbox;
}


.clear_btn {
  margin-top: 10px;
}

.helper {
  padding: 0;
}

.helper_container_right {
  padding-left: 0px;
  padding-right: 15px;
}

.helper_container_left {
  padding-left: 15px;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.helper_container_right_btn_first,
.helper_container_right_btn_second,
.helper_container_right_btn_third {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}

.helper_container_right_btn_second {
  justify-content: space-around;
}

.helper_container_right_btn_third {
  justify-content: center;
}

.helper_container_right_btn_third .helper_container_right_btn__item:first-child {
  margin-right: 5px;
}

.helper_container_right_btn_third .helper_container_right_btn__item:last-child {
  margin-left: 5px;
}


.helper_container_left_btn {
  justify-content: space-around;
}

.helper_container_right_select,
.helper_container_left_select {
  margin-top: 2rem;
}

.helper_container_clear {
  padding-left: 0px;
}

/* Медиа-запрос для экранов с шириной менее 654px */
@media (max-width: 654px) {
  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .helper_container_right {
    padding-right: 0;
  }

  .helper_container_left {
    padding-right: 0;
    padding-left: 0;
  }

  .helper_container_right_btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .helper_container_right_btn_first,
  .helper_container_right_btn_second,
  .helper_container_right_btn_third {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
    min-width: 100px;
    max-width: 400px;
  }

  .helper_container_right_btn_first .helper_container_right_btn__item,
  .helper_container_right_btn_second .helper_container_right_btn__item,
  .helper_container_right_btn_third .helper_container_right_btn__item {
    margin-right: 0.5rem;
    max-width: 120px;
  }

  /* Первой кнопке не нужен отступ слева */
  .helper_container_right_btn_first .helper_container_right_btn__item:first-child,
  .helper_container_right_btn_second .helper_container_right_btn__item:first-child,
  .helper_container_right_btn_third .helper_container_right_btn__item:first-child {
    margin-left: 0;
  }

  .helper_container_right_select,
  .helper_container_left_select {
    margin-top: 1rem;
  }

  .helper_container_left_btn {
    margin-top: 2rem;
  }
}

@media (max-width: 483px) {

  .helper_container_right_btn {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between; /* Выравниваем по правой стороне */
    flex-wrap: wrap;
  }

  .helper_container_right_btn_first,
  .helper_container_right_btn_second,
  .helper_container_right_btn_third {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Выравниваем по правой стороне */
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
  }

  .helper_container_right_btn_first .helper_container_right_btn__item,
  .helper_container_right_btn_second .helper_container_right_btn__item,
  .helper_container_right_btn_third .helper_container_right_btn__item {
    margin-bottom: 0.5rem; /* Расстояние между кнопками внутри каждой колонки */
    max-width: 100%; /* Ширина кнопок внутри каждой колонки */
  }


}
</style>

<template>

  <b-col cols="12" class="helper">
    <b-row class="helper_container">
      <b-col :cols="12" :sm="6" class="helper_container_right">
        <div class="helper_container_right_btn">
          <div class="helper_container_right_btn_first">
            <button class="helper_container_right_btn__item"
                    :class="{ active: buttonUSCities }"
                    @click="openButton(1)"
            >US cities
            </button>
            <button class="helper_container_right_btn__item"
                    :class="{ active: buttonUSStates }"
                    @click="openButton(2)"
            >US states
            </button>
            <button class="helper_container_right_btn__item"
                    :class="{ active: buttonUSCounties }"
                    @click="openButton(3)"
            >US counties
            </button>
            <button class="helper_container_right_btn__item"
                    :class="{ active: buttonCountries }"
                    @click="openButton(4)"
            >Countries
            </button>
          </div>

          <div v-if="buttonUSCities"
               class="helper_container_right_btn_second">
            <button class="helper_container_right_btn__item"
                    :class="{ active: button100Cities }"
                    @click="chooseNumberCities(1)"
            >Top 100 cities
            </button>
            <button class="helper_container_right_btn__item"
                    :class="{ active: button1000Cities }"
                    @click="chooseNumberCities(2)"
            >Top 1000 cities
            </button>
            <button class="helper_container_right_btn__item"
                    :class="{ active: buttonAllCities }"
                    @click="chooseNumberCities(3)"
            >All cities
            </button>
          </div>

          <div v-if="buttonUSStates"
               class="helper_container_right_btn_second">
            <button class="helper_container_right_btn__item"
                    :class="{ active: button5States }"
                    @click="chooseNumberStates(1)"
            >Top 5 cities
            </button>
            <button class="helper_container_right_btn__item"
                    :class="{ active: button50States }"
                    @click="chooseNumberStates(2)"
            >Top 50 cities
            </button>
            <button class="helper_container_right_btn__item"
                    :class="{ active: buttonAllStates }"
                    @click="chooseNumberStates(3)"
            >All cities
            </button>
          </div>

          <div v-if="buttonUSStates || buttonUSCounties"
               class="helper_container_right_btn_third">
            <button class="helper_container_right_btn__item"
                    :class="{ active: buttonSortByName }"
                    @click="chooseSortType(1)"
            >A-Z
            </button>
            <button class="helper_container_right_btn__item"
                    :class="{ active: buttonSortByPopulation }"
                    @click="chooseSortType(2)"
            >By Populations
            </button>
          </div>

        </div>

        <div class="input-group helper_container_right_select">
          <!--          <select v-model="selectedRightOption" class="textHelperBulk" multiple @change="updateTextarea" :class="{ 'mobile_chrome': isChromeOnAndroid }">-->
          <!--            <option v-for="(value, key) in selectedRightData" :key="key" :value="value">{{ value }}</option>-->
          <!--          </select>-->
          <custom-select :optionsData="selectedRightData"
                         @options-selected="handleOptionsSelectedRight"
                         ref="refRightCustomSelect"></custom-select>
        </div>
      </b-col>

      <b-col :cols="12" :sm="6" class="helper_container_left">
        <div class="helper_container_left_btn">
          <button class="helper_container_left_btn__item"
                  :class="{ active: buttonPopular }"
                  @click="chooseLeftButton(1)">
            Popular
          </button>
          <button class="helper_container_left_btn__item" :class="{ active: buttonCategories }"
                  @click="chooseLeftButton(2)">Categories A-Z
          </button>
        </div>

        <div class="input-group helper_container_left_select"
             :class="{ margin: buttonUSCities || buttonUSStates }"
        >
          <!--          <select v-model="selectedLeftOption" class="textHelperBulk" multiple @change="updateTextarea" :class="{ 'mobile_chrome': isChromeOnAndroid }">-->
          <!--            <option v-for="(value, key) in selectedLeftData" :key="key" :value="value">{{ value }}</option>-->
          <!--          </select>-->
          <custom-select :optionsData="selectedLeftData"
                         @options-selected="handleOptionsSelectedLeft"
                         ref="refLeftCustomSelect"
          ></custom-select>
        </div>
      </b-col>

      <b-col :cols="12" :sm="4" class="helper_container_clear">
        <b-row class="my-3" align-v="start" align-h="start">
          <!--      <b-col cols="12">-->
<!--          <a class="text-info text-left" style="cursor: pointer" @click="clearSearch">Clear all</a>-->
          <!--     </b-col>-->
        </b-row>
      </b-col>
    </b-row>
  </b-col>


</template>

<script>
import {
  cities_helper,
  cities_by_states,
  counties_by_states,
  countries_helper,
  popular_helper,
  categories_helper,
  state_abbreviations
} from "@/fixtures/bulkHelper";
import Vue from "vue";
import CustomSelect from "@/components/CustomSelect.vue";

export default {
  name: "BulkSearchFilterButtons",
  components: {CustomSelect},
  props: ['onSearchSubmitParent'],
  data() {
    return {
      selectAttributes: {},

      onButtonUSCities: false,
      onButtonUSStates: false,
      onButtonUSCounties: false,
      onButtonCountries: false,

      buttonUSCities: false,
      buttonUSStates: false,
      buttonUSCounties: false,
      buttonCountries: false,

      button100Cities: false,
      button1000Cities: false,
      buttonAllCities: false,

      button5States: false,
      button50States: false,
      buttonAllStates: false,

      buttonSortByName: false,
      buttonSortByPopulation: false,

      buttonPopular: false,
      buttonCategories: false,

      isFirstLoad: true,

      citiesHelper: cities_helper,
      citiesByStates: cities_by_states,
      popularHelper: popular_helper,
      categoriesHelper: categories_helper,
      countiesByState: counties_by_states,
      countriesHelper: countries_helper,
      stateAbbreviations: state_abbreviations,

      selectedRightOption: [],
      selectedLeftOption: [],
      selectedOption: '',
    };
  },

  computed: {
    selectedRightData() {
      let amount_data = Infinity;
      let data = [];

      if ( this.buttonUSCities ) {
          amount_data = 100;
        if ( this.button100Cities ) {
          amount_data = 100;
        } else if ( this.button1000Cities ) {
          amount_data = 1000;
        }
        data = this.citiesHelper.slice(0, amount_data);
      } else if ( this.onButtonUSStates) {
        data = Object.keys(this.citiesByStates);
      } else if (this.onButtonUSCounties) {
        data = Object.keys(this.countiesByState);
      } else if (this.onButtonCountries) {
        data = Object.keys(this.countriesHelper);
      }

      if ( this.buttonSortByName ) {
        return data.sort();
      }

      return data;
    },

    selectedLeftData() {
      if ( this.buttonPopular ) {
        return this.popularHelper;
      } else if ( this.buttonCategories ) {
        return this.categoriesHelper;
      }

      return [];
    },
  },

  methods: {
    clearAllLeftButtons() {
      this.onButtonUSCities = false;
      this.onButtonUSStates = false;
      this.onButtonUSCounties = false;
      this.onButtonCountries = false;

      this.buttonUSCities = false;
      this.buttonUSStates = false;
      this.buttonUSCounties = false;
      this.buttonCountries = false;

      this.button100Cities = false;
      this.button1000Cities = false;
      this.buttonAllCities = false;

      this.button5States = false;
      this.button50States = false;
      this.buttonAllStates = false;

      this.buttonSortByName = false;
      this.buttonSortByPopulation = false;
    },

    clearSearch() {
      this.clearSelectedOptions();
      this.clearRightSelectedOptionsChild();
      this.clearLeftSelectedOptionsChild();
    },

    clearSelectedOptions() {
      this.selectedOption = '';
    },

    clearRightSelectedOptionsChild() {
      // this.$bus.$emit('clear-search');
      this.$refs.refRightCustomSelect.clearSelectedOptions();
    },

    clearLeftSelectedOptionsChild() {
      // this.$bus.$emit('clear-search');
      this.$refs.refLeftCustomSelect.clearSelectedOptions();
    },

    handleOptionsSelectedLeft(selectedOptions) {
      this.selectedLeftOption = selectedOptions;
    },

    handleOptionsSelectedRight(selectedOptions) {
      this.selectedRightOption = selectedOptions;
    },

    updateTextarea() {
      if (this.buttonUSCities) {
        this.selectedOption = this.getUSCities();

      } else if (this.buttonUSStates) {
        if (this.button5States) {
          this.selectedOption = this.getUSCitiesByState(5);
        } else if (this.button50States) {
          this.selectedOption = this.getUSCitiesByState(50);
        } else {
          this.selectedOption = this.getUSCitiesByState();
        }
      } else if (this.buttonUSCounties) {
        this.selectedOption = this.getUSCountiesByState();
      } else if (this.buttonCountries) {
        this.selectedOption = this.getCountries();
      }
    },

    openButton(number) {
      this.clearRightSelectedOptionsChild();
      this.clearAllLeftButtons();
      switch (number) {
        case 1: {
          this.onButtonUSCities = true;
          this.buttonUSCities = true;
          this.button100Cities = true;
          break;
        }
        case 2: {
          this.onButtonUSStates = true;
          this.buttonUSStates = true;
          this.button5States = true;
          this.buttonSortByName = true;
          break;
        }
        case 3: {
          this.onButtonUSCounties = true;
          this.buttonUSCounties = true;
          this.buttonSortByName = true;
          break;
        }
        case 4: {
          this.onButtonCountries = true;
          this.buttonCountries = true;
          break;
        }
      }
    },

    chooseRightButton(number) {
      this.buttonUSCities = false;
      this.buttonUSStates = false;
      this.buttonUSCounties = false;
      this.buttonCountries = false;

      switch (number) {
        case 1: {
          this.buttonUSCities = true;
          break;
        }
        case 2: {
          this.buttonUSStates = true;
          break;
        }
        case 3: {
          this.buttonUSCounties = true;
          break;
        }
        case 4: {
          this.buttonCountries = true;
          break;
        }
      }
    },

    chooseNumberCities(number) {
      this.button100Cities = false;
      this.button1000Cities = false;
      this.buttonAllCities = false;

      switch (number) {
        case 1: {
          this.button100Cities = true;
          break;
        }
        case 2: {
          this.button1000Cities = true;
          break;
        }
        case 3: {
          this.buttonAllCities = true;
          break;
        }
      }
      this.updateTextarea();
    },

    chooseNumberStates(number) {
      this.button5States = false;
      this.button50States = false;
      this.buttonAllStates = false;

      switch (number) {
        case 1: {
          this.button5States = true;
          break;
        }
        case 2: {
          this.button50States = true;
          break;
        }
        case 3: {
          this.buttonAllStates = true;
          break;
        }
      }
      this.updateTextarea();
    },

    chooseSortType(number) {
      this.buttonSortByName = false;
      this.buttonSortByPopulation = false;

      switch (number) {
        case 1: {
          this.buttonSortByName = true;
          break;
        }
        case 2: {
          this.buttonSortByPopulation = true;
          break;
        }
      }
    },

    chooseLeftButton(number) {
      this.buttonPopular = false;
      this.buttonCategories = false;

      switch (number) {
        case 1: {
          this.buttonPopular = true;
          break;
        }
        case 2: {
          this.buttonCategories = true;
          break;
        }
      }
    },

    getUSCities() {
      let arr = [];
      if (this.selectedRightOption.length > 0) {
        arr = this.selectedRightOption.flatMap(rightItem => {
          if (this.selectedLeftOption.length > 0) {
            return this.selectedLeftOption.map(leftItem => {
              return `${rightItem} ${leftItem}`;
            });
          } else {
            return [rightItem];
          }
        });
      } else {
        arr = this.selectedLeftOption;
      }

      return arr.join('\n');
    },

    getUSCitiesByState(number_cities = Infinity) {
      let arr = [];

      if (this.selectedRightOption.length > 0) {
        arr = this.selectedRightOption.flatMap(stateKey => {
          const cities = this.citiesByStates[stateKey].slice(0, number_cities);
          return cities.flatMap(city => {
            if (this.selectedLeftOption.length > 0) {
              return this.selectedLeftOption.map(option => `${city}, ${this.getStateAbbreviations(stateKey)} ${option}`);
            } else {
              return `${city}, ${this.getStateAbbreviations(stateKey)}`;
            }
          });
        });
      } else {
        arr = this.selectedLeftOption;
      }

      return arr.join('\n');
    },

    getUSCountiesByState() {
      let arr = [];

      if (this.selectedRightOption.length > 0) {
        arr = this.selectedRightOption.flatMap(stateKey => {
          const cities = this.countiesByState[stateKey];
          return cities.flatMap(city => {
            if (this.selectedLeftOption.length > 0) {
              return this.selectedLeftOption.map(option => `${city} county, ${this.getStateAbbreviations(stateKey)} ${option}`);
            } else {
              return `${city} county, ${this.getStateAbbreviations(stateKey)}`;
            }
          });
        });
      } else {
        arr = this.selectedLeftOption;
      }

      return arr.join('\n');
    },

    getCountries() {
      let arr = [];
      if (this.selectedRightOption.length > 0) {
        arr = this.selectedRightOption.flatMap(stateKey => {
          const cities = this.countriesHelper[stateKey];
          return cities.flatMap(city => {
            if (this.selectedLeftOption.length > 0) {
              return this.selectedLeftOption.map(option => `${city}, ${stateKey} ${option}`);
            } else {
              return `${city}, ${stateKey}`;
            }
          });
        });
      } else {
        arr = this.selectedLeftOption;
      }
      return arr.join('\n');
    },

    getStateAbbreviations(state) {
      return this.stateAbbreviations[state];
    },

    setParamFirstLoad() {
      this.onButtonUSCities = true;
      this.buttonUSCities = true;
      this.button100Cities = true;
      this.buttonPopular = true;
      this.isFirstLoad = false;
    },
  },

  created() {
    if (this.isFirstLoad) {
      this.setParamFirstLoad();
    }
  },

  mounted() {
    this.$bus.$on('update-textarea', this.updateTextarea);
    this.$bus.$on('clear-search', this.clearSearch)
  },

  watch: {
    selectedOption(newValue) {
      this.$bus.$emit('onSelectedOption', newValue);
    }
  },
}

</script>

