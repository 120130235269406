<style>
.search-test {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 15px;
}

.search-align {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  width: 100%;
}

.search-tables,
.search-tables-tabs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.search-tables {
  margin-top: 50px;
}

.search-align>* {
  width: 100%;
}

.navbar_tables_on {
  margin-left: -191px;
  margin-top: -5px;
}

.navbar_tables_on .navbar-brand img {
  max-width: 175px;
  vertical-align: middle;
}

.last_key_words {
  margin-top: 50px;
}

.btn_bulk_activated {
  margin-top: 10px;
}

.btn_bulk_margin {
  margin-right: 59px;
}

.bulk-search-filter-buttons {
  margin-top: 30px;
}

.last-key-words-items-table {
  width: 100%;
}

.modal-title img {
  width: 28px;
  margin-right: 10px;
}

.my-body {
  position: relative;
  padding: 0 6rem;
  text-align: center;
  font-family: 'Montserrat';
}

.dropdown-prices {
  margin-bottom: 1rem;
}

.search-log-notice {
  background-color: rgb(248, 248, 248);
  border-bottom: 1px solid #dee2e6;
  text-align: center;
}

.row {
  width: 100%;
}

.text_blue {
  color: #007bff;
}
</style>

<template>
  <div class="search-test">
    <!-- <warning-toast></warning-toast> -->

    <toast-error></toast-error>
    <b-modal id="payment-modal" ref="payment-modal" centered hide-footer titleHtml='<img src="/static/images/credit-card_30x30.png" style="width:28px; margin-right:10px" alt="credit card">
              <img src="/static/images/stripe-logo.png" style="width:50px; margin-right:20px" alt="credit card">'>
      <div class="my-body">
        <div class="dropdown-prices">
          <dropdown-prices ref="dropdownPrices"></dropdown-prices>
        </div>
        <strip-component></strip-component>
      </div>
    </b-modal>


    <div class="search-align">

      <div v-if="!BulkBigActivated" class="d-flex">
        <div v-if="shouldShowNavbar" class="navbar_tables_on flex-grow-0">
          <router-link class="navbar-brand" to="/">
            <img alt="" src="/static/images/logo.png" />
          </router-link>
        </div>
        <div class="flex-grow-1">
          <search-bulk-small class="flex-grow-1" @search-key="updateSearchKey"></search-bulk-small>
        </div>
        <div class="flex-grow-0">
          <search-button-submit @open-modal="onSearchSubmit"></search-button-submit>
        </div>
      </div>


      <div v-if="!BulkBigActivated" class="text-info-container btn_bulk_activated">
        <div class="d-flex justify-content-end btn_bulk_margin">
          <a class="text_blue" style="cursor: pointer" @click="changeSearchBulk">Bulk search</a>
        </div>
      </div>


      <div v-if="BulkBigActivated" class="d-flex">
        <div v-if="shouldShowNavbar" class="navbar_tables_on flex-grow-0">
          <router-link class="navbar-brand" to="/">
            <img alt="" src="/static/images/logo.png" />
          </router-link>
        </div>
        <div class="flex-grow-1">
          <search-bulk-big :list_search="keysList"></search-bulk-big>
        </div>
        <div class="flex-grow-0">
          <search-button-submit @open-modal="onSearchSubmit"></search-button-submit>
        </div>
      </div>


      <div v-if="BulkBigActivated" class="text-info-container btn_bulk_activated">
        <div class="d-flex justify-content-end btn_bulk_margin">
          <a class="mr-4 text_blue" style="cursor: pointer" @click="clearSearch">Clear all</a>
          <a class="text_blue" style="cursor: pointer" @click="changeSearchBulk">Single search</a>
        </div>
      </div>


      <div v-if="BulkBigActivated" class="d-flex bulk-search-filter-buttons">
        <bulk-search-filter-buttons></bulk-search-filter-buttons>
      </div>

      <div v-if="LastKeywordsActivated" class="d-flex last_key_words">
        <b-row no-gutters>
          <b-col cols="12" sm="6" class="pr-sm-2">
            <last-keywords v-on:on-lki="showTable"></last-keywords>
          </b-col>
          <b-col cols="12" sm="6" class="pl-sm-2">
            <popular-table v-on:on-lki="showTable"></popular-table>
          </b-col>
        </b-row>
      </div>
    </div>

    <div :class="{ 'search-tables': SearchTableActivated || LastKeywordItemsActivated }">

      <div v-if="SearchTableActivated" class="d-flex search-tables-tabs">
        <div v-if="SearchTableActivated && orderLink" class="search-log-notice pb-3 pt-3 mx-3" style="width: 100%">
          <p v-html="orderLink" class="m-0"></p>
        </div>
        <div v-cloak v-if="orderLink" class="no-gutter" style="width: 100%">
          <search-progress-bar ref="search-progress-bar"></search-progress-bar>
        </div>
        <b-col v-if="articles[0] && articles[0]['search_type'] === 'people'" class="web_search" cols="12">
          <b-tabs v-if="showTable">
            <b-tab active title="Business">
              <search-table></search-table>
            </b-tab>
            <b-tab title="People">
              <peoples-component></peoples-component>
            </b-tab>
          </b-tabs>
        </b-col>
        <b-col v-else cols="12">
          <search-table></search-table>
        </b-col>
      </div>

      <div v-if="LastKeywordItemsActivated" class="d-flex last-key-words-items-table">
        <keep-alive>
          <last-keyword-items v-on:change_table="showTable"></last-keyword-items>
        </keep-alive>
      </div>
    </div>

  </div>
</template>


<script>
import SearchBulkSmall from "@/components/SearchBulkSmall.vue";
import SearchBulkBig from "@/components/SearchBulkBig.vue";
import SearchButtonSubmit from "@/components/SearchButtonSubmit.vue";
import DropdownPrices from "@/components/DropdownPrices.vue";
import BulkSearchFilterButtons from "@/components/BulkSearchFilterButtons.vue";
import ToastError from "@/components/ToastError.vue";
import SearchTable from "@/components/SearchTable.vue";
import LastKeywordItems from "@/components/LastKeywordItems.vue";
import LastKeywords from "@/components/LastKeywords.vue";
import PeoplesComponent from "@/components/PeoplesComponent.vue";
// import SearchModeSwitcher from "@/components/SearchModeSwitcher.vue";
import StripComponent from "@/components/Stripe/Stripe.vue";
import { debounce } from "lodash";
import SearchProgressBar from "@/components/SearchProgressBar.vue";
import PopularTable from "@/components/PopularTable.vue";
export default {
  name: "SearchComponent",
  components: {
    PopularTable,
    SearchProgressBar,
    StripComponent,
    PeoplesComponent,
    SearchTable,
    SearchBulkSmall,
    SearchButtonSubmit,
    SearchBulkBig,
    DropdownPrices,
    BulkSearchFilterButtons,
    ToastError,
    LastKeywordItems,
    LastKeywords,
    // SearchModeSwitcher,
  },


  data() {
    return {
      // SearchModeSwitcher: SearchModeSwitcher,
      BulkBigActivated: false,
      DropdownPricesActivated: false,
      submitPayment: false,
      keysList: "",
      searchKey: "",
      currentTable: 0,
      textToShow: '',
      activKeyword: '',
      searchInterval: null,
      countSearchInterval: 0,
      windowWidth: window.innerWidth,
    }
  },

  computed: {

    shouldShowNavbar() {
      return (this.submitPayment || this.LastKeywordItemsActivated) && this.windowWidth >= 980;
    },

    SearchTableActivated: {
      get() {
        return this.$store.getters.isSearchTable;
      },
      set(value) {
        this.$store.commit('SET_isSearchTable', value)
      },
    },

    LastKeywordsActivated: {
      get() {
        return this.$store.getters.isLK;
      },
      set(value) {
        this.$store.commit('SET_isLK', value)
      },
    },

    LastKeywordItemsActivated: {
      get() {
        return this.$store.getters.isLKI;
      },
      set(value) {
        this.$store.commit('SET_isLKI', value)
      },
    },

    orderLink() {
      return this.$store.getters.orderlink;
    },

    hasArticlesData() {
      // return this.articles.length > 0;
      return this.articles.some((article) => {
        return article.email.trim() !== "";
      });
    },

    searchDataReceived: {
      get() {
        return this.$store.getters.searchDataReceived();
      },
      set(value) {
        this.$store.dispatch("actSearchDataReceived", value);
      },
    },


    dv() {
      return this.$store.getters.dv;
    },

    articles() {
      return this.$store.getters.data;
    },

    sfrm: {
      get() {
        return this.$store.getters.sfrm;
      },
      set(value) {
        this.$store.dispatch("actSfrm", value);
      },
    },

    searchList() {
      let result = [];
      if (this.keysList.length > 0) {
        result = this.keysList.split("\n");
      } else if (this.searchKey.length > 0) {
        result = [this.searchKey];
      }
      return result;
    },
  },


  methods: {

    handleResize() {
      this.windowWidth = window.innerWidth;
    },

    clearSearch() {
      this.$bus.$emit('clear-search');
    },

    setSearchInterval() {
      this.searchInterval = setInterval(
        this.dispatchSearchRequest,
        1000
      );
    },

    dispatchSearchRequest() {
      this.$store.dispatch(
        "actSearchRequest",
        JSON.parse(JSON.stringify(this.$store.getters.sfrm))
      );
    },

    showTable(nameTable) {
      if (nameTable === 'SearchTable') {
        this.SearchTableActivated = true;
        this.LastKeywordItemsActivated = false;
        this.LastKeywordsActivated = false;
      } else if (nameTable === 'LastKeywordItems') {
        this.SearchTableActivated = false;
        this.LastKeywordItemsActivated = true;
        this.LastKeywordsActivated = false;
      } else {
        this.SearchTableActivated = false;
        this.LastKeywordItemsActivated = false;
        this.LastKeywordsActivated = true;
      }
    },

    updateKeysList(data) {
      this.keysList = data;
    },

    updateSearchKey(data) {
      this.searchKey = data;
    },

    changeSearchBulk() {
      return this.BulkBigActivated = !this.BulkBigActivated;
    },


    openModal() {
      this.$refs['payment-modal'].show();
    },

    closeModal() {
      this.$refs['payment-modal'].hide();
    },

    onSearchSubmit() {
      let self = this;
      self.searchDataReceived = false;
      self.$data.startRequestAt = new Date().getTime();

      if (self.dv === 1) {
        if (this.searchList.length <= 0) {
          let msg = {
            status: "5005",
            message: "Empty query. Please specify search query keyword.",
            variant: "warning",
          };
          self.$store.dispatch("actMSG", msg);
        } else {
          self.sfrm.q = JSON.parse(JSON.stringify(self.searchList));
          this.openModal();
          // self.$store.dispatch("actOid", "ch_3OGT9NGefNSPz2RI0N9D3CdG");
          // self.$store.dispatch("actSearchRequest");
          // this.showTable('SearchTable');
        }
      } else {
        let msg = {
          status: "5005",
          message: "Please wait previous search execution terminate.",
          variant: "warning",
        };
        self.$store.dispatch("actMSG", msg);
      }
    },
  },
  mounted() {

    window.addEventListener('resize', this.handleResize);

    this.$bus.$on('on_search', this.setSearchInterval);

    this.$bus.$on('submitPayment', (value) => {
      this.submitPayment = value;
    });

    this.unsubscribe = this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === "actSearchRequest") {
          this.closeModal();
          this.showTable('SearchTable')
        }
      },
      error: (action, state, error) => {
        console.error(error);
      },
    });

    this.$bus.$on('onSelectedOption', this.updateKeysList);
  },

  beforeDestroy() {
    if (this.unsubscribe && this.unsubscribe instanceof Function) {
      this.unsubscribe();
    }
  },

  watch: {
    '$store.getters.data': {
      handler(newData) {
        this.articles = newData;
      },
      deep: true
    },

    articles() {
      let self = this;
      if (self.hasArticlesData && self.countSearchInterval === 0) {
        clearInterval(self.searchInterval);
        self.searchInterval = setInterval(function () {
          self.$store.dispatch(
            "actSearchRequest",
            JSON.parse(JSON.stringify(self.$store.getters.sfrm))
          );
        }, 10000);
        self.countSearchInterval += 1;
      }

      if (self.dv === 1) {
        clearInterval(self.searchInterval);
        self.searchInterval = null;
      }
    },


  }
}
</script>
