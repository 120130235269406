<template>
  <div class="content-main pb-4 pt-4">
    <div class="container-m">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center pb-3 pt-3">Contact us</h1>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUs"
}
</script>

<style scoped>
h1 {
  font-size: 28px;
}
</style>